import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { SelectButtons } from "../../../../components/SelectButtons/SelectButtons";
import { trimmedTimeRadioButtons } from "../../../../constants/radioButtons";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { getPowerCostData } from "../../../../mocks/relativeCost";

import "./RelativeCosts.scss";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { observer } from "mobx-react-lite";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { getSelectedDevices, mergeSameLocationData } from "../../../../utils/multipleDevicesUtils";
import { getTelemetryData } from "../../../../api/clientApi";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { calculateActiveEnergyTotalCost, calculateDistributionCost } from "../../../../mocks/costs";
import { getOsd } from "../../../../api/api";
import { showToast } from "../../../../utils/toast";
import { Toast } from "primereact/toast";
import { TOAST_MESSAGE_TYPE } from "../../../../constants/toastText";
import { calculateSumDataArray, isNotNullish } from "../../../../utils/helpers";

export const RelativeCosts = observer(() => {
  const toastRef = useRef(null);

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  const { selectedClientsDevices, clientsDevicesNodes, selectAllDevices } = useClientStore();

  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const [buttonChecked, setButtonChecked] = useState(trimmedTimeRadioButtons[0].id);
  const [distributionChecked, setDistributionChecked] = useState(true);
  const [powerChecked, setPowerChecked] = useState(true);
  const [distributionCost, setDistributionCost] = useState([]);
  const [activeEnergyTotalCost, setActiveEnergyTotalCost] = useState([]);
  const [totalCost, setTotalCost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const resolution = TIME_CONSTANTS.HOUR_4;
  const sinceDate = useMemo(() => new Date(Date.now() - TIME_CONSTANTS_VALUES[buttonChecked]), [buttonChecked]);
  const toDate = new Date();

  useEffect(() => {
    getPowerDemandData();
  }, [selectedClientsDevices.ids, buttonChecked]);

  const getPowerDemandData = async () => {
    setIsLoading(true);

    const promisesDataArray = selectedDevices.map(async device => {
      const { data } = await getTelemetryData({
        devices: device.deviceId,
        begin: parseDateToApiFormat(sinceDate),
        end: parseDateToApiFormat(toDate),
        resolution: resolution,
        avm_active_energy_total_sum: 1
      });

      return {
        value: data,
        osdId: device.osdId,
        branchId: device.branchId,
        name: device.locationName,
        locationId: device.locationId,
        contractPower: device.contractPower
      };
    });

    const devicesArray = await Promise.all(promisesDataArray);
    const powerCostsPromises = devicesArray.map(async deviceData => {
      if (deviceData?.value[0]?.data !== undefined && deviceData.value[0].data.length > 0) {
        const activeEnergyCostInTime = calculateActiveEnergyTotalCost(deviceData.value, resolution);
        const sumActiveEnergyCost = calculateSumDataArray(activeEnergyCostInTime);
        const name = deviceData.name;

        const buildingArea = selectedDevices.find(
          device => Number(device.deviceId) === deviceData.value[0].device_id
        ).buildingArea;

        const sumActiveEnergyCostPerSurface = Math.round((sumActiveEnergyCost / buildingArea) * 100) / 100;

        if (!isNotNullish(deviceData?.branchId) || !isNotNullish(deviceData?.osdId)) {
          displayToast("Brak szczegółowych informacji o pliszce!", TOAST_MESSAGE_TYPE.WARN);
          const distributionCost = 0;
          return {
            active: [name, sumActiveEnergyCostPerSurface, buildingArea],
            distribution: [name, 0, buildingArea],
            total: [name, Math.round((sumActiveEnergyCostPerSurface + distributionCost) * 100) / 100, buildingArea]
          };
        } else {
          const fetchOsdData = async (year, branchId, rateId) => {
            try {
              const { data } = await getOsd({ year, branchId, rateId });
              return data;
            } catch (error) {
              displayToast("Brak informacji o osd!", TOAST_MESSAGE_TYPE.WARN);
            }
          };
          const osdInfo = await fetchOsdData("2021", deviceData?.branchId, deviceData?.osdId);

          const distributionCostInTime = calculateDistributionCost(
            deviceData.value,
            osdInfo.response[0],
            resolution,
            deviceData.contractPower
          );
          const sumDistributionCost = calculateSumDataArray(distributionCostInTime);
          const sumDistributionCostPerSurface = Math.round((sumDistributionCost / buildingArea) * 100) / 100;

          return {
            active: [name, sumActiveEnergyCostPerSurface, buildingArea],
            distribution: [name, sumDistributionCostPerSurface, buildingArea],
            total: [
              name,
              Math.round((sumActiveEnergyCostPerSurface + sumDistributionCostPerSurface) * 100) / 100,
              buildingArea
            ]
          };
        }
      } else {
        displayToast("Brak danych dla pliszki!", TOAST_MESSAGE_TYPE.WARN);
      }
    });
    const powerCosts = await Promise.all(powerCostsPromises);
    const relativeActiveCost = mergeSameLocationData(powerCosts.map(element => element?.active));
    const relativeDistrubutionCost = mergeSameLocationData(powerCosts.map(element => element?.distribution));
    const relativeTotalCost = mergeSameLocationData(powerCosts.map(element => element?.total));

    setActiveEnergyTotalCost(relativeActiveCost);
    setDistributionCost(relativeDistrubutionCost);
    setTotalCost(relativeTotalCost);
    setIsLoading(false);
  };

  const onRadioButtonPress = useCallback(() => {}, []);
  const periodOfTime = useMemo(() => buttonChecked, [buttonChecked]);
  const chartHeight = 700;
  const chartData = useMemo(
    () =>
      getPowerCostData(
        distributionChecked,
        powerChecked,
        activeEnergyTotalCost,
        distributionCost,
        totalCost,
        periodOfTime,
        chartHeight
      ),
    [distributionChecked, powerChecked, activeEnergyTotalCost, distributionCost, totalCost, periodOfTime]
  );
  return (
    <Grid>
      <Toast ref={toastRef} />
      <Card flexGrow={6}>
        <div className="selectionContainer">
          <h3>{`Okres:`}</h3>
          <SelectButtons
            setButtonChecked={setButtonChecked}
            onRadioButtonPress={onRadioButtonPress}
            radioButtons={trimmedTimeRadioButtons}
            value={buttonChecked}
          />
        </div>
      </Card>
      <Card flexGrow={6}>
        <div className="selectionContainer">
          <h3>{`Dane:`}</h3>
          <div>
            <label className="checkboxLabel">Koszty dytrybucji</label>
            <Checkbox
              onChange={e => setDistributionChecked(e.checked)}
              checked={distributionChecked}
              disabled={!powerChecked}
            />
            <label className="checkboxLabel">Koszty mocy</label>
            <Checkbox
              onChange={e => setPowerChecked(e.checked)}
              checked={powerChecked}
              disabled={!distributionChecked}
            />
          </div>
        </div>
      </Card>
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
