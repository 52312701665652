import { getSplineChartOptions } from "../constants/defaultChartOptions";
import { createDataPoints } from "./createDataPoints";

export const getPvProductionDetailsChartData = (numberOfDataPoints, resolution, startingPoint) => {
  const dataObject = {
    title: "Szczegółowy profil produkcji PV",
    yAxisLabel: "Produkcja PV",
    yAxisUnit: "Wh",
    pointInterval: resolution,
    startTime: startingPoint,
    zoomType: "x",
    plots: [
      {
        name: "Produkcja PV",
        data: createDataPoints(numberOfDataPoints)
      }
    ]
  };
  return getSplineChartOptions(dataObject);
};
