import { getAxiosClientWithCustomCredentials } from "./apiClient";
import axiosClient from "./axiosClient";

export const getCities = async () => await fetchData("weather/cities");
export const getWeatherData = async params => await fetchData("weather", params);
export const getNotes = async params => await fetchData("notes", params);
export const createNote = async body => await postData("notes/note", body);
export const updateNote = async body => await putData("notes/note", body);
export const removeNote = async body => await postData("notes/deleteNote", body);
export const getOsd = async params => await fetchData("osd", params);
export const getAllOsdTarrifs = async params => await fetchData("osd/allTariffs", params);

async function fetchData(routeName, params = {}) {
  try {
    const { data, status } = await axiosClient.get(`/${routeName}`, {
      params
    });
    return { data, status };
  } catch (error) {
    console.log("Fetch error: ", error.toJSON());
    return { message: error.toJSON().message };
  }
}

async function postData(routeName, body = {}) {
  try {
    const { data, status } = await axiosClient.post(`/${routeName}`, body);
    return { data, status };
  } catch (error) {
    console.log("Post error: ", error.toJSON());
    return { message: error.toJSON().message };
  }
}

async function putData(routeName, body = {}) {
  try {
    const { data, status } = await axiosClient.put(`/${routeName}`, body);
    return { data, status };
  } catch (error) {
    console.log("Put error: ", error.toJSON());
    return { message: error.toJSON().message };
  }
}

export async function checkCredentians(username, password) {
  try {
    const { data } = await getAxiosClientWithCustomCredentials(username, password).get(`/devices/data.json`);
    return !!data?.customers;
  } catch (error) {
    return false;
  }
}
