import { Button } from "primereact/button";

import "./ContractPowerChange.scss";

export const ContractPowerChange = ({
  increment,
  decrement,
  contractPower,
  contractPowerPrice,
  priceClassName,
  priceDifferance
}) => (
  <div className="power-change-container">
    <div className="buttons-container">
      <Button onClick={increment} icon="pi pi-arrow-up" className="p-button-rounded p-button-secondary" />
      <div>
        <span className="power-change-label">Moc umowna</span>
        <span className="contract-power">{contractPower?.toFixed(2)} kW</span>
      </div>
      <Button onClick={decrement} icon="pi pi-arrow-down" className="p-button-rounded p-button-secondary" />
    </div>
    <div className="contract-power-wrapper">
      <div className="power-change-differance-wrapper">
        <span className={`power-change-differance ${priceClassName}`}>{priceDifferance}zł</span>
      </div>
      <span className="power-change-price">
        Koszt: <span>{contractPowerPrice}zł</span>
      </span>
    </div>
  </div>
);
