import React, { useCallback, useMemo, useState } from "react";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { getPvProductionEfficiencyChartData } from "../../../../mocks/pvProductionEfficiency";

export const PvProductionEfficiency = () => {
  const { isPhotovoltaicConnected } = useClientStore();

  const [buttonChecked, setButtonChecked] = useState(standardTimeRadioButtons[0].id);

  const onRadioButtonPress = useCallback(() => {}, []);

  const chartData = useMemo(() => {
    if (!isPhotovoltaicConnected) {
      const defaultChartOptions = getPvProductionEfficiencyChartData();
      const { series: defaulSeries } = defaultChartOptions;
      const chartOptions = {
        ...defaultChartOptions,
        series: defaulSeries.map(({ type, name }) => ({
          type,
          name,
          data: []
        }))
      };

      return chartOptions;
    }

    return getPvProductionEfficiencyChartData();
  }, [isPhotovoltaicConnected]);

  return (
    <Grid>
      <RadioButtonsSection
        setButtonChecked={setButtonChecked}
        onRadioButtonPress={onRadioButtonPress}
        radioButtons={standardTimeRadioButtons}
        value={buttonChecked}
      />
      <Card>
        <Chart options={chartData} />
      </Card>
    </Grid>
  );
};
