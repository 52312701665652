import { useContext } from "react";
import { StoreContext } from "../../store/StoreProvider";

export function useAuthStore() {
  return useContext(StoreContext).authStore;
}

export function useClientStore() {
  return useContext(StoreContext).clientStore;
}

export function useUserOptionsStore() {
  return useContext(StoreContext).userOptionsStore;
}

export function useWeatherStore() {
  return useContext(StoreContext).weatherStore;
}

export function useCityStore() {
  return useContext(StoreContext).cityStore;
}

export function useNoteStore() {
  return useContext(StoreContext).noteStore;
}
