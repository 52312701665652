export const parseDateToApiFormat = (date = new Date()) => {
  const format = val => (val < 10 ? `0${val}` : val);

  const year = date.getFullYear();
  const month = format(date.getMonth() + 1);
  const day = format(date.getDate());
  const hour = format(date.getHours());
  const minute = format(date.getMinutes());
  const second = format(date.getSeconds());

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};
