import React, { useMemo, useState } from "react";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { getUsagePerFixtureData } from "../../../../mocks/usegePerFixture";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";

export const UsagePerFixture = () => {
  //TODO isLoading, resolution, telemetryData - dummy data used for notes(we dont have real data right now).
  //Delete or change when proper data will be introduced.
  //If selectedDevices will be used instead telemetryData.device_id use typeof selectedDevices.id === "number" ? selectedDevices.id : null.
  const [isLoading, setIsLoading] = useState(false);
  const [resolution, setResolution] = useState(TIME_CONSTANTS.SEC_10);
  const [telemetryData, setTelemetryData] = useState({
    device_id: 145,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });
  const chartData = useMemo(() => getUsagePerFixtureData(), []);

  return (
    <>
      <Grid>
        <Card>
          <Chart
            options={chartData}
            deviceId={telemetryData.device_id}
            chartResolution={resolution}
            isLoading={isLoading}
            chartName={"UsagePerFixture"}
            displayNotesList
          />
        </Card>
      </Grid>
    </>
  );
};
