import { createDataPointsCurrentDay } from "../mocks/createDataPoints";
import { getDayBeginningDateTime } from "../utils/helpers";
import { getMixedSingleAxisChartOptions } from "../constants/defaultChartOptions";
import { TIME_CONSTANTS_VALUES } from "../constants/timeValues";

const { hour } = TIME_CONSTANTS_VALUES;
const pointInterval = hour;
const date = Date.parse(getDayBeginningDateTime());

const pvProduction = createDataPointsCurrentDay(date, pointInterval);

const activeEnergy = createDataPointsCurrentDay(date, pointInterval);

const energyNetworkBalance = pvProduction.map((point, index) =>
  activeEnergy[index] ? Number((point + activeEnergy[index]).toFixed(1)) : point
);

const plotOption = Object.freeze({
  pointStart: date,
  pointInterval: pointInterval
});

export const getOsdBalanceData = () =>
  getMixedSingleAxisChartOptions({
    title: "Bilans produkcji energii z siecią OSD",
    yAxisTitle: "Wyprodukowana energia",
    yAxisUnit: "kWh",
    plotOptions: { spline: plotOption, column: plotOption },
    series: [
      {
        name: "Bilans do sieci",
        type: "column",
        data: energyNetworkBalance
      },
      {
        name: "Energia czynna",
        data: activeEnergy
      },
      {
        name: "Profil produkcji PV",
        data: pvProduction
      }
    ]
  });
