import { getSplineChartOptions } from "../constants/defaultChartOptions";
import { createDataPoints } from "./createDataPoints";
import { getDateLabel } from "./compareActiveEnergy";

export const getPvProductionCompareChartData = (numberOfDataPoints, resolution, startingPoint, dates) => {
  const dataObject = {
    title: "Porównanie profili produkcji PV dla wybranych dni",
    yAxisLabel: "Produkcja PV",
    yAxisUnit: "Wh",
    pointInterval: resolution,
    startTime: startingPoint,
    zoomType: "x",
    tooltipShared: true,
    tooltipTimeOnly: true,
    plots: dates.map(date => {
      return {
        name: getDateLabel(date.value),
        data: createDataPoints(numberOfDataPoints)
      };
    })
  };
  return getSplineChartOptions(dataObject);
};
