import Highcharts from "highcharts";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { DateFilter } from "../../../../components/DateFilter/DateFilter";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { getLargeTreeMapChartOptions } from "../../../../constants/defaultChartOptions";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { getSelectedDevices } from "../../../../utils/multipleDevicesUtils";

export const PassivePowerCosts = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedClientsDevices, clientsDevicesNodes, selectAllDevices } = useClientStore();

  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const [sinceDate, setSinceDate] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]));
  const [toDate, setToDate] = useState(new Date());
  const resolution = TIME_CONSTANTS.HOUR_1;
  const [powerCostDataArray, setPowerCostDataArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    selectAllDevices();
  }, []);

  useEffect(() => {
    getPassivePowerCostsData();
  }, [selectedClientsDevices.ids, sinceDate, toDate]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  let colorIndex = -1;

  const flattenTree = (obj, parentId) => {
    const returnArray = [mapNodeToChartFormat(obj, parentId)];
    obj.children && obj.children.forEach(child => returnArray.push(...flattenTree(child, obj.key)));
    return returnArray;
  };

  const mapNodeToChartFormat = (node, parentId) => {
    if (colorIndex >= 10) {
      colorIndex = 0;
    } else {
      colorIndex++;
    }
    return {
      color: Highcharts.getOptions().colors[colorIndex],
      parent: parentId,
      name: node.label,
      id: node.key,
      ...node
    };
  };

  const addValueToPassivePowerCostsData = (powerCostData, dataWithValue) => {
    for (
      let elementNumberPassivePowerArray = 0;
      elementNumberPassivePowerArray < powerCostData.length;
      elementNumberPassivePowerArray++
    ) {
      for (
        let elementNumberDataWithValueArray = 0;
        elementNumberDataWithValueArray < dataWithValue.length;
        elementNumberDataWithValueArray++
      ) {
        const id = powerCostData[elementNumberPassivePowerArray].id.split("-");
        const elementId = id[id.length - 1];
        if (elementId === `${dataWithValue[elementNumberDataWithValueArray].id}`) {
          powerCostData[elementNumberPassivePowerArray].value = dataWithValue[elementNumberDataWithValueArray].value;
        }
      }
    }
  };

  const passivePowerCostsDataWithUnecessaryRoot = flattenTree({ children: clientsDevicesNodes });
  const [, ...passivePowerCostsDataWithProxy] = passivePowerCostsDataWithUnecessaryRoot;
  const passivePowerCostsData = JSON.parse(JSON.stringify(passivePowerCostsDataWithProxy));

  const getPassivePowerCostsData = useCallback(
    async id => {
      if (!selectedDevices.length) {
        showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
        setPowerCostDataArray([]);
        return;
      }
      setIsLoading(true);
      const promisesDataArray = selectedDevices.map(async devices => {
        const { data } = await getTelemetryData({
          devices: devices.deviceId,
          begin: parseDateToApiFormat(sinceDate),
          end: parseDateToApiFormat(toDate),
          resolution: resolution,
          avm_active_energy_total_sum: 1
        });
        return { value: data };
      });

      const devicesArray = await Promise.all(promisesDataArray);

      const dataArray = devicesArray.map(devicesData => {
        if (devicesData?.value[0]?.data !== undefined) {
          const summDevicesDataArray = devicesData.value[0].data.map(element => {
            return element[1];
          });
          if (summDevicesDataArray.length > 0) {
            const averageDevicesDataArray = Math.round(
              summDevicesDataArray.reduce((prev, curr) => prev + curr) / summDevicesDataArray.length
            );
            return { value: averageDevicesDataArray, id: devicesData.value[0].device_id };
          } else {
            return { value: 0, id: devicesData.value[0].device_id };
          }
        } else {
          return 0;
        }
      });
      addValueToPassivePowerCostsData(passivePowerCostsData, dataArray);
      setPowerCostDataArray(passivePowerCostsData);
      setIsLoading(false);
    },
    [passivePowerCostsData, resolution, selectedDevices, showToast, sinceDate, toDate]
  );

  const getPassivePowerCostsChartData = useCallback(() => {
    const dataObject = {
      title: "Koszt energii czynnej",
      data: powerCostDataArray
    };

    return getLargeTreeMapChartOptions(dataObject);
  }, [powerCostDataArray]);

  const chartData = useMemo(() => getPassivePowerCostsChartData(), [getPassivePowerCostsChartData]);
  return (
    <Grid>
      <Card>
        <Toast ref={toastRef} />
        <DateFilter
          sinceDate={sinceDate}
          setSinceDate={setSinceDate}
          toDate={toDate}
          setToDate={setToDate}
          dateOnly
          buttonClear={false}
        />
      </Card>
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
