import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Toast } from "primereact/toast";

import { calculateNumberOfPoints } from "../../../../utils/chartUtils";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { ComparisonFilterSection } from "../../../../components/ComparisonFilterSection/ComparisonFilterSection";
import { getPvProductionCompareChartData } from "../../../../mocks/comparePvProduction";
import { Grid } from "../../../../components/UI/Grid";
import { filterResolutionsOptions, parseDate } from "../../../../utils/helpers";
import { resolutionOptions, RESOLUTIONS_NUMBERS } from "../../../../constants/chartOptions";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";

export const ComparePvProduction = () => {
  const toastRef = React.useRef(null);

  const [numberOfDataPoints, setNumberOfDataPoints] = useState();
  const [resolution, setResolution] = useState(TIME_CONSTANTS.SEC_10);
  const [sinceHour, setSinceHour] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]));
  const [toHour, setToHour] = useState(new Date());
  const [dates, setDates] = useState([{ id: "Dzień 1", value: new Date() }]);
  //TODO telemetryData - dummy data used for notes(we dont have real data right now).
  //Delete or change when proper data will be introduced.
  //If selectedDevices will be used instead telemetryData.device_id use typeof selectedDevices.id === "number" ? selectedDevices.id : null.
  const [telemetryData, setTelemetryData] = useState({
    device_id: 145,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  const showErrorToast = useCallback(
    message =>
      toastRef.current?.show({
        severity: "error",
        summary: "Wystąpił błąd",
        detail: message,
        sticky: true
      }),
    []
  );

  useEffect(
    () => calculateNumberOfPoints(toHour, sinceHour, resolution, showErrorToast, setNumberOfDataPoints, true),
    [toHour, sinceHour, resolution, showErrorToast]
  );

  const chartData = useMemo(
    () =>
      getPvProductionCompareChartData(numberOfDataPoints, RESOLUTIONS_NUMBERS[resolution], parseDate(sinceHour), dates),
    [numberOfDataPoints, sinceHour, dates, resolution]
  );

  return (
    <Grid>
      <Toast ref={toastRef} />
      <ComparisonFilterSection
        dates={dates}
        setDates={setDates}
        sinceHour={sinceHour}
        setSinceHour={setSinceHour}
        toHour={toHour}
        setToHour={setToHour}
        resolution={resolution}
        setResolution={setResolution}
        resolutionOptions={filterResolutionsOptions(toHour, sinceHour, resolutionOptions, resolution, setResolution)}
      />
      <Card>
        <Chart
          options={chartData}
          deviceId={telemetryData.device_id}
          chartResolution={resolution}
          isLoading={false}
          chartName={"ComparePvProduction"}
          displayNotesList
        />
      </Card>
    </Grid>
  );
};
