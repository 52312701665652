import { getSplineChartOptions } from "../constants/defaultChartOptions";
import { createDataPoints } from "./createDataPoints";

export const getDateLabel = dateObject =>
  `${dateObject.getDate()}.${
    dateObject.getMonth() + 1 < 10 ? "0" + (dateObject.getMonth() + 1) : dateObject.getMonth() + 1
  }.${dateObject.getFullYear()}`;

export const getActiveEnergyCompareChartData = (numberOfDataPoints, resolution, startingPoint, dates) => {
  const dataObject = {
    title: "Porównanie profili energii czynnej dla wybranych dni",
    yAxisLabel: "Energia czynna",
    yAxisUnit: "Wh",
    pointInterval: resolution,
    startTime: startingPoint,
    zoomType: "x",
    tooltipShared: true,
    tooltipTimeOnly: true,
    plots: dates.map(date => {
      return {
        name: getDateLabel(date.value),
        data: createDataPoints(numberOfDataPoints)
      };
    })
  };
  return getSplineChartOptions(dataObject);
};
