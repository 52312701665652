import React from "react";
import { Link } from "react-router-dom";
import AppSubmenu from "./AppSubmenu";
import { useAuthStore } from "./common/hooks/storeHooks";

import "./AppMenu.scss";
import { Button } from "primereact/button";

import Logo from "./assets/images/logo.svg";

const AppMenu = props => {
  const { logOut } = useAuthStore();
  return (
    <div className="layout-sidebar" onClick={props.onMenuClick}>
      <Link to="/" className="logo">
        <span className="app-name">
          <img
            src={Logo}
            style={{
              color: "#fff"
            }}
          />
        </span>
      </Link>

      <div className="layout-menu-container">
        <AppSubmenu
          items={props.model}
          menuMode={props.menuMode}
          parentMenuItemActive
          menuActive={props.active}
          mobileMenuActive={props.mobileMenuActive}
          root
          onMenuitemClick={props.onMenuitemClick}
          onRootMenuitemClick={props.onRootMenuitemClick}
        />
      </div>
      <div className="appmenu-logout" onClick={logOut}>
        <Button className="p-button-text button-text" label="Wyloguj" icon="pi pi-sign-out"></Button>
      </div>
    </div>
  );
};

export default AppMenu;
