import { TIME_CONSTANTS_VALUES, TIME_CONSTANTS } from "../constants/timeValues";
import { getCurrentTimeRange } from "../utils/chartUtils";

const { hour, hour_quarter } = TIME_CONSTANTS_VALUES;

export const createDataPoints = (numberOfPoints, initialNumber) => {
  const data = [];
  let y = initialNumber ?? 100;
  for (let i = 0; i < numberOfPoints; i++) {
    y += Math.round(Math.random() * 10 - 5);
    data.push(y);
  }
  return data;
};

export const createDataPointsCurrentDay = (date, interval = hour_quarter) => {
  const numberOfPoints = (24 * hour) / interval;
  return createDataPoints(numberOfPoints).map((point, index) =>
    getCurrentTimeRange(date, interval) > index ? point / 10 : null
  );
};
