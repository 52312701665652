import { useState, useCallback, useMemo, useEffect } from "react";
import { PvProduction } from "./PvProduction";
import { localizations } from "../../../../mocks/pvProduction";
import { observer } from "mobx-react";
import { getDayBeginningDateTime, getDayEndDateTime } from "../../../../utils/helpers";
import { LocalizationsSelection } from "./components/LocalizationsSelection/LocalizationsSelection";
import { useCityStore, useWeatherStore } from "../../../../common/hooks/storeHooks";

export const PvProductionPerLocalization = observer(() => {
  const [date, setDate] = useState(getDayBeginningDateTime());
  const [firstLocalization, setFirstLocalization] = useState(localizations[0]);
  const [secondLocalization, setSecondLocalization] = useState(localizations[1]);

  const getOptions = excludedLocalization =>
    localizations.filter(localization => localization !== excludedLocalization);

  const firstLocalizations = useMemo(() => getOptions(secondLocalization), [secondLocalization]);

  const secondsLocalizations = useMemo(() => getOptions(firstLocalization), [firstLocalization]);
  const weather = useWeatherStore();
  const cities = useCityStore();
  const [telemetryData, setTelemetryData] = useState({
    device_id: 145,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  useEffect(() => {
    const date = new Date("2021-08-11");
    cities.fetchCities();
    weather.fetchWeatherData("Krakow", getDayBeginningDateTime(date).toISOString(), getDayEndDateTime(date));
  }, [weather, cities]);

  const getData = useCallback(
    () => ({
      localizations: [firstLocalization, secondLocalization],
      names: [firstLocalization, secondLocalization],
      startDate: date,
      dates: [date],
      weathers: [weather.weatherData, weather.weatherData]
    }),
    [firstLocalization, secondLocalization, date, weather.weatherData]
  );

  const onDropdownChange = setter => e => setter(e.value);

  return (
    <>
      <PvProduction
        deviceId={telemetryData.device_id}
        chartName={"PvProductionPerLocalization"}
        getData={getData}
        withWeather
        renderOptions={() => (
          <LocalizationsSelection
            setFirstLocalization={onDropdownChange(setFirstLocalization)}
            setSecondLocalization={onDropdownChange(setSecondLocalization)}
            firstLocalizations={firstLocalizations}
            currentFirstLocalization={firstLocalization}
            currentSecondLocalization={secondLocalization}
            secondsLocalizations={secondsLocalizations}
            date={date}
            setDate={setDate}
          />
        )}
      />
      <div></div>
    </>
  );
});
