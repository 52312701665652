import React from "react";
import { Card } from "../components/UI/Card/Card";
import { Grid } from "../components/UI/Grid";

export const About = () => {
  return (
    <Grid>
      <Card>
        <h5>Empty Page</h5>
        <p>Use this page to start from scratch and place your custom content.</p>
      </Card>
    </Grid>
  );
};
