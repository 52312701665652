import React from "react";
import { observer } from "mobx-react";
import { TreeSelect } from "primereact/treeselect";

export const CustomTreeSelect = observer(
  ({ nodes, selectedKeys, setSelectedKeys, placeholder, display, selectionMode }) => {
    return (
      <TreeSelect
        options={nodes}
        selectionMode={selectionMode || "single"}
        value={selectedKeys}
        onChange={e => setSelectedKeys(e.value)}
        filter
        filterMode="strict"
        placeholder={placeholder}
        display={display}
      />
    );
  }
);
