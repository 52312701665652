import { getHorizontalBarChartOptions } from "../constants/defaultChartOptions";
import { BUILDING_TYPES_CATEGORIES } from "../utils/buildingTypesUtils";

export const getPowerDipsChartData = powerDipsData => {
  const dataObject = {
    title: "Zapady zasilania",
    categories: BUILDING_TYPES_CATEGORIES,
    yAxisLabel: "Sumaryczny czas, gdy średnie napięcie spadało poniżej 225V lub podniosło sie powyżej 235V [ min ]",
    yAxisUnit: "min",
    stacking: "normal",
    data: [
      {
        name: "weekendy",
        data: powerDipsData.weekends,
        dataLabels: dataLabels
      },
      {
        name: "16 - 8 dni powszednie",
        data: powerDipsData.afternoonfAndNights,
        dataLabels: dataLabels
      },
      {
        name: "8 - 16 dni powszednie",
        data: powerDipsData.midDays,
        dataLabels: dataLabels
      }
    ]
  };
  return getHorizontalBarChartOptions(dataObject);
};

const dataLabels = Object.freeze({
  enabled: true,
  format: `{point.y} min`
});
