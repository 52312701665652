import React from "react";
import { ClientTreeSelect } from "../components/TreeSelect/ClientTreeSelect";
import { DeviceTreeSelect } from "../components/TreeSelect/DeviceTreeSelect";
import { Card } from "../components/UI/Card/Card";
import { Grid } from "../components/UI/Grid";

export const Clients = () => {
  return (
    <Grid>
      <Card>
        <ClientTreeSelect />
        <DeviceTreeSelect />
      </Card>
    </Grid>
  );
};
