import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLocation, withRouter } from "react-router-dom";

const AppBreadcrumb = props => {
  const location = useLocation();
  const pathname = location.pathname;

  let name = pathname.replace("/", "");
  if (props.routers) {
    let currentRouter = props.routers.find(router => router.path === pathname);
    name = currentRouter ? currentRouter["meta"].breadcrumb : [{ label: name }];
  }

  return <BreadCrumb model={name} />;
};

export default withRouter(AppBreadcrumb);
