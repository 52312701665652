import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { TIME_CONSTANTS_VALUES, TIME_CONSTANTS, CURRENT_YER } from "../../../../constants/timeValues";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { OverviewBoxesSection } from "../../../../components/OverviewBoxesSection/OverviewBoxesSection";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { getSplineChartOptions } from "../../../../constants/defaultChartOptions";
import { observer } from "mobx-react";
import { RESOLUTIONS_NUMBERS, week } from "../../../../constants/chartOptions";
import { calculateSum } from "../../../../utils/costs";
import { calculateActiveEnergyTotalCost, calculateDistributionCost, getSummaryCost } from "../../../../mocks/costs";
import { getOsd } from "../../../../api/api";
import { showToast } from "../../../../utils/toast";
import { calculateNumberOfPoints } from "../../../../utils/chartUtils";
import { TOAST_MESSAGE_TYPE } from "../../../../constants/toastText";
import { Toast } from "primereact/toast";

const DATA_SERIES = [
  { label: "Ostatnia noc", dataName: "active_energy_total" },
  { label: "Średnia tygodniowa", dataName: "active_energy_total" }
];

const NUMBER_OF_DAYS_IN_WEEK = 7;

export const LastNight = observer(() => {
  const toastRef = useRef(null);
  const { selectedDevices } = useClientStore();
  const { id: deviceId, branchId, osdId, contractualCapacity } = selectedDevices;

  const [resolution, setResolution] = useState(TIME_CONSTANTS.MIN_15);
  const [telemetryData, setTelemetryData] = useState([]);
  const [averageWattHours, setAverageWattHours] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [sinceDate, setSinceDate] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]));
  const [activeEnergyTotal, setActiveEnergyTotal] = useState([{ data: [] }]);
  const [osdInfo, setOsdInfo] = useState({});
  const [numberOfPoints, setNumberOfPoints] = useState("");
  const [lastNightEnergy, setLastNightEnergy] = useState(0);
  const [weekNightEnergy, setWeektNightEnergy] = useState(0);
  const [lastNightSum, setLastNightSum] = useState(0);
  const [weekSum, setWeekSum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const cardElements = [
    {
      title: "Energia Czynna - Ostatnia noc",
      icon: "pi-dollar",
      color: "#64B5F6",
      iconColor: "#1976D2",
      elements: [
        {
          number: lastNightEnergy,
          text: "Illość kWh"
        },
        {
          number: lastNightSum,
          text: "Suma PLN"
        }
      ]
    },
    {
      title: "Energia Czynna - Średnia tygodniowa",
      icon: "pi-dollar",
      color: "#7986CB",
      iconColor: "#303F9F",
      elements: [
        {
          number: weekNightEnergy,
          text: "Illość kWh"
        },
        {
          number: weekSum,
          text: "Suma PLN"
        }
      ]
    }
  ];

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      getMyLastNightData(selectedDevices.id);
    }
  }, [selectedDevices.id]);

  useEffect(() => {
    calculateActiveEnergyOverviewElements();
    getMyLastNightChartData();
  }, [telemetryData]);

  useEffect(() => {
    calculateNumberOfPoints(toDate, sinceDate, resolution, displayToast, setNumberOfPoints);
    if (isNaN(deviceId)) {
      displayToast("Nie wybrano pliszki", TOAST_MESSAGE_TYPE.WARN);
    }
  }, []);

  useEffect(() => {
    if (!isNaN(deviceId) && numberOfPoints) {
      if (branchId === undefined || osdId === null) {
        setOsdInfo(undefined);
        forceUpdateEmptyCosts();
        displayToast(
          "Brak szczegółowych informacji o pliszce! Zakładka Ostatnia Noc oraz Średnia Tygodniowa została wyłączona",
          TOAST_MESSAGE_TYPE.WARN
        );
      } else {
        fetchOsdInfo(CURRENT_YER.toString(), branchId, osdId);
      }
      getMyLastNightData(deviceId);
    }
  }, [deviceId, numberOfPoints]);

  const getMyLastNightData = useCallback(async id => {
    setIsLoading(true);
    const averageEnegyForTheLastWeek = week.map(async day => {
      const { data } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(
          new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1] * (day.value + 1))
        ),
        end: parseDateToApiFormat(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1] * day.value)),
        resolution: resolution,
        avm_active_energy_total_sum: 1
      });

      return { ...day, value: data };
    });

    const dataWattHoursPerWeek = (await Promise.all(averageEnegyForTheLastWeek)).map(data =>
      data?.value[0]?.data?.map(wattHoursDataForTheDay => wattHoursDataForTheDay[1])
    );

    const summWattHoursInAGivenHour = dataWattHoursPerWeek[0]?.map((measurementOnAGivenDay, index) =>
      dataWattHoursPerWeek.reduce((summ, currentValue) => summ + currentValue[index], 0)
    );

    const averageWattHoursInAGivenHour = summWattHoursInAGivenHour?.map(element => {
      return element / NUMBER_OF_DAYS_IN_WEEK;
    });

    setAverageWattHours(averageWattHoursInAGivenHour);

    const { data } = await getTelemetryData({
      devices: id,
      begin: parseDateToApiFormat(sinceDate),
      end: parseDateToApiFormat(toDate),
      resolution: resolution,
      avm_active_energy_total_sum: 1
    });

    if (!data || data?.some(dataPart => !dataPart.data?.length)) {
      setActiveEnergyTotal([{ data: [] }]);
    }
    setActiveEnergyTotal(data);
    setTelemetryData(data);
    setIsLoading(false);
  });

  const gettPlotDataFromArray = (arrayData, dataName) => {
    const newArrayOfData = [];
    if (arrayData !== undefined) {
      for (let dataNumber = 0; dataNumber < arrayData.length; dataNumber++) {
        newArrayOfData[dataNumber] = arrayData[dataNumber].slice();
      }
    }

    const plotData = {
      name: dataName,
      data: newArrayOfData
    };
    return plotData;
  };

  const fetchOsdInfo = useCallback(async (year, branchId, rateId) => {
    try {
      const osd = await getOsd({ year, branchId, rateId });
      setOsdInfo(osd);
    } catch (err) {
      displayToast(err, "err");
    }
  }, []);

  const getMyLastNightChartData = useCallback(() => {
    const activeEnergyTotalData = telemetryData?.find(item => item.dataType.includes("active_energy_total"))?.data;
    const lastNightPlot = gettPlotDataFromArray(activeEnergyTotalData, DATA_SERIES[0].label);
    const weekNightPlot = gettPlotDataFromArray(activeEnergyTotalData, DATA_SERIES[1].label);
    const dataObject = {
      title: "Profile energii czynnej",
      yAxisLabel: "Energia czynna",
      yAxisUnit: "Wh",
      crosshairs: true,
      tooltipShared: true,
      pointInterval: 900000, // 15 minutes,
      startTime: Date.parse(new Date(2018, 1, 13, 18, 0, 0)),
      plots: [lastNightPlot, weekNightPlot]
    };
    if (dataObject.plots[1].data !== undefined) {
      for (let i = 0; i < dataObject.plots[1].data.length; i++) {
        dataObject.plots[1].data[i].splice(1, 1, averageWattHours[i]);
      }
      dataObject.plots[1].data.splice(dataObject.plots[1].data.findIndex(value => isNaN(value[1])));
    }
    return getSplineChartOptions(dataObject);
  }, [telemetryData, averageWattHours]);

  const chartData = useMemo(() => getMyLastNightChartData(), [telemetryData]);

  const activeEnergyTotalCostWeek = useMemo(
    () => calculateActiveEnergyTotalCost([{ data: chartData.series[1].data }], resolution),
    [resolution, chartData]
  );
  const activeEnergyTotalCostLastNight = useMemo(
    () => calculateActiveEnergyTotalCost(activeEnergyTotal, resolution),
    [resolution, activeEnergyTotal]
  );

  const distributionWeek = useMemo(
    () =>
      calculateDistributionCost(
        [{ data: chartData.series[1].data }],
        osdInfo?.data?.response?.[0],
        resolution,
        contractualCapacity
      ),
    [chartData, osdInfo, resolution, contractualCapacity]
  );
  const distributionCostLastNight = useMemo(
    () => calculateDistributionCost(activeEnergyTotal, osdInfo?.data?.response?.[0], resolution, contractualCapacity),
    [activeEnergyTotal, osdInfo, resolution, contractualCapacity]
  );

  const sumWeek = useMemo(
    () => calculateSum(activeEnergyTotalCostWeek, distributionWeek),
    [activeEnergyTotalCostWeek, distributionWeek]
  );
  const sumLastNight = useMemo(
    () => calculateSum(activeEnergyTotalCostLastNight, distributionCostLastNight),
    [activeEnergyTotalCostLastNight, distributionCostLastNight]
  );

  const calculateActiveEnergyOverviewElements = useCallback(() => {
    //runs 3 times... if we goback to this screen from another view
    const summaryCostWeek = getSummaryCost(sumWeek);
    const summaryCostLastNight = getSummaryCost(sumLastNight);
    if (osdInfo !== undefined && telemetryData !== undefined && telemetryData?.some(({ data }) => data.length)) {
      const activeEnergyTotalWeek = [{ data: chartData.series[1].data }][0].data;
      const activeEnergyTotalLastNIght = telemetryData[0].data;

      const totalConsumptionWeek = activeEnergyTotalWeek.reduce((prev, current) => prev + current[1], 0);
      const totalConsumptionLastNight = activeEnergyTotalLastNIght.reduce((prev, current) => prev + current[1], 0);

      const totalTime = RESOLUTIONS_NUMBERS[resolution] / RESOLUTIONS_NUMBERS[TIME_CONSTANTS.HOUR_1];

      const totalConsumptionInKhWeek = Math.round(((totalConsumptionWeek * totalTime) / 1000) * 100) / 100;
      const totalConsumptionInKhLastNight = Math.round(((totalConsumptionLastNight * totalTime) / 1000) * 100) / 100;

      setLastNightEnergy(totalConsumptionInKhLastNight);
      setWeektNightEnergy(totalConsumptionInKhWeek);
      setLastNightSum(summaryCostLastNight);
      setWeekSum(summaryCostWeek);
    } else {
      forceUpdateEmptyCosts();
      displayToast(
        "Brak danych z wybranego zakresu! Ostatnia Noc oraz Średnia Tygodniowa została wyłączona",
        TOAST_MESSAGE_TYPE.WARN
      );
    }
  }, [telemetryData, resolution, sumWeek, sumLastNight, chartData.series]);

  const forceUpdateEmptyCosts = () => {
    setLastNightEnergy("--");
    setWeektNightEnergy("--");
    setLastNightSum("--");
    setWeekSum("--");
  };

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  return (
    <Grid>
      <Toast ref={toastRef} />
      <OverviewBoxesSection boxes={cardElements} boxFlexGrow={6} />
      <Card flexGrow={12}>
        <Chart
          options={chartData}
          deviceId={typeof selectedDevices.id === "number" ? selectedDevices.id : null}
          chartResolution={resolution}
          chartName={"LastNight"}
          displayNotesList
          isLoading={isLoading}
        />
      </Card>
      {/* commented component untill further notice */}
      {/* <Card flexGrow={4}>
        <LastNightDataTable />
      </Card> */}
    </Grid>
  );
});
