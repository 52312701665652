import React from "react";

import "./OverviewBox.scss";

export const OverviewBox = ({ flexGrow, title, elements, icon, color, iconColor }) => {
  return (
    <div className={`p-col-${flexGrow} overview-box-container`}>
      <div className="card widget-overview-box" style={{ borderLeftColor: color }}>
        <div className="title-container">
          <div className="icon-container">
            <span className="overview-icon" style={{ background: color, color: iconColor }}>
              <i className={`pi ${icon}`}></i>
            </span>
          </div>
          <div className="texts-container">
            <span className="overview-title" style={{ color }}>
              {title}
            </span>
          </div>
        </div>
        <div className="p-grid overview-detail">
          {elements.map((element, index) => (
            <div
              key={index}
              className={`p-col-${elements.length === 2 ? 6 : elements.length === 3 ? 4 : 12} overview-container`}
            >
              <div className="overview-number-container">
                <span className="overview-number">{element.number}</span>
              </div>
              <div className="overview-subtex-container">
                <span className="overview-subtext">{element.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
