import { useState, useCallback } from "react";
import { PvProduction } from "./PvProduction";
import { getPreviousDate, getDayBeginningDateTime, getLocalizedDateString } from "../../../../utils/helpers";
import { TwoDatesSelection } from "../../../../components/TwoDatesSelection/TwoDatesSelection";

export const PvProductionPerDate = () => {
  const [firstDate, setFirstDate] = useState(getDayBeginningDateTime(getPreviousDate()));
  const [secondDate, setSecondDate] = useState(new Date());
  const [telemetryData, setTelemetryData] = useState({
    device_id: 145,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  const getData = useCallback(
    () => ({
      dates: [firstDate, secondDate],
      names: [getLocalizedDateString(firstDate), getLocalizedDateString(secondDate)],
      startDate: getDayBeginningDateTime(firstDate),
      withRatedPower: true
    }),
    [firstDate, secondDate]
  );

  return (
    <PvProduction
      deviceId={telemetryData.device_id}
      chartName={"PvProductionPerDate"}
      getData={getData}
      renderOptions={() => (
        <TwoDatesSelection
          firstDate={firstDate}
          secondDate={secondDate}
          setFirstDate={setFirstDate}
          setSecondDate={setSecondDate}
        />
      )}
    />
  );
};
