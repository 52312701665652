import React, { useCallback, useEffect, useState, useRef } from "react";
import { OverviewBoxesSection } from "../../../../components/OverviewBoxesSection/OverviewBoxesSection";
import { TwoDatesSelection } from "../../../../components/TwoDatesSelection/TwoDatesSelection";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { getDemandIncreaseChartData } from "../../../../mocks/demandIncrese";
import { getDayBeginningDateTime, getPreviousDate, getBeforePreviousDate } from "../../../../utils/helpers";
import { getTelemetryData } from "../../../../api/clientApi";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { TIME_CONSTANTS } from "../../../../constants/timeValues";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import { showToast } from "../../../../utils/toast";
import { TOAST_MESSAGE_TYPE } from "../../../../constants/toastText";

export const DemandIncrease = observer(() => {
  const { selectedDevices } = useClientStore();
  const [firstDate, setFirstDate] = useState(getDayBeginningDateTime(getBeforePreviousDate()));
  const [secondDate, setSecondDate] = useState(getDayBeginningDateTime(getPreviousDate()));
  const [resolution, setResolution] = useState(TIME_CONSTANTS.HOUR_1);
  const toastRef = useRef(null);

  const [dayFirstDate, setDayFirstDate] = useState("");
  const [daySecondDate, setDaySecondDate] = useState("");
  const [monthFirstData, setMxonthFirstData] = useState("");
  const [monthSecondData, setMxonthSecondData] = useState("");
  const [yearFirstData, setYearFirstData] = useState("");
  const [yearSecondData, setYearSecondData] = useState("");
  const [firstDayPowerDemand, setFirstDayPowerDemand] = useState(0);
  const [secondDayPowerDemand, setSecondDayPowerDemand] = useState(0);
  const [increaseQuantilyDemand, steIncreaseQuantilyDemand] = useState(0);
  const [increasePercentageDemand, steIncreasePercentageDemand] = useState(0);
  const [firstDayDayilyEnergyConsumption, setFirstDayDayilyEnergyConsumption] = useState(0);
  const [secondDayDayilyEnergyConsumption, setSecondDayDayilyEnergyConsumption] = useState(0);
  const [increaseEnergyConsumption, setIncreaseEnergyConsumption] = useState(0);
  const [increaseEnergyConsumptionPercentage, setIncreaseEnergyConsumptionPercentage] = useState(0);
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const boxesCardsData = [
    {
      title: "Dobowe zapotrzebowanie na moc - W",
      color: "#64B5F6",
      icon: "pi-chart-bar",
      iconColor: "#1976D2",
      elements: [
        {
          number: firstDayPowerDemand,
          text: dayFirstDate ? `${dayFirstDate}.${monthFirstData + 1}.${yearFirstData}` : "wybierz datę"
        },
        {
          number: secondDayPowerDemand,
          text: daySecondDate ? `${daySecondDate}.${monthSecondData + 1}.${yearSecondData}` : "wybierz datę"
        }
      ]
    },
    {
      title: "Wzrost zapotrzebowania ",
      color: "#7986CB",
      icon: "pi-chart-line",
      iconColor: "#303F9F",
      elements: [
        {
          number: increaseQuantilyDemand,
          text: "Ilościowy W"
        },
        {
          number: increasePercentageDemand,
          text: "Procentowy %"
        }
      ]
    },
    {
      title: "Dobowe zużycie energii - Wh",
      color: "#4DB6AC",
      icon: "pi-chart-bar",
      iconColor: "#00796B",
      elements: [
        {
          number: firstDayDayilyEnergyConsumption,
          text: dayFirstDate ? `${dayFirstDate}.${monthFirstData + 1}.${yearFirstData}` : "wybierz datę"
        },
        {
          number: secondDayDayilyEnergyConsumption,
          text: daySecondDate ? `${daySecondDate}.${monthSecondData + 1}.${yearSecondData}` : "wybierz datę"
        }
      ]
    },
    {
      title: "Wzrost zużycia",
      color: "#4DD0E1",
      icon: "pi-chart-line",
      iconColor: "#0097A7",
      elements: [
        {
          number: increaseEnergyConsumption,
          text: "Ilościowy Wh"
        },
        {
          number: increaseEnergyConsumptionPercentage,
          text: "Procentowy %"
        }
      ]
    }
  ];

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      getMyDemandIncreaseData(selectedDevices.id);
    }
  }, [selectedDevices.id, firstDate, secondDate]);

  const getMyDemandIncreaseData = useCallback(
    async id => {
      setIsLoading(true);
      const deepCopyFirstDate = new Date(firstDate);
      const deepCopySecondDate = new Date(secondDate);
      const endFirstDate = new Date(deepCopyFirstDate.setHours(23));
      const endSecondDate = new Date(deepCopySecondDate.setHours(23));

      const numberOfDayFirstDate = firstDate.getDate();
      const numberOfDaySecondtDate = secondDate.getDate();
      const numberOfMonthFirstDate = firstDate.getMonth();
      const numberOfMonthSecondtDate = secondDate.getMonth();
      const numberOfYearFirstDate = firstDate.getFullYear();
      const numberOfYearSecondDate = secondDate.getFullYear();

      const { data: firstDayData } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(firstDate),
        end: parseDateToApiFormat(endFirstDate),
        resolution: resolution,
        avm_active_energy_total_sum: 1
      });

      const { data: secondDayData } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(secondDate),
        end: parseDateToApiFormat(endSecondDate),
        resolution: resolution,
        avm_active_energy_total_sum: 1
      });

      if (firstDayData[0]?.data?.length > 0 && secondDayData[0]?.data?.length > 0) {
        const dataFromFirstDay = firstDayData[0].data.map(dataElement => {
          return dataElement[1];
        });

        const numberOfMeasurements = dataFromFirstDay.length;

        const dataFromSecondDay = secondDayData[0].data.map(dataElement => {
          return dataElement[1];
        });

        const dataFromBothDays = secondDayData[0].data.map((dataElement, index) => {
          return [dataFromFirstDay[index], dataElement[1]];
        });

        const quantitativeDifferenceArray = dataFromBothDays.map(dataElement => {
          return Math.round(dataElement[1] - dataElement[0]);
        });

        const percentageDifferenceArray = dataFromBothDays.map(dataElement => {
          return Math.round(((dataElement[1] - dataElement[0]) / dataElement[0]) * 100);
        });

        const sumFirstDayPowerDemand = Math.round(
          dataFromFirstDay.reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
          }, 0)
        );

        const sumSecondtDayPowerDemand = Math.round(
          dataFromSecondDay.reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
          }, 0)
        );

        const firstDayDayilyEnergyConsumption = Math.round(sumFirstDayPowerDemand / numberOfMeasurements);
        const secondDayDayilyEnergyConsumption = Math.round(sumSecondtDayPowerDemand / numberOfMeasurements);

        const increaseEnergyConsumption = Math.round(
          secondDayDayilyEnergyConsumption - firstDayDayilyEnergyConsumption
        );
        const increaseEnergyConsumptionPercentages = Math.round(
          ((secondDayDayilyEnergyConsumption - firstDayDayilyEnergyConsumption) / firstDayDayilyEnergyConsumption) * 100
        );
        setDayFirstDate(numberOfDayFirstDate);
        setDaySecondDate(numberOfDaySecondtDate);
        setMxonthFirstData(numberOfMonthFirstDate);
        setMxonthSecondData(numberOfMonthSecondtDate);
        setYearFirstData(numberOfYearFirstDate);
        setYearSecondData(numberOfYearSecondDate);

        setFirstDayPowerDemand(sumFirstDayPowerDemand);
        setSecondDayPowerDemand(sumSecondtDayPowerDemand);

        steIncreaseQuantilyDemand(sumSecondtDayPowerDemand - sumFirstDayPowerDemand);
        steIncreasePercentageDemand(
          Math.round(((sumSecondtDayPowerDemand - sumFirstDayPowerDemand) / sumFirstDayPowerDemand) * 100)
        );

        setFirstDayDayilyEnergyConsumption(firstDayDayilyEnergyConsumption);
        setSecondDayDayilyEnergyConsumption(secondDayDayilyEnergyConsumption);

        setIncreaseEnergyConsumption(increaseEnergyConsumption);
        setIncreaseEnergyConsumptionPercentage(increaseEnergyConsumptionPercentages);

        setChartData(getDemandIncreaseChartData(quantitativeDifferenceArray, percentageDifferenceArray));
      } else {
        displayToast("Brak danych dla wybranych dni", TOAST_MESSAGE_TYPE.WARN);
        setChartData(getDemandIncreaseChartData([], []));
      }
      setIsLoading(false);
    },
    [firstDate, secondDate]
  );

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  return (
    <Grid>
      <Toast ref={toastRef} />
      <TwoDatesSelection
        firstDate={firstDate}
        secondDate={secondDate}
        setFirstDate={setFirstDate}
        setSecondDate={setSecondDate}
      />
      <OverviewBoxesSection boxes={boxesCardsData} boxFlexGrow={3} />
      <Card>
        {chartData && (
          <Chart
            options={chartData}
            deviceId={typeof selectedDevices.id === "number" ? selectedDevices.id : null}
            chartName={"DemandIncrease"}
            displayNotesList
            isLoading={isLoading}
          />
        )}
      </Card>
    </Grid>
  );
});
