import moment from "moment";
import { MAX_NUMBER_OF_DATA_POINTS, RANGES, RESOLUTIONS_NUMBERS } from "../constants/chartOptions";
import { TIME_CONSTANTS_VALUES } from "../constants/timeValues";
import { TOAST_MESSAGE_TYPE } from "../constants/toastText";
import { getDaysQuantity, parseDate } from "./helpers";
import WeatherIcons from "../assets/images/weather";

const { hour, hour_quarter, day } = TIME_CONSTANTS_VALUES;

export const calculateNumberOfPoints = (
  toDate,
  sinceDate,
  resolution,
  selectedDevices,
  displayToast,
  setNumberOfDataPoints,
  futureToDate = false,
  getData
) => {
  const parsedSinceDate = parseDate(sinceDate);
  const parsedToDate = parseDate(toDate);
  if (!parsedSinceDate || !parsedToDate) {
    displayToast("Wybierz datę początkową i końcową.", TOAST_MESSAGE_TYPE.ERR);
    return;
  }
  if (!futureToDate && parsedToDate > Date.now()) {
    displayToast("Końcowa data nie może znajdować się w przyszłości.", TOAST_MESSAGE_TYPE.ERR);
    return;
  }
  if (parsedSinceDate > parsedToDate) {
    displayToast("Data początkowa nie może być póżniej niż data końcowa.", TOAST_MESSAGE_TYPE.ERR);
    return;
  }
  const calculatedNumberOfPoints = ((parsedToDate - parsedSinceDate) / RESOLUTIONS_NUMBERS[resolution]).toFixed();
  if (calculatedNumberOfPoints > MAX_NUMBER_OF_DATA_POINTS) {
    displayToast(
      "Zbyt dużo punktów do wyświetlenia. Wybierz mniejszy zakres, lub mniejszą rozdzielczość.",
      TOAST_MESSAGE_TYPE.ERR
    );
    return;
  }

  if (isNaN(selectedDevices.id)) {
    displayToast("Nie wybrano pliszki.", "warn");
    return;
  }
  setNumberOfDataPoints(calculatedNumberOfPoints);
  getData?.();
};

export const POINTS_QUANTITIES = Object.freeze({
  [RANGES.DAY]: (quantity = hour_quarter) => 24 * (hour / quantity),
  [RANGES.MONTH]: date => (getDaysQuantity(date) - 1) * (day / (hour * 6))
});

export const getCurrentTimeRange = (date, interval = hour_quarter) => {
  const points = moment().diff(moment(date)) / interval;
  const timePoints = moment().isSame(date, "day")
    ? Number.parseInt(points.toFixed(0))
    : POINTS_QUANTITIES[RANGES.DAY]();

  return timePoints;
};
export const generateWeatherData = (data, weatherData, interval = 4) => {
  return [
    ...data.map((point, index) => {
      const weatherIndex = (index / interval).toFixed(0);
      const isFullHour = (index / interval) % 1 === 0;
      const length = weatherData.length - 1;
      if (length >= weatherIndex) {
        const data = {
          tempValue: weatherData[weatherIndex]?.weatherData?.main.temp,
          humidityValue: weatherData[weatherIndex]?.weatherData?.main.humidity,
          windValue: weatherData[weatherIndex]?.weatherData?.wind.speed,
          windDirectionValue: weatherData[weatherIndex]?.weatherData?.wind.deg,
          weatherDescriptionValue: weatherData[weatherIndex]?.weatherData?.weather[0].description,
          cloudinessValue: weatherData[weatherIndex]?.weatherData?.clouds.all
        };

        return {
          y: point,
          custom: isFullHour ? data : {},
          marker: isFullHour
            ? {
                symbol: `url(${WeatherIcons[`Icon${weatherData[weatherIndex]?.weatherData?.weather[0].icon}`]})`,
                width: 25,
                height: 25
              }
            : { enabled: false }
        };
      } else {
        return {
          y: point,
          custom: {},
          marker: { enabled: false }
        };
      }
    })
  ];
};
