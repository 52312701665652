import { Start } from "./pages/Start";
import { Costs } from "./pages/onlineProfile/Costs";
import { Controls } from "./pages/Controls";
import { Clients } from "./pages/Clients";
import { Users } from "./pages/Users";
import { Devices } from "./pages/Devices";
import { Profile } from "./pages/onlineProfile/Profile";
import { ServiceLevel } from "./pages/ServiceLevel";
import { About } from "./pages/About";
import { LastNight } from "./pages/reports/lanterns/LastNight/LastNight";
import { ActiveAndPassiveEnergy } from "./pages/reports/lanterns/ActiveAndPassiveEnergy/ActiveAndPassiveEnergy";
import { ActiveEnergyDetails } from "./pages/reports/lanterns/ActiveEnergyDetails/ActiveEnergyDetails";
import { UsagePerFixture } from "./pages/reports/lanterns/UsagePerFixture/UsagePerFixture";
import { PvProductionPerDate } from "./pages/reports/pv/PvProduction/PvProductionPerDate";
import { PvProductionPerLocalization } from "./pages/reports/pv/PvProduction/PvProductionPerLocalization";
import { OsdBalance } from "./pages/reports/pv/OsdBalance/OsdBalance";
import { PvProductionDetails } from "./pages/reports/pv/PvProductionDetails/PvProductionDetails";
import { PvProductionEfficiency } from "./pages/reports/pv/PvProductionEfficiency/PvProductionEfficiency";
import { DistributionCosts } from "./pages/reports/tariffs/DistributionCosts/DistributionCosts";
import { ContractPowerReduction } from "./pages/reports/tariffs/ContractPowerReduction/ContractPowerReduction";
import { PowerDemand } from "./pages/reports/power/PowerDemand/PowerDemand";
import { PowerDemandRanges } from "./pages/reports/power/PowerDemandRanges/PowerDemandRanges";
import { PassivePowerCosts } from "./pages/reports/power/PassivePowerCosts/PassivePowerCosts";
import { RelativeCosts } from "./pages/reports/power/RelativeCosts/RelativeCosts";
import { PhaseVoltages } from "./pages/reports/quality/PhaseVoltages/PhaseVoltages";
import { HigherHarmonics } from "./pages/reports/quality/HigherHarmonics/HigherHarmonics";
import { PowerDips } from "./pages/reports/quality/PowerDips/PowerDips";
import { LowQualityCounter } from "./pages/reports/quality/LowQualityCounter/LowQualityCounter";
import { DemandIncrease } from "./pages/reports/trends/DemandIncrease/DemandIncrease";
import { HighestUsages } from "./pages/reports/trends/HighestUsages/HighestUsages";
import { ComparePvProduction } from "./pages/reports/pv/ComparePvProduction/ComparePvProduction";
import { Map } from "./pages/onlineProfile/Map/Map";
import { CompareActiveEnergy } from "./pages/reports/lanterns/CompareActiveEnergy/CompareActiveEnergy";

export const routers = [
  {
    path: "/",
    component: Start,
    exact: true,
    meta: { breadcrumb: [{ label: "Start" }] }
  },
  {
    path: "/costs",
    component: Costs,
    meta: { breadcrumb: [{ label: "Koszty" }] }
  },
  {
    path: "/profile",
    component: Profile,
    meta: { breadcrumb: [{ label: "Profil" }] }
  },
  {
    path: "/profilePv",
    component: PvProductionDetails,
    meta: { breadcrumb: [{ label: "Profil PV" }] }
  },
  {
    path: "/map",
    component: Map,
    meta: { breadcrumb: [{ label: "Mapa" }] }
  },
  {
    path: "/lastNight",
    component: LastNight,
    meta: { breadcrumb: [{ label: "Analityka" }, { label: "Ostatnia noc" }] }
  },
  {
    path: "/activeAndPassiveEnergy",
    component: ActiveAndPassiveEnergy,
    meta: { breadcrumb: [{ label: "Analityka" }, { label: "Energia czynna i bierna" }] }
  },
  {
    path: "/activeEnergyDetails",
    component: ActiveEnergyDetails,
    meta: { breadcrumb: [{ label: "Analityka" }, { label: "Energia czynna - szczegóły" }] }
  },
  {
    path: "/usagePerFixture",
    component: UsagePerFixture,
    meta: { breadcrumb: [{ label: "Analityka" }, { label: "Zużycie na oprawę" }] }
  },
  {
    path: "/compareActiveEnergy",
    component: CompareActiveEnergy,
    meta: { breadcrumb: [{ label: "Analityka" }, { label: "Porównanie profili energii czynnej" }] }
  },
  {
    path: "/pvProductionPerDate",
    component: PvProductionPerDate,
    meta: { breadcrumb: [{ label: "PV" }, { label: "Produkcja PV - dni" }] }
  },
  {
    path: "/pvProductionPerLocalization",
    component: PvProductionPerLocalization,
    meta: { breadcrumb: [{ label: "PV" }, { label: "Produkcja PV - lokalizacje" }] }
  },
  {
    path: "/osdBalance",
    component: OsdBalance,
    meta: { breadcrumb: [{ label: "PV" }, { label: "Bilans z OSD" }] }
  },
  {
    path: "/pvProductionDetails",
    component: PvProductionDetails,
    meta: { breadcrumb: [{ label: "PV" }, { label: "Produkcja PV - szczegóły" }] }
  },
  {
    path: "/pvProductionEfficiency",
    component: PvProductionEfficiency,
    meta: { breadcrumb: [{ label: "PV" }, { label: "Efektywność produkcji PV" }] }
  },
  {
    path: "/comparePvProduction",
    component: ComparePvProduction,
    meta: { breadcrumb: [{ label: "PV" }, { label: "Porównanie profili produkcji PV" }] }
  },
  {
    path: "/distributionCosts",
    component: DistributionCosts,
    meta: { breadcrumb: [{ label: "Taryfy" }, { label: "Koszty dystrybucji" }] }
  },
  {
    path: "/contractedPowerReduction",
    component: ContractPowerReduction,
    meta: { breadcrumb: [{ label: "Taryfy" }, { label: "Obniżenie mocy umownej" }] }
  },
  {
    path: "/powerDemand",
    component: PowerDemand,
    meta: { breadcrumb: [{ label: "Moc" }, { label: "Zapotrzebowanie na moc" }] }
  },
  {
    path: "/powerDemanRanges",
    component: PowerDemandRanges,
    meta: { breadcrumb: [{ label: "Moc" }, { label: "Zapotrzebowanie - zakresy" }] }
  },
  {
    path: "/passivePowerCosts",
    component: PassivePowerCosts,
    meta: { breadcrumb: [{ label: "Moc" }, { label: "Koszt energii czynnej" }] }
  },
  {
    path: "/relativeCosts",
    component: RelativeCosts,
    meta: { breadcrumb: [{ label: "Moc" }, { label: "Względny koszt" }] }
  },
  {
    path: "/phaseVoltages",
    component: PhaseVoltages,
    meta: { breadcrumb: [{ label: "Jakość" }, { label: "Napięcia fazowe" }] }
  },
  {
    path: "/higherHarmonics",
    component: HigherHarmonics,
    meta: { breadcrumb: [{ label: "Jakość" }, { label: "Wyższe harmoniczne" }] }
  },
  {
    path: "/powerDips",
    component: PowerDips,
    meta: { breadcrumb: [{ label: "Jakość" }, { label: "Zapady zasilania" }] }
  },
  {
    path: "/lowQualityCounter",
    component: LowQualityCounter,
    meta: { breadcrumb: [{ label: "Jakość" }, { label: "Licznik niskiej jakości" }] }
  },
  {
    path: "/demandIncrease",
    component: DemandIncrease,
    meta: { breadcrumb: [{ label: "Trendy" }, { label: "Wzrost zapotrzebowania" }] }
  },
  {
    path: "/highestUsages",
    component: HighestUsages,
    meta: { breadcrumb: [{ label: "Trendy" }, { label: "Największe zużycia" }] }
  },
  {
    path: "/controls",
    component: Controls,
    meta: { breadcrumb: [{ label: "Sterowanie" }] }
  },
  {
    path: "/clients",
    component: Clients,
    meta: { breadcrumb: [{ label: "Klienci" }] }
  },
  {
    path: "/users",
    component: Users,
    meta: { breadcrumb: [{ label: "Użytkownicy" }] }
  },
  {
    path: "/devices",
    component: Devices,
    meta: { breadcrumb: [{ label: "Urządzenia" }] }
  },
  {
    path: "/serviceLevel",
    component: ServiceLevel,
    meta: { breadcrumb: [{ label: "Service Level" }] }
  },
  {
    path: "/about",
    component: About,
    meta: { breadcrumb: [{ label: "O Programie" }] }
  }
];
