import React from "react";
import { Calendar } from "primereact/calendar";
import "../../../../../../constants/localCalendar";
import { RANGES } from "../../../../../../constants/chartOptions";
import "./DayMonthCustomCalendar.scss";

const CALENDAR_MODE = Object.freeze({ DATE: "date", MONTH: "month" });

export const DayMonthCustomCalendar = ({
  label,
  value,
  calendarId,
  onDateChange,
  calendarIcon,
  showTime,
  minDate,
  maxDate,
  disabledDates,
  className,
  calendarModeRange
}) => {
  return (
    <div className="day-month-calendar-wrapper">
      {label && <span className="day-month-calendar-label">{label}:</span>}
      <Calendar
        value={value}
        id={calendarId}
        dateFormat={calendarModeRange === RANGES.DAY ? "dd/mm/yy" : "mm/yy"}
        onChange={onDateChange}
        icon={calendarIcon}
        showIcon
        showTime={showTime}
        minDate={minDate}
        maxDate={maxDate}
        disabledDates={disabledDates}
        locale="pl"
        className={className}
        view={calendarModeRange === RANGES.DAY ? CALENDAR_MODE.DATE : CALENDAR_MODE.MONTH}
      />
    </div>
  );
};
