import Highcharts from "highcharts";
import { getMixedSingleAxisChartOptions } from "../constants/defaultChartOptions";
import { hoursArray } from "../constants/timeValues";
import { colors } from "../styles/colors";
import { createDataPoints } from "./createDataPoints";

export const getDemandIncreaseOverviewBoxes = (firstDate, secondDate) => [
  {
    title: "Dobowe zapotrzebowanie na moc - kW",
    elements: [
      { number: 9.8, text: firstDate },
      { number: 11.3, text: secondDate }
    ],
    icon: "pi-shopping-cart",
    color: colors.lightBlue,
    iconColor: colors.darkBlue
  },
  {
    title: "Wzrost zapotrzebowania ",
    elements: [
      { number: 1.5, text: "Ilościowy kW" },
      { number: 14, text: "Procentowy %" }
    ],
    icon: "pi-dollar",
    color: colors.purple,
    iconColor: colors.darkPurple
  },
  {
    title: "Dobowe zużycie energii - kWh",
    elements: [
      { number: 8.4, text: firstDate },
      { number: 9.3, text: secondDate }
    ],
    icon: "pi-users",
    color: colors.jade,
    iconColor: colors.darkJade
  },
  {
    title: "Wzrost zużycia",
    elements: [
      { number: 0.9, text: "Ilościowy kWh" },
      { number: 9, text: "Procentowy %" }
    ],
    icon: "pi-comment",
    color: colors.turquoise,
    iconColor: colors.darkTurquoise
  }
];

export const getDemandIncreaseChartData = (dataPoints, percentPoints) => {
  const dataObject = {
    title: "Dobowy wzrost / spadek zapotrzebowania na moc",
    categories: hoursArray,
    xAxisType: "category",
    tooltip: {
      headerFormat: "Godzina: {point.key}<br>"
    },
    multipleAxes: [
      {
        // Primary yAxis
        minRange: 10,
        labels: {
          format: "{value} W",
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        title: {
          text: "Wzrost / spadek zapotrzebowania na moc",
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        }
      },
      {
        // Secondary yAxis
        minRange: 10,
        title: {
          text: "Procentowy wzrost / spadek zapotrzebowania na moc",
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: "{value} %",
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true
      }
    ],
    colors: Highcharts.getOptions().colors,
    series: [
      {
        name: "Ilościowy wzrost / spadek zapotrzebowania na moc",
        type: "column",
        yAxis: 0,
        data: dataPoints,
        unit: " kW"
      },
      {
        name: "Procentowy wzrost / spadek zapotrzebowania na moc",
        type: "spline",
        yAxis: 1,
        data: percentPoints,
        unit: "%"
      }
    ]
  };
  return getMixedSingleAxisChartOptions(dataObject);
};
