import { getVariwideChartOptions } from "../constants/defaultChartOptions";

export const getPowerCostData = (
  distributionChecked,
  powerChecked,
  buildingPowerCostsData,
  buildingDistributionCostsData,
  totalCost,
  period,
  chartHeight
) => {
  const costData = powerChecked
    ? distributionChecked
      ? totalCost
      : buildingPowerCostsData
    : distributionChecked && buildingDistributionCostsData;

  costData.sort((a, b) => b[1] - a[1]);
  const periodOfTimeDescription = period === "month" ? "miesięczny" : "roczny";
  const dataObject = {
    title: `Koszt ${periodOfTimeDescription}/wielkość budynku`,
    subtitle: "Koszt dla 20 najdroższych budynków",
    yAxisLabel: "Koszt na jeden metr kwadratowy",
    yAxisUnit: "zł/m<sup>2</sup>",
    caption: "Szerokość kolumn jest proporcjonalna do powierzchni budynków",
    data: costData,
    format: "zł/m<sup>2</sup>",
    tooltip: {
      value: {
        yValueName: "Cena za metr kwadratowy",
        xValueName: "Powierzchnia budynku",
        totalValueName: "Całkowity koszt"
      },
      unit: {
        yValueName: "zł",
        xValueName: "m<sup>2</sup>",
        totalValueName: "zł"
      }
    }
  };

  return getVariwideChartOptions(dataObject, chartHeight);
};
