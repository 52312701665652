import { AuthStore } from "./AuthStore";
import { CityStore } from "./CityStore";
import { ClientStore } from "./ClientStore";
import { NoteStore } from "./NoteStore";
import { UserOptionsStore } from "./UserOptionsStore";
import { WeatherStore } from "./WeatherStore";

export class RootStore {
  constructor() {
    this.authStore = new AuthStore();
    this.clientStore = new ClientStore();
    this.userOptionsStore = new UserOptionsStore();
    this.weatherStore = new WeatherStore();
    this.cityStore = new CityStore();
    this.noteStore = new NoteStore();
  }
}
