export const colors = Object.freeze({
  grey: "#888888",
  red: "#E82413",
  green: "#2DA71C",
  yellow: "#FFC300",
  blue: "#7CB5EC",
  lightRed: "#F15C80",
  lightGrey: "#DDDDDD",
  lightBlue: "#64B5F6",
  darkBlue: "#1976D2",
  purple: "#7986CB",
  darkPurple: "#303F9F",
  jade: "#4DB6AC",
  darkJade: "#00796B",
  turquoise: "#4DD0E1",
  darkTurquoise: "#0097A7",
  lightYellow: "rgba(252, 255, 197, 0.7)",
  transparentBlack: "rgba(0, 0, 0, 0.75)"
});
