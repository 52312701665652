import React, { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { DataOptions } from "./components/DataOptions/DataOptions";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { RANGES } from "../../../../constants/chartOptions";
import { colors } from "../../../..//styles/colors";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import {
  POWER_PRICE_PER_KW,
  HOURS_IN_MONTH,
  HOURS_QUARTERS_IN_DAY,
  NUMBER_OF_MONTHS_TO_CALCUATE_MEAN,
  NUMBER_OF_DAYS_TO_CALCULATE_MEAN,
  MINIMAL_HOURS_IN_MONTH_DATA_LENGTH,
  MINIMAL_HOUR_QUARTERS_IN_WEEK_DATA_LENGTH
} from "../../../../constants/powerValues";
import { parseDate } from "../../../../utils/helpers";
import { getDayBeginningDateTime } from "../../../../utils/helpers";
import { buttonResolutionFilter } from "../../../../utils/dateAndResolutionFilter";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { Toast } from "primereact/toast";
import { getTelemetryData } from "../../../../api/clientApi";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { getSplineChartOptions } from "../../../../constants/defaultChartOptions";
import moment from "moment";

export const ContractPowerReduction = observer(() => {
  const { selectedDevices } = useClientStore();
  const { contractualCapacity } = selectedDevices;
  const [range, setRange] = useState(RANGES.DAY);
  const [startDateTime, setStartDateTime] = useState(getDayBeginningDateTime());
  const [contractPower, setContractPower] = useState(0);

  const toastRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [telemetryData, setTelemetryData] = useState([
    {
      device_id: 0,
      dateType: "",
      name: "",
      dashStyle: "",
      yAxis: "",
      data: [],
      tooltip: ""
    }
  ]);

  const [meanChatData, setMeanChatData] = useState([]);

  const isImmutableRef = useRef(false);

  const isDay = range === RANGES.DAY;
  const contractPowerPrice = Number(((contractualCapacity * POWER_PRICE_PER_KW) / (isDay ? 30 : 1)).toFixed(2));

  useEffect(() => {
    setIsImmutable(true);
  }, [startDateTime, range, selectedDevices.id]);

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      if (!isNaN(contractualCapacity)) {
        setContractPower(contractualCapacity);
      } else {
        setContractPower(0);
        showToast("Brak informacji o mocy umownej.", "Ostrzeżenie", "warn");
      }
    }
  }, [selectedDevices.id]);

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      getActiveEnergyTelemetryData(selectedDevices.id, range, startDateTime);
    } else {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    }
  }, [selectedDevices.id, range, startDateTime]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const getRequestDates = (range, inputStartDate, getCurrent) => {
    const cleanDate = inputStartDate;
    const [month, day, year] = [cleanDate.getMonth(), cleanDate.getDate(), cleanDate.getFullYear()];
    let startDate = undefined;
    let endDate = undefined;
    let baseDate = undefined;
    if (getCurrent) {
      if (range === RANGES.DAY) {
        baseDate = new Date(year, month, day);
        startDate = new Date(year, month, day);
        endDate = new Date(baseDate.setDate(baseDate.getDate() + 1));
      } else {
        baseDate = new Date(year, month, "01");
        startDate = new Date(year, month, "01");
        endDate = new Date(baseDate.setMonth(baseDate.getMonth() + 1));
      }
    } else {
      if (range === RANGES.DAY) {
        baseDate = new Date(year, month, day);
        endDate = new Date(year, month, day);
        startDate = new Date(baseDate.setDate(baseDate.getDate() - 7));
      } else {
        baseDate = new Date(year, month, "01");
        endDate = new Date(year, month, "01");
        startDate = new Date(baseDate.setMonth(baseDate.getMonth() - 3));
      }
    }
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    return [startDate, endDate];
  };

  const getActiveEnergyTelemetryData = useCallback(
    async (id, range, startDateTime) => {
      setIsLoading(true);

      const { resolution } = buttonResolutionFilter(range);
      const [beginDate, endDate] = getRequestDates(range, startDateTime, true);

      const { data } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(beginDate),
        end: parseDateToApiFormat(endDate),
        resolution,
        avm_active_energy_fundamental_sum: 1
      });

      if (!data[0]?.data || data[0]?.data?.length === 0) {
        showToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości", "Informacja", "info");
        setDefaultEmptyData(data);
        return;
      }

      const [prevBeginDate, prevEndDate] = getRequestDates(range, startDateTime, false);

      const { data: data2 } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(prevBeginDate),
        end: parseDateToApiFormat(prevEndDate),
        resolution,
        avm_active_energy_fundamental_sum: 1
      });

      if (!data2[0]?.data || data2[0]?.data?.length === 0) {
        showToast("Brak danych do wyliczenia średnich mocy", "Informacja", "info");
        setDefaultEmptyData(data);
        return;
      }

      const splitedData = dayMonthDataSpliter(data2[0].data, range);
      if (!isMinimalDataProvided(splitedData, range)) {
        showToast("Za mało danych do wyliczenia średnich mocy", "Informacja", "info");
        setDefaultEmptyData(data);
        return;
      }
      setMeanChatData(getMeanChartData(splitedData, range));
      setTelemetryData([data[0]]);
      setIsLoading(false);
      setIsImmutable(false);
    },
    [showToast]
  );

  const setDefaultEmptyData = data => {
    setMeanChatData([]);
    setTelemetryData([data[0]]);
    setIsLoading(false);
  };

  const getContractPowerReductionChartData2 = useCallback(
    startingPoint => {
      const [beginDate, endDate] = getRequestDates(range, startingPoint, true);
      const [prevBeginDate, prevEndDate] = getRequestDates(range, startingPoint, false);
      const tickValue = isDay
        ? TIME_CONSTANTS_VALUES[[TIME_CONSTANTS.MIN_15]]
        : TIME_CONSTANTS_VALUES[[TIME_CONSTANTS.HOUR_1]];

      const currentEnergyData = getCurrentEnegryChartData(telemetryData, beginDate);
      const meanEnergyData = getMeanEnegryChartData(meanChatData, prevBeginDate, prevEndDate);
      const dataObject = {
        title: "Korzyści z obniżenia mocy umownej",
        yAxisLabel: "Moc",
        yAxisUnit: "W",
        crosshairs: true,
        tooltipShared: true,
        tooltipTimeOnly: true,
        pointInterval: tickValue,
        startTime: parseDate(beginDate),
        zoomType: "x",
        plots: [currentEnergyData, meanEnergyData]
      };

      return getSplineChartOptions(dataObject);
    },
    [telemetryData]
  );

  const getCurrentEnegryChartData = (telemetryData, beginDate) => {
    const dataArray = telemetryData?.[0]?.data.map(element => element[1]);

    const plotData = {
      name: isDay
        ? `Obecna moc - ${moment(beginDate).format("DD/MM/YYYY")}`
        : `Obecna moc - ${moment(beginDate).format("MM/YYYY")}`,
      data: dataArray
    };
    return plotData;
  };

  const getMeanEnegryChartData = (meanChatData, prevBeginDate, prevEndDate) => {
    const plotData = {
      name: isDay
        ? `Średnia moc - ${moment(prevBeginDate).format("DD/MM/YYYY")} - ${moment(prevEndDate).format("DD/MM/YYYY")}`
        : `Średnia moc - ${moment(prevBeginDate).format("MM/YYYY")} - ${moment(prevEndDate).format("MM/YYYY")}`,
      data: meanChatData
    };
    return plotData;
  };

  const isMinimalDataProvided = (sortedData, range) => {
    const minimalAcceptableDataLength =
      range === RANGES.DAY ? MINIMAL_HOUR_QUARTERS_IN_WEEK_DATA_LENGTH : MINIMAL_HOURS_IN_MONTH_DATA_LENGTH;

    const analyzeArrays = sortedData.map(element => element.length >= minimalAcceptableDataLength);

    return analyzeArrays.includes(true);
  };

  const getMeanChartData = (data, range) => {
    const inputData = data;
    const expectedNumberOfValues = range === RANGES.DAY ? HOURS_QUARTERS_IN_DAY : HOURS_IN_MONTH;
    const expectedNumberOfArrays =
      range === RANGES.DAY ? NUMBER_OF_DAYS_TO_CALCULATE_MEAN : NUMBER_OF_MONTHS_TO_CALCUATE_MEAN;
    const meanDataValues = [];

    for (let i = 0; i < expectedNumberOfValues; i++) {
      const tempDayArray = [];
      for (let j = 0; j < expectedNumberOfArrays; j++) {
        const element = inputData?.[j]?.[i];
        const value = element?.[1];
        if (!isNaN(value)) {
          tempDayArray.push(value);
        }
      }
      const sum = tempDayArray.reduce((partialSum, a) => partialSum + a, 0);

      const meanValue = sum / tempDayArray.length;
      meanDataValues.push(meanValue);
    }

    return meanDataValues;
  };

  const dayMonthDataSpliter = (data = [], range) => {
    const copiedData = [...data];
    const splitData = [];
    const mode = range === RANGES.DAY ? "day" : "month";
    while (copiedData.length) {
      const newOriginDataIndex = copiedData.findIndex(
        (element, _, array) => moment(element?.[0]) > moment(array?.[0]?.[0]).endOf(mode)
      );

      if (newOriginDataIndex === -1) {
        splitData.push(copiedData);
        break;
      }

      const selectedOriginElements = copiedData.splice(0, newOriginDataIndex);

      if (newOriginDataIndex === 1) {
        splitData.push([selectedOriginElements]);
      } else {
        splitData.push(selectedOriginElements);
      }
    }

    return splitData;
  };

  const getContractPowerPrice = power => Number(((power * POWER_PRICE_PER_KW) / (isDay ? 30 : 1)).toFixed(2));

  const getContractPower = ({ contractPower, range, date, priceDifferance, isConstant = false } = {}) => {
    const timePoints = range === RANGES.DAY ? HOURS_QUARTERS_IN_DAY : HOURS_IN_MONTH;

    return {
      data: Array(timePoints).fill(contractPower),
      name: isConstant ? "Moc umowna" : "Symulowana moc umowna",
      color: isConstant
        ? colors.lightGrey
        : priceDifferance < 0
        ? colors.green
        : priceDifferance > 0
        ? colors.lightRed
        : colors.grey,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    };
  };

  const chartData = useMemo(
    () => getContractPowerReductionChartData2(startDateTime),
    [telemetryData, startDateTime, getContractPowerReductionChartData2]
  );

  const { priceDifferance, className, price } = useMemo(
    () => getContractPowerPriceDifferance(getContractPowerPrice(contractPower)),
    [contractPower]
  );

  const contractPowerDataLine = useMemo(
    () => getContractPower({ contractPower: contractPower * 1000, range, date: startDateTime, priceDifferance }),
    [contractPower, range, startDateTime, priceDifferance]
  );

  const defaultContractPowerDataLine = useMemo(
    () => getContractPower({ contractPower: contractualCapacity * 1000, range, date: startDateTime, isConstant: true }),
    [range, startDateTime, contractPower]
  );

  const mergedChartData = useMemo(
    () => ({ ...chartData, series: [defaultContractPowerDataLine, contractPowerDataLine, ...chartData.series] }),
    [chartData, contractPowerDataLine, defaultContractPowerDataLine]
  );

  const handleDateChange = useCallback(date => {
    setStartDateTime(date);
    setIsImmutable(true);
  }, []);

  const handleRadioButtonClick = useCallback(buttonId => {
    setRange(buttonId);
    setIsImmutable(true);
  }, []);

  const setIsImmutable = isImmutable => (isImmutableRef.current = !!isImmutable);

  const getIsImmutable = () => isImmutableRef.current;

  function getContractPowerPriceDifferance(price) {
    const priceDifferance = (price - contractPowerPrice).toFixed(2);
    const className = price < contractPowerPrice ? "lower-cost" : price > contractPowerPrice ? "higher-cost" : "";
    return {
      price: price.toFixed(2),
      priceDifferance:
        Number(priceDifferance) > 0 && price !== contractPowerPrice ? `+${priceDifferance}` : priceDifferance,
      className
    };
  }

  return (
    <Grid>
      <Toast ref={toastRef} />
      <DataOptions
        startDateTime={startDateTime}
        setStartDateTime={handleDateChange}
        setContractPower={setContractPower}
        handleRadioButtonClick={handleRadioButtonClick}
        range={range}
        contractPower={contractPower}
        contractPowerPrice={price}
        priceDifferance={priceDifferance}
        priceClassName={className}
      />
      <Card>
        <Chart
          options={mergedChartData}
          deviceId={telemetryData.device_id}
          chartName={"ContractPowerReduction"}
          displayNotesList
          immutable={getIsImmutable()}
          isLoading={isLoading}
        />
      </Card>
    </Grid>
  );
});
