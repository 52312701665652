import { observable, makeObservable, action } from "mobx";
import { INITIAL_VOLTAGE_DEVIATION_THRESHOLD } from "../constants/initialValues";

export class UserOptionsStore {
  voltageDeviationThreshold = INITIAL_VOLTAGE_DEVIATION_THRESHOLD;

  constructor() {
    makeObservable(this, {
      voltageDeviationThreshold: observable,
      setVoltageDeviationThreshold: action.bound
    });
  }

  setVoltageDeviationThreshold = value => {
    this.voltageDeviationThreshold = value;
  };
}
