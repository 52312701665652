import React from "react";
import { OverviewBox } from "../OverviewBox/OverviewBox";
import { Grid } from "../UI/Grid";

import "./OverviewBoxesSection.scss";

export const OverviewBoxesSection = ({ boxes, boxFlexGrow }) => {
  return (
    <div className="p-col-12 overview-boxes-section-container">
      <Grid className="reset-margin">
        {boxes.map((box, index) => (
          <OverviewBox
            key={index}
            flexGrow={boxFlexGrow}
            title={box.title}
            elements={box.elements}
            icon={box.icon}
            color={box.color}
            iconColor={box.iconColor}
          />
        ))}
      </Grid>
    </div>
  );
};
