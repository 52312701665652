import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { observer } from "mobx-react-lite";
import { Card } from "../../../components/UI/Card/Card";
import { Grid } from "../../../components/UI/Grid";
import MapMarker from "./components/MapMarker/MapMarker";
import { getRegionForCoordinates, isNotNullish } from "../../../utils/helpers";
import "./Map.scss";
import { useClientStore } from "../../../common/hooks/storeHooks";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../constants/timeValues";
import { showToast } from "../../../utils/toast";

const defaultProps = {
  center: {
    lat: 50.0748343,
    lng: 19.9332869
  },
  zoom: 16
};

const centerPoland = {
  center: {
    lat: 52.196667,
    lng: 19.356389
  },
  zoom: 6.5
};

export const Map = observer(() => {
  const [zoom, setZoom] = useState(defaultProps.zoom);
  const [center, setCenter] = useState(defaultProps.center);
  const [infoWindowDown, setInfoWindowDown] = useState(false);
  const [infoWindowRight, setInfoWindowRight] = useState(false);
  const [infoWindowLeft, setInfoWindowLeft] = useState(false);
  const [mapPoints, setMapPoints] = useState([]);

  const ref = useRef(null);
  const toastRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const { data, selectedClients, selectAllLocations } = useClientStore();

  const selectedClientsID = useMemo(() => Object.keys(selectedClients.ids, [selectedClients.ids]));

  const sinceDate = new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.WEEK_1]);
  const toDate = new Date();

  useEffect(() => {
    selectAllLocations();
  }, []);

  useEffect(() => {
    if (data.customers) {
      setLocalizationPoints();
      setIsLoading(false);
    }
  }, [data, selectedClients.ids]);

  const setLocalizationPoints = () => {
    const array = [];
    data.customers?.forEach(customer => {
      customer.areas.forEach(area => {
        area.locations.forEach(location => {
          if (
            isNotNullish(location.latitude) &&
            isNotNullish(location.longitude) &&
            selectedClientsID.includes(location.id.toString())
          ) {
            const locationData = {
              ...location,
              showLabel: false,
              type: "house"
            };
            array.push(locationData);
          }
        });
      });
    });

    const size = {
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight
    };

    const coordinates = array.length > 1 ? fitBounds(getRegionForCoordinates(array), size) : null;
    const zoom = coordinates && coordinates.zoom;
    const center = coordinates && coordinates.center;

    if (array.length === 1) {
      setZoom(defaultProps.zoom);
      setCenter({ lat: array[0].latitude, lng: array[0].longitude });
    } else if (array.length === 0 && Object.keys(selectedClients.ids).length > 0) {
      setZoom(centerPoland.zoom);
      setCenter(centerPoland.center);
      displayToast("Brak lokalizacji dla danego urządzenia");
    } else if (array.length === 0) {
      setZoom(centerPoland.zoom);
      setCenter(centerPoland.center);
    } else {
      setZoom(zoom);
      setCenter(center);
    }
    setMapPoints(array);
  };

  const onChildMouseCallback = (key, props) => {
    const array = mapPoints.map(point => {
      if (point.id == key) {
        point.showLabel = !point.showLabel;
      }
      return point;
    });

    setMapPoints(array);
  };

  const distanceToMouse = (markerPos, { x: mouseX, y: mouseY }) => {
    const x = markerPos.x;
    const y = markerPos.y;
    const mouseDistanceFromTopBorder = 250;
    const mouseDistanceFromX_axisBorder = 140;

    if (mouseY < mouseDistanceFromTopBorder) {
      setInfoWindowDown(true);
    } else {
      setInfoWindowDown(false);
    }
    if (mouseX < mouseDistanceFromX_axisBorder) {
      setInfoWindowRight(true);
    } else {
      setInfoWindowRight(false);
    }
    if (ref.current.offsetWidth - mouseX < mouseDistanceFromX_axisBorder) {
      setInfoWindowLeft(true);
    } else {
      setInfoWindowLeft(false);
    }
    return Math.sqrt((x - mouseX) * (x - mouseX) + (y - mouseY) * (y - mouseY));
  };

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  return (
    <>
      <div className="layout-content map">
        <Grid className="grid">
          <Toast ref={toastRef} />
          <Card>
            <h5>Mapa</h5>
            <div ref={ref} className="map-container">
              {isLoading ? (
                <ProgressSpinner />
              ) : (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                  center={center}
                  zoom={zoom}
                  onChildMouseEnter={onChildMouseCallback}
                  onChildMouseLeave={onChildMouseCallback}
                  distanceToMouse={distanceToMouse}
                  options={{ fullscreenControl: false }}
                >
                  {mapPoints &&
                    mapPoints.length > 0 &&
                    mapPoints.map(point => {
                      return (
                        <MapMarker
                          key={point.id}
                          lat={point.latitude}
                          lng={point.longitude}
                          point={point}
                          infoWindowDown={infoWindowDown}
                          infoWindowRight={infoWindowRight}
                          infoWindowLeft={infoWindowLeft}
                          sinceDate={sinceDate}
                          toDate={toDate}
                        />
                      );
                    })}
                </GoogleMapReact>
              )}
            </div>
          </Card>
        </Grid>
      </div>
    </>
  );
});
