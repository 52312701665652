import React, { useCallback, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import Dialog from "../UI/Dialog/Dialog";
import { useNoteContext } from "./NoteWrapper";
import NoteDetails from "./NoteDetails";
import "./NoteDialog.scss";
import { observer } from "mobx-react";
import {
  noteAddErrorText,
  noteAddSendErrorText,
  noteAddSuccessText,
  noteEditErrorText,
  noteEditSendErrorText,
  noteEditSuccessText
} from "../../constants/toastText";
import { isNotNullish } from "../../utils/helpers";

const NoteDialog = observer(() => {
  const {
    isDialogVisible,
    note,
    noteStore,
    noteId,
    userName,
    authorName,
    modificationAuthor,
    chartName,
    date,
    noteDate,
    editDate,
    deviceId,
    chartResolution,
    chartPointData,
    setNote,
    setIsDialogVisible,
    setChartPointData,
    setNoteId,
    setDate,
    setAuthorName,
    setModificationAuthor,
    setNoteDate,
    setEditDate,
    showToast
  } = useNoteContext();

  const { editNote, addNote, removeNote } = noteStore;

  const resetDialog = useCallback(() => {
    setNote("");
    setNoteId(null);
    setChartPointData({});
    setIsDialogVisible(false);
    setDate(Date.now());
    setAuthorName(null);
    setNoteDate(null);
    setModificationAuthor(null);
    setEditDate(null);
  }, [setNote, setIsDialogVisible, setNoteId, setChartPointData, setAuthorName, setModificationAuthor, setNoteDate]);

  const onSave = useCallback(
    async note => {
      if (isNotNullish(noteId)) {
        const { status, message } = await editNote(noteId, note, userName, date, deviceId, chartName);
        if (isNotNullish(status)) {
          status === 200 ? showToast(noteEditSuccessText) : showToast(noteEditErrorText, true);
        } else {
          showToast(noteEditErrorText, true);
        }

        if (message) showToast(noteEditSendErrorText, true);
      } else {
        const { status, message } = await addNote(
          chartPointData,
          note,
          userName,
          chartName,
          date,
          deviceId,
          chartResolution
        );
        if (isNotNullish(status)) {
          status === 201 ? showToast(noteAddSuccessText) : showToast(noteAddErrorText, true);
        } else {
          showToast(noteAddErrorText, true);
        }

        if (message) showToast(noteAddSendErrorText, true);
      }

      resetDialog();
    },
    [chartPointData, resetDialog, noteId, showToast, addNote, editNote]
  );

  const onDelete = useCallback(async () => {
    const { status, message } = await removeNote(noteId, deviceId, chartName);
    status === 200
      ? showToast("Notatka została usunięta pomyśnie")
      : showToast("Notatka nie została usunięta. Spróbuj ponownie później.", true);
    if (message) showToast("Notatka nie została usunięta. Spróbuj ponownie później.", true);
    resetDialog();
  }, [noteId, removeNote, showToast, resetDialog]);
  return (
    <Dialog
      header={noteId !== null ? "Edytuj notatkę" : "Dodaj notatkę"}
      visible={isDialogVisible}
      onHide={resetDialog}
    >
      {authorName && noteDate ? (
        <NoteDetails
          author={authorName}
          modificationAuthor={modificationAuthor}
          creationDate={noteDate}
          modificationDate={editDate}
        />
      ) : null}
      <InputTextarea className="text-area" value={note} onChange={e => setNote(e.target.value)} rows={15} cols={30} />

      <div className="button-container">
        <Button
          className="p-button-danger"
          label={noteId !== null ? "Usuń" : "Anuluj"}
          onClick={() => (noteId !== null ? onDelete() : resetDialog())}
        />
        <Button
          label="Zapisz"
          onClick={() => {
            onSave(note);
          }}
        />
      </div>
    </Dialog>
  );
});

export default NoteDialog;
