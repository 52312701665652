import { useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { useClientStore } from "../../common/hooks/storeHooks";
import { mapDevices } from "../../utils/devices";
import { CustomTreeSelect } from "./CustomTreeSelect";

export const ClientDevicesTreeSelect = observer(() => {
  const { clientsDevicesNodes, selectClientsDevices, selectedClientsDevices, fetchDevicesNodes } = useClientStore();
  useEffect(() => {
    fetchDevicesNodes();
  }, [fetchDevicesNodes]);

  return (
    <CustomTreeSelect
      nodes={clientsDevicesNodes}
      selectedKeys={selectedClientsDevices.ids}
      setSelectedKeys={selectClientsDevices}
      display="chip"
      placeholder="Wybierz urządzenia"
      selectionMode="checkbox"
    />
  );
});
