import { getVariwideChartOptions } from "../constants/defaultChartOptions";

export const getUsagePerFixtureData = () => {
  const dataObject = {
    title: "Zużycie jednostkowe na oprawę",
    subtitle: "Energia Wh/h/oprawę dla najgorszych 20 obwodów",
    yAxisLabel: "Zużycie pojedynczej żarówki",
    yAxisUnit: "Wh/h",
    caption: "Szerokość kolumn jest proporcjonalna do ilości żarówek w instalacji",
    data: usagePerFixtureData,
    format: "Wh",
    tooltip: {
      value: {
        yValueName: "Pojedyncza żarówka",
        xValueName: "Liczba latarni",
        totalValueName: "Cały obwód"
      },
      unit: {
        yValueName: "Wh/h",
        xValueName: "",
        totalValueName: "Wh/h"
      }
    }
  };
  return getVariwideChartOptions(dataObject);
};

const usagePerFixtureData = Object.freeze([
  ["Obwód 1", 50.2, 20],
  ["Obwód 2", 42, 13],
  ["Obwód 3", 39.2, 45],
  ["Obwód 4", 38, 10],
  ["Obwód 5", 35.6, 30],
  ["Obwód 6", 34.3, 80],
  ["Obwód 7", 33.2, 24],
  ["Obwód 8", 33.0, 46],
  ["Obwód 9", 32.7, 50],
  ["Obwód 10", 30.4, 11],
  ["Obwód 11", 27.8, 17],
  ["Obwód 12", 26.7, 67],
  ["Obwód 13", 21.3, 34],
  ["Obwód 14", 14.2, 52],
  ["Obwód 15", 13.7, 30],
  ["Obwód 16", 10.2, 25],
  ["Obwód 17", 8.6, 40],
  ["Obwód 18", 5.5, 35]
]);
