import { makeAutoObservable, runInAction } from "mobx";
import { getWeatherData } from "../api/api";
export class WeatherStore {
  weatherData = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async fetchWeatherData(city, from, to) {
    this.isLoading = true;
    const { data, status } = await getWeatherData({ city, from, to });
    runInAction(() => {
      if (status === 200) {
        this.weatherData = data.data.response;
      }
      this.isLoading = false;
    });
  }
}
