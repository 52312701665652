export const standardTimeRadioButtons = Object.freeze([
  { id: "day", label: "Dzień" },
  { id: "week", label: "Tydzień" },
  { id: "month", label: "Miesiąc" },
  { id: "year", label: "Rok" }
]);

export const trimmedTimeRadioButtons = Object.freeze([
  { id: "month", label: "Miesiąc" },
  { id: "year", label: "Rok" }
]);

export const phasesRadioButtons = Object.freeze([
  { id: "sum", label: "Suma" },
  { id: "phase1", label: "Faza 1" },
  { id: "phase2", label: "Faza 2" },
  { id: "phase3", label: "Faza 3" }
]);

export const NIGHTS_AND_WEEKENDS = Object.freeze({
  ALL: "all",
  NIGHTS: "nights",
  WEEKENDS: "weekends"
});

export const nightsAndWeekendsRadioButtons = Object.freeze([
  { id: NIGHTS_AND_WEEKENDS.ALL, label: "Całodobowy" },
  { id: NIGHTS_AND_WEEKENDS.NIGHTS, label: "Nocny" },
  { id: NIGHTS_AND_WEEKENDS.WEEKENDS, label: "Weekendowy" }
]);

export const energyTypeRadioButtons = Object.freeze([
  { id: "avm_active", label: "Czynna" },
  { id: "avm_reactive", label: "Bierna" }
]);
