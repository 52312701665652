import { getVariwideChartOptions } from "../constants/defaultChartOptions";

export const getHighestUsagesChartData = usagesData => {
  const dataObject = {
    title: "Zużycie energii na m<sup>2</sup>",
    yAxisLabel: "Zużycie energii na m<sup>2</sup>",
    yAxisUnit: "kWh/m<sup>2</sup>",
    caption: "Szerokość kolumn jest proporcjonalna do powierzchni budynków",
    data: usagesData,
    format: "kWh/m<sup>2</sup>",
    tooltip: {
      value: {
        yValueName: "Zużycie na metr kwardatowy",
        xValueName: "Powierzchnia budynku",
        totalValueName: "Całkowite zużycie"
      },
      unit: {
        yValueName: "kWh",
        xValueName: "m<sup>2</sup>",
        totalValueName: "kWh"
      }
    }
  };
  return getVariwideChartOptions(dataObject);
};
