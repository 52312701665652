import moment from "moment";
import { observer } from "mobx-react";
import "./NoteDialog.scss";

const NoteDetails = observer(({ author, modificationAuthor, creationDate, modificationDate }) => {
  const cDate = moment(creationDate).format("DD.MM.YYYY HH:mm");
  const mDate = moment(modificationDate).format("DD.MM.YYYY HH:mm");
  return (
    <div className="note-dialog-information">
      <div>
        <p>Autor notatki: {author}</p>
        <p>Data utworzenia: {cDate}</p>
      </div>
      {modificationAuthor && mDate ? (
        <div>
          <p>Author modyfikacji: {modificationAuthor}</p>
          <p>Data modyfikacji: {mDate}</p>
        </div>
      ) : null}
    </div>
  );
});

export default NoteDetails;
