import { addLocale } from "primereact/api";

addLocale("pl", {
  firstDayOfWeek: 0,
  dayNames: ["poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota", "niedziela"],
  dayNamesShort: ["pon", "wt", "śr", "czw", "pt", "sob", "nie"],
  dayNamesMin: ["P", "W", "Ś", "C", "P", "S", "N"],
  monthNames: [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "październik",
    "listopad",
    "grudzień"
  ],
  monthNamesShort: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
  today: "Dziś",
  clear: "Wyczyść"
});
