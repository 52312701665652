import React, { useMemo, useCallback } from "react";
import { SelectButton } from "primereact/selectbutton";

export const SelectButtons = ({ radioButtons, setButtonChecked, onRadioButtonPress, value }) => {
  const options = useMemo(
    () =>
      radioButtons.map(button => ({
        label: button.label,
        value: button.id
      })),
    [radioButtons]
  );

  const onSelectChange = useCallback(
    e => {
      onRadioButtonPress(e.value);
      setButtonChecked(e.value);
    },
    [onRadioButtonPress, setButtonChecked]
  );

  return <SelectButton options={options} value={value} unselectable={false} onChange={onSelectChange} />;
};
