import axios from "axios";

export const setAxiosClientBaseUrl = websiteUrl => {
  const url = getAxiosClientBaseUrl(websiteUrl);
  apiClient.defaults.baseURL = url;
};

const WEBSITE_URLS = Object.freeze({
  ORANGE: "portal-orange.elektrowniapolska.pl",
  PLAY: "portal-play.elektrowniapolska.pl"
});

const API_URLS = Object.freeze({
  ORANGE: "https://noc.energiapiastow.pl/system/index.php/api/pl/",
  PLAY: "https://noc.elektrowniapolska.pl/system/index.php/api/pl/"
});

const getAxiosClientBaseUrl = websiteUrl => {
  if (websiteUrl.includes(WEBSITE_URLS.ORANGE)) {
    return API_URLS.ORANGE;
  }
  if (websiteUrl.includes(WEBSITE_URLS.PLAY)) {
    return API_URLS.PLAY;
  }
  return "https://noc.elektrowniapolska.pl/system/index.php/api/pl/";
};

export const getAxiosClientWithCustomCredentials = (username, password) =>
  axios.create({
    baseURL: getAxiosClientBaseUrl(document.location.href),
    auth: {
      username,
      password
    }
  });

const apiClient = axios.create({
  baseURL: getAxiosClientBaseUrl(document.location.href)
});

export const setCredentialsToAxiosClient = (username, password) => {
  apiClient.defaults.auth = {
    username,
    password
  };
};

export default apiClient;
