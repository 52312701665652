export const menu = [
  {
    items: [{ label: "Start", icon: "pi pi-fw pi-home", to: "/" }]
  },
  { separator: true },
  {
    label: "Profil Online",
    items: [
      { label: "Koszty", icon: "pi pi-fw pi-wallet", to: "/costs" },
      {
        label: "Profil",
        icon: "pi pi-fw pi-chart-line",
        to: "/profile"
      },
      {
        label: "Profil PV",
        icon: "pi pi-fw pi-chart-line",
        to: "/profilePv"
      },
      {
        label: "Mapa",
        icon: "pi pi-fw pi-map",
        to: "/map"
      }
    ]
  },
  { separator: true },
  {
    label: "Raporty",
    items: [
      {
        label: "Analityka",
        icon: "pi pi-fw pi-users",
        items: [
          { label: "Ostatnia noc", icon: "pi pi-fw pi-users", to: "/lastNight" },
          { label: "Energia czynna i bierna", icon: "pi pi-fw pi-users", to: "/activeAndPassiveEnergy" },
          { label: "Energia czynna - szczegóły", icon: "pi pi-fw pi-users", to: "/activeEnergyDetails" },
          // { label: "Zużycie na oprawę", icon: "pi pi-fw pi-users", to: "/usagePerFixture" },
          { label: "Porównanie profili energii czynnej", icon: "pi pi-fw pi-users", to: "/compareActiveEnergy" }
        ]
      },
      {
        label: "PV",
        icon: "pi pi-fw pi-cog",
        items: [
          { label: "Produkcja PV - lokalizacje", icon: "pi pi-fw pi-users", to: "/pvProductionPerLocalization" },
          { label: "Produkcja PV - dni", icon: "pi pi-fw pi-users", to: "/pvProductionPerDate" },
          { label: "Bilans z OSD", icon: "pi pi-fw pi-users", to: "/osdBalance" },
          { label: "Produkcja PV - szczegóły", icon: "pi pi-fw pi-users", to: "/pvProductionDetails" },
          { label: "Efektywność produkcji PV", icon: "pi pi-fw pi-users", to: "pvProductionEfficiency" },
          { label: "Porównanie profili produkcji PV", icon: "pi pi-fw pi-users", to: "/comparePvProduction" }
        ]
      },
      {
        label: "Taryfy",
        icon: "pi pi-fw pi-ticket",
        items: [
          { label: "Koszty dystrybucji", icon: "pi pi-fw pi-users", to: "/distributionCosts" },
          { label: "Obniżenie mocy umownej", icon: "pi pi-fw pi-users", to: "/contractedPowerReduction" }
        ]
      },
      {
        label: "Moc",
        icon: "pi pi-fw pi-id-card",
        items: [
          { label: "Zapotrzebowanie na moc", icon: "pi pi-fw pi-users", to: "/powerDemand" },
          { label: "Zapotrzebowanie - zakresy", icon: "pi pi-fw pi-users", to: "/powerDemanRanges" },
          { label: "Koszt energii czynnej", icon: "pi pi-fw pi-users", to: "/passivePowerCosts" },
          { label: "Względny koszt", icon: "pi pi-fw pi-users", to: "/relativeCosts" }
        ]
      },
      {
        label: "Jakość",
        icon: "pi pi-fw pi-id-card",
        items: [
          { label: "Napięcia fazowe", icon: "pi pi-fw pi-users", to: "/phaseVoltages" },
          { label: "Wyższe harmoniczne", icon: "pi pi-fw pi-users", to: "/higherHarmonics" },
          { label: "Zapady zasilania", icon: "pi pi-fw pi-users", to: "/powerDips" },
          { label: "Licznik niskiej jakości", icon: "pi pi-fw pi-users", to: "/lowQualityCounter" }
        ]
      },
      {
        label: "Trendy",
        icon: "pi pi-fw pi-id-card",
        items: [
          { label: "Wzrost zapotrzebowania", icon: "pi pi-fw pi-users", to: "/demandIncrease" },
          { label: "Największe zużycia", icon: "pi pi-fw pi-users", to: "/highestUsages" }
        ]
      }
    ]
  },
  { separator: true }
  // {
  //   items: [
  //     { label: "Sterowanie", icon: "pi pi-fw pi-id-card", to: "/controls" },
  //     { label: "Klienci", icon: "pi pi-fw pi-id-card", to: "/clients" },
  //     { label: "Użytkownicy", icon: "pi pi-fw pi-id-card", to: "/users" },
  //     { label: "Urządzenia", icon: "pi pi-fw pi-id-card", to: "/devices" },
  //     { label: "Service Level", icon: "pi pi-fw pi-id-card", to: "/serviceLevel" }
  //   ]
  // },
  // { separator: true },
  // { items: [{ label: "O programie", icon: "pi pi-fw pi-id-card", to: "/about" }] }
];
