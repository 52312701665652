import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../constants/timeValues";
import { chartLangOptions, RESOLUTIONS_NAMES } from "../constants/chartOptions";
import moment from "moment";

export const getRangeStartDate = range => {
  switch (range) {
    case TIME_CONSTANTS.DAY_1:
      return new Date(moment(new Date()).subtract(1, "day").startOf("day"));
    case TIME_CONSTANTS.WEEK_1:
      return new Date(moment(new Date()).subtract(7, "days").startOf("day"));
    case TIME_CONSTANTS.MONTH_1:
      return new Date(moment(new Date()).subtract(1, "month").startOf("day"));
    case TIME_CONSTANTS.YEAR_1:
      return new Date(moment(new Date()).subtract(1, "year").startOf("day"));
    default:
      return new Date(moment(new Date()).subtract(1, "day").startOf("day"));
  }
};

export const parseDate = date => Date.parse(date);

export const getPreviousDate = (timestampOffset = TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]) =>
  new Date(new Date().getTime() - timestampOffset);

export const getBeforePreviousDate = (timestampOffset = TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]) =>
  new Date(new Date().getTime() - 2 * timestampOffset);

export const getDayBeginningDateTime = (date = new Date()) => new Date(date.toDateString());

export const getDayEndDateTime = (date = new Date()) => {
  date.setHours(23, 59, 59, 999);
  return new Date(date).toISOString();
};

export const getMonth = ({ monthsOffset = 0, date = new Date(), beginning = false } = {}) =>
  new Date(
    date.getFullYear(),
    date.getMonth() - monthsOffset,
    beginning ? 1 : date.getDate(),
    beginning ? 0 : date.getHours(),
    beginning ? 0 : date.getMinutes(),
    beginning ? 0 : date.getSeconds(),
    beginning ? 0 : date.getMilliseconds()
  );

export const checkIfDateIsWeekend = date => {
  const weekDayNumber = moment(date).isoWeekday();
  const hour = moment(date).hour();
  if (
    weekDayNumber === 6 ||
    weekDayNumber === 7 ||
    (weekDayNumber === 5 && hour >= 16) ||
    (weekDayNumber === 1 && hour < 8)
  ) {
    return true;
  }
  return false;
};

export const getDaysQuantity = date => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export const getLocalizedDateString = date => {
  const {
    lang: { weekdays, shortMonths }
  } = chartLangOptions;
  return `${weekdays[date.getDay()]}, ${date.getDate()} ${shortMonths[date.getMonth()]}`;
};

export const getDate = date =>
  `${date.getDate()} ${chartLangOptions.lang.shortMonths[date.getMonth()]} ${date.getFullYear()}`;

export const getRegionForCoordinates = points => {
  try {
    // points should be an array of { lat: X, lng: Y }
    let minX, maxX, minY, maxY;
    // init first point
    (({ latitude: lat, longitude: lng }) => {
      minX = lat;
      maxX = lat;
      minY = lng;
      maxY = lng;
    })(points[0]);

    // calculate rect
    points.map(({ latitude: lat, longitude: lng }) => {
      minX = Math.min(minX, lat);
      maxX = Math.max(maxX, lat);
      minY = Math.min(minY, lng);
      maxY = Math.max(maxY, lng);
      return { minX, maxX, minY, maxY };
    });
    return {
      ne: {
        lat: maxX,
        lng: maxY
      },
      sw: {
        lat: minX,
        lng: minY
      }
    };
  } catch (error) {
    console.log(error);
  }
};

export const filterResolutionsOptions = (toHour, sinceHour, resolutionOptions, resolution, setResolution) => {
  const timeDiff = moment(toHour).diff(sinceHour);
  const length = resolutionOptions.length;
  const options = resolutionOptions;

  const setOption = (slice, maxTime) => {
    const option = options.slice(0, length - slice);
    if (!option.some(value => value === RESOLUTIONS_NAMES[resolution])) {
      setResolution(maxTime);
    }

    return option;
  };

  if (timeDiff > 2 * TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]) {
    return options;
  } else if (timeDiff > 2 * TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MIN_15]) {
    return setOption(1, TIME_CONSTANTS.MIN_15);
  } else if (timeDiff > 2 * TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MIN_5]) {
    return setOption(2, 2 * TIME_CONSTANTS.MIN_5);
  } else if (timeDiff > TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MIN_1]) {
    return setOption(3, 2 * TIME_CONSTANTS.MIN_1);
  } else if (timeDiff === TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MIN_1]) {
    return setOption(4, TIME_CONSTANTS.SEC_30);
  }
};

export const parseDateToChartFormat = date => {
  const parsedDate = new Date(date);
  const [month, day, hours, minutes] = [
    parsedDate.toLocaleString("pl-PL", { month: "short" }),
    parsedDate.getDate(),
    parsedDate.getHours(),
    parsedDate.getMinutes()
  ];

  return `${day}. ${month.charAt(0).toUpperCase() + month.slice(1)} ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

export const isNotNullish = value => value !== undefined && value !== null;

export const isObject = value => typeof value === "object" && value !== null && !Array.isArray(value);

export const handleNaN = value => (isNaN(value) ? 0 : value);

export const calculateSumDataArray = array => {
  const sumOfArray = array.reduce((prev, curr) => {
    if (typeof prev === "number") {
      return prev + curr[1];
    } else {
      return prev[1] + curr[1];
    }
  });
  return sumOfArray;
};
