import Icon01d from "./01d.svg";
import Icon01n from "./01n.svg";
import Icon02n from "./02n.svg";
import Icon02d from "./02d.svg";
import Icon03d from "./03d.svg";
import Icon03n from "./03n.svg";
import Icon04d from "./04.svg";
import Icon09d from "./09.svg";
import Icon10d from "./10.svg";
import Icon11d from "./11.svg";
import Icon13d from "./13.svg";
import Icon50d from "./50.svg";

const icons = {
  Icon01d,
  Icon02d,
  Icon03d,
  Icon01n,
  Icon02n,
  Icon03n,
  Icon04d,
  Icon04n: Icon04d,
  Icon09d,
  Icon09n: Icon09d,
  Icon10d,
  Icon10n: Icon10d,
  Icon11d,
  Icon11n: Icon11d,
  Icon13d,
  Icon13n: Icon13d,
  Icon50d,
  Icon50n: Icon50d
};

export default icons;
