import { getSplineChartOptions } from "../constants/defaultChartOptions";
import { createDataPoints } from "../mocks/createDataPoints";

export const localizations = ["ul. Sienna 4", "ul. Krupnicza 11", "ul. Szewska 23", "ul. Wielopole 8"];
const numberOfPoints = 96;
const generateData = () => createDataPoints(numberOfPoints).map(point => point / 10);

export const getPvProductionData = (getData, withWeather) => {
  const { dates, withRatedPower, names } = getData?.() ?? {};
  const [firstName, secondName] = names ?? [];
  const localizationsPoints = [
    {
      name: firstName,
      data: generateData()
    },
    {
      name: secondName,
      data: generateData()
    }
  ];

  const arrayMax = Math.max.apply(Math, [...localizationsPoints[0].data, ...localizationsPoints[1].data]);

  const weatherPoints = withWeather
    ? [
        {
          name: `${firstName} pogoda`,
          weather: getData().weathers[0],
          data: localizationsPoints[0].data.map(() => arrayMax + 1)
        },
        {
          name: `${secondName} pogoda`,
          weather: getData().weathers[1],
          data: localizationsPoints[0].data.map(() => arrayMax + 2)
        }
      ]
    : [];

  const dataObject = {
    title: "Profil produkcji PV",
    yAxisLabel: "Wyprodukowana energia",
    yAxisUnit: "Wh",
    withWeather: withWeather,
    withoutHeader: dates.length > 1,
    crosshairs: true,
    tooltipShared: true,
    pointInterval: 900000, // 15 minutes,
    startTime: Date.parse(dates[0] ?? new Date()),
    zoomType: "x",
    plots: localizationsPoints,
    weatherData: weatherPoints
  };
  withRatedPower &&
    dataObject.plots.push({
      name: "Moc znamionowa",
      data: Array(98).fill(12)
    });
  return getSplineChartOptions(dataObject);
};
