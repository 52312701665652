export const TIME_CONSTANTS = Object.freeze({
  SEC_10: "10_seconds",
  MIN_1: "minute",
  MIN_15: "hour_quarter",
  HOUR_1: "hour",
  HOUR_4: "4_hours",
  DAY_1: "day",
  WEEK_1: "week",
  MONTH_1: "month",
  MONTH_3: "year_quarter",
  YEAR_1: "year"
});

export const CURRENT_YER = "2021";

export const TIME_CONSTANTS_VALUES = Object.freeze({
  [TIME_CONSTANTS.SEC_10]: 10000,
  [TIME_CONSTANTS.MIN_1]: 60000,
  [TIME_CONSTANTS.MIN_15]: 900000,
  [TIME_CONSTANTS.HOUR_1]: 3600000,
  [TIME_CONSTANTS.HOUR_4]: 14400000,
  [TIME_CONSTANTS.DAY_1]: 86400000,
  [TIME_CONSTANTS.WEEK_1]: 604800000,
  [TIME_CONSTANTS.MONTH_1]: 2592000000, // 30 days
  [TIME_CONSTANTS.MONTH_3]: 7776000000, // 90 days
  [TIME_CONSTANTS.YEAR_1]: 31536000000 // 365 days
});

export const hoursArray = Object.freeze([
  "0:00",
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00"
]);
