import React, { useMemo, useCallback } from "react";
import { Card } from "../../../../../../components/UI/Card/Card";
import { ContractPowerChange } from "../ContractPowerChange/ContractPowerChange";
import { SelectButtons } from "../../../../../../components/SelectButtons/SelectButtons";
import { standardTimeRadioButtons } from "../../../../../../constants/radioButtons";
import { RANGES } from "../../../../../../constants/chartOptions";
import { DayMonthCustomCalendar } from "./DayMonthCustomCalendar";

import "./DataOptions.scss";

export const DataOptions = ({
  startDateTime,
  setStartDateTime,
  setContractPower,
  handleRadioButtonClick,
  range,
  contractPower,
  contractPowerPrice,
  priceDifferance,
  priceClassName,
  calendarMode
}) => {
  const buttons = useMemo(
    () => standardTimeRadioButtons.filter(({ id }) => Object.values(RANGES).some(value => value === id)),
    []
  );

  const setButtonChecked = useCallback(() => range, [range]);

  return (
    <div className="data-options-container">
      <Card className="data-card-container">
        <div className="data-option">
          <div className="data-option">
            <div className="option-item">
              <DayMonthCustomCalendar
                label={range === RANGES.DAY ? "Wybierz datę" : "Wybierz miesiąc"}
                value={startDateTime}
                onDateChange={e => setStartDateTime(e.target.value)}
                calendarIcon="pi pi-calendar-plus"
                maxDate={new Date()}
                calendarModeRange={range}
              />
            </div>
            <div className="option-item">
              <span className="label">Wybierz zakres: </span>
              <SelectButtons
                radioButtons={buttons}
                setButtonChecked={setButtonChecked}
                onRadioButtonPress={handleRadioButtonClick}
                value={range}
              />
            </div>
          </div>
        </div>
      </Card>

      <div>
        <Card className="contract-power-container">
          <ContractPowerChange
            priceClassName={priceClassName}
            contractPowerPrice={contractPowerPrice}
            priceDifferance={priceDifferance}
            contractPower={contractPower}
            increment={() => setContractPower(current => current + 1)}
            decrement={() => setContractPower(current => (current > 0 ? current - 1 : current))}
          />
        </Card>
      </div>
    </div>
  );
};
