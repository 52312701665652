import { getCircleBarChartOptions } from "../constants/defaultChartOptions";
import { colors } from "../styles/colors";
import { BUILDING_TYPES_CATEGORIES } from "../utils/buildingTypesUtils";

export const getPhaseVoltagesChartData = percentagesData => {
  const dataObject = {
    colors: [colors.yellow, colors.green, colors.red],
    title: "Wachania napięcia fazowego",
    caption: "Procent czasu gdy napięcie fazowe jest za niskie / w normie / za wysokie",
    yAxisUnit: "% czasu",
    yAxisMax: 100,
    categories: BUILDING_TYPES_CATEGORIES,
    data: [
      {
        name: "Za niskie",
        data: percentagesData.belowLowerThresholdPercentage
      },
      {
        name: "W normie",
        data: percentagesData.betweenThresholdsPercentage
      },
      {
        name: "Za wysokie",
        data: percentagesData.aboveHigherThresholdPercentage
      }
    ]
  };
  return getCircleBarChartOptions(dataObject);
};
