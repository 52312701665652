import React, { useState } from "react";

export const CustomClock = () => {
  const [currentDate, setCurrentDate] = useState("");

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
    const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
    const hour = today.getHours();
    const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
    const seconds = today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds();
    setCurrentDate(`${day}/${month}/${year}   ${hour}:${minutes}:${seconds}`);
  };

  const oneSecond = 1000;

  React.useEffect(() => {
    getCurrentDate();
    const dateInterval = setInterval(() => {
      getCurrentDate();
    }, oneSecond);
    return function clearDate() {
      clearInterval(dateInterval);
    };
  }, []);

  return <span className="topbar-text">{currentDate}</span>;
};
