import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OverviewBoxesSection } from "../../../../components/OverviewBoxesSection/OverviewBoxesSection";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { getDistributionCostsOverviewBoxes, getDistributionCostsChartData } from "../../../../mocks/distributionCosts";
import { getAllOsdTarrifs } from "../../../../api/api";
import { showToast } from "../../../../utils/toast";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { observer } from "mobx-react";
import { ENERGY_TARIFF } from "../../../../mocks/energyTariff";
import { TOAST_MESSAGE_TYPE } from "../../../../constants/toastText";
import { Toast } from "primereact/toast";
import { getTelemetryData } from "../../../../api/clientApi";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { calculateDistributionCost, getSummaryCost } from "../../../../mocks/costs";
import moment from "moment";

export const DistributionCosts = observer(() => {
  const toastRef = useRef(null);
  const {
    selectedDevices: { id: deviceId, branchId, osdId, contractualCapacity }
  } = useClientStore();

  const [tariffs, setTariffs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeEnergyTotal, setActiveEnergyTotal] = useState([]);

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  useEffect(() => {
    if (!isNaN(deviceId)) {
      fetchActiveEnergyTotal(deviceId);
      if (branchId === undefined || osdId === null) {
        displayToast(
          "Brak szczegółowych informacji o OSD! Elementy: Kod Taryfy, Koszty OSD zostały wyłączona",
          TOAST_MESSAGE_TYPE.WARN
        );
        setTariffs([]);
      } else {
        fetchOsdTariffs("2021", branchId, osdId);
      }
    }
  }, [deviceId]);

  useEffect(() => {
    if (isNaN(deviceId)) {
      displayToast("Nie wybrano pliszki", TOAST_MESSAGE_TYPE.WARN);
    }
  }, []);

  const fetchActiveEnergyTotal = useCallback(async id => {
    setIsLoading(true);

    const { data } = await getTelemetryData({
      devices: id,
      begin: parseDateToApiFormat(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MONTH_1])),
      end: parseDateToApiFormat(new Date()),
      resolution: TIME_CONSTANTS.HOUR_1,
      avm_active_energy_total_sum: 1
    });

    if (!data || data?.some(dataPart => !dataPart.data?.length)) {
      displayToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości");
      setActiveEnergyTotal([]);
      setIsLoading(false);
      return;
    }

    setActiveEnergyTotal(data);
    setIsLoading(false);
  }, []);

  const fetchOsdTariffs = useCallback(async (year, branchId) => {
    try {
      const { data } = await getAllOsdTarrifs({ year, branchId });

      setTariffs(data.response);
    } catch (err) {
      displayToast(err, "err");
    }
  }, []);

  const currentTariff = useMemo(() => tariffs.find(tariff => Number(tariff.osd_id) === osdId), [tariffs]);

  const categories = useMemo(() => tariffs.map(tariff => tariff.rate), [tariffs]);

  const distributionCosts = useMemo(
    () =>
      tariffs.map(tariff =>
        calculateDistributionCost(activeEnergyTotal, tariff, TIME_CONSTANTS.HOUR_1, contractualCapacity)
      ),
    [activeEnergyTotal, contractualCapacity]
  );

  const summaryDistributionCost = useMemo(() => distributionCosts.map(getSummaryCost), [distributionCosts]);

  const chartData = useMemo(
    () => getDistributionCostsChartData(categories, summaryDistributionCost),
    [categories, summaryDistributionCost]
  );

  const activeEnergyData = useMemo(() => activeEnergyTotal?.[0]?.data, [activeEnergyTotal]);

  const dayTimeUsage = useMemo(
    () =>
      activeEnergyData?.filter(data => {
        const hour = moment(data[0]).hour();
        return hour >= 8 && hour < 16;
      }),
    [activeEnergyData]
  );

  const nightTimeUsage = useMemo(
    () =>
      activeEnergyData?.filter(data => {
        const hour = moment(data[0]).hour();
        return hour >= 16 || hour < 8;
      }),
    [activeEnergyData]
  );

  const totalDayTimeUsage = useMemo(
    () => (dayTimeUsage?.reduce((prev, current) => prev + current[1], 0) / 1000).toFixed(2),
    [dayTimeUsage]
  );

  const totalNightTimeUsage = useMemo(
    () => (nightTimeUsage?.reduce((prev, current) => prev + current[1], 0) / 1000).toFixed(2),
    [nightTimeUsage]
  );

  const dayTimeDistributionCosts = useMemo(
    () =>
      calculateDistributionCost([{ data: dayTimeUsage }], currentTariff, TIME_CONSTANTS.HOUR_1, contractualCapacity),
    [dayTimeUsage, currentTariff, contractualCapacity]
  );

  const nightTimeDistributionCosts = useMemo(
    () =>
      calculateDistributionCost([{ data: nightTimeUsage }], currentTariff, TIME_CONSTANTS.HOUR_1, contractualCapacity),
    [nightTimeUsage, currentTariff, contractualCapacity]
  );

  const totalDayTimeCost = useMemo(() => getSummaryCost(dayTimeDistributionCosts), [dayTimeDistributionCosts]).toFixed(
    2
  );

  const totalNightTimeCost = useMemo(
    () => getSummaryCost(nightTimeDistributionCosts),
    [nightTimeDistributionCosts]
  ).toFixed(2);

  return (
    <Grid>
      <Toast ref={toastRef} />
      <OverviewBoxesSection
        boxes={getDistributionCostsOverviewBoxes(
          ENERGY_TARIFF[osdId],
          totalDayTimeUsage,
          totalNightTimeUsage,
          totalDayTimeCost,
          totalNightTimeCost
        )}
        boxFlexGrow={4}
      />
      <Card>
        <Chart
          options={chartData}
          masterDetailChartData={null}
          isLoading={isLoading}
          chartName={"DistributionCosts"}
          deviceId={deviceId}
          displayNotesList
        />
      </Card>
    </Grid>
  );
});
