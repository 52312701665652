import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { phasesRadioButtons, standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { CurrentContractedPowerSection } from "./components/CurrentContractedPowerSection";
import { Toast } from "primereact/toast";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { getVerticalBarChartOptions } from "../../../../constants/defaultChartOptions";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { buttonResolutionFilter, buttonDateFilter } from "../../../../utils/dateAndResolutionFilter";
import "./PowerDemandRanges.scss";

export const PowerDemandRanges = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedDevices } = useClientStore();
  const [isLoading, setIsLoading] = useState(false);
  const [timeButtonChecked, setTimeButtonChecked] = useState(standardTimeRadioButtons[0].id);
  const [phaseButtonChecked, setPhaseButtonChecked] = useState(phasesRadioButtons[0].id);
  const [customChartData, setCustomChartData] = useState({ dataPercentage: [], barsNames: [] });
  const [telemetryData, setTelemetryData] = useState({
    device_id: 0,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });
  const [currentContractedPower, setCurrentContractedPower] = useState("--");

  const energyPhaseApiParameterNameAddition = Object.freeze({
    sum: "_sum",
    phase1: "_1",
    phase2: "_2",
    phase3: "_3"
  });

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      getPowerDemandTelemetryData(selectedDevices.id, timeButtonChecked, phaseButtonChecked);
    } else {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    }
  }, [selectedDevices.id, timeButtonChecked, phaseButtonChecked]);

  useEffect(() => {
    analyseData(telemetryData.data);
  }, [telemetryData]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const getRangesArray = maxResult => {
    const customRanges = [];
    const barsNumber = 10;
    const maxResultRoundedUp = Math.ceil(maxResult / 100) * 100;
    const chartStep = maxResultRoundedUp / barsNumber;

    for (let index = 0; index < barsNumber + 1; index++) {
      const calcRange = chartStep * index;
      if (index === 0) {
        calcRange = -Infinity;
      }
      customRanges.push(calcRange);
    }

    return customRanges;
  };

  const createBarsNames = rangesArray => {
    const customBarNames = [];

    rangesArray.forEach((range, index, ranges) => {
      const currentRangeValue = range === -Infinity ? 0 : range;
      const nextRangeValue = ranges[index + 1];
      if (nextRangeValue !== undefined) {
        customBarNames.push(`${currentRangeValue} kW - ${nextRangeValue} kW`);
      }
    });

    return customBarNames;
  };

  const checkRecordRange = (record, rangesArray) => {
    return rangesArray.findIndex((range, index, array) => {
      const currentRangeValue = range;
      const nextRangeValue = array[index + 1];
      if (record > currentRangeValue && record <= nextRangeValue) {
        return true;
      }
    });
  };

  const analyseData = telemetryData => {
    const energyResults = [];
    const sortedRecords = [[], [], [], [], [], [], [], [], [], []];
    const recordsPercentage = [];

    if (telemetryData.length === 0) {
      setCustomChartData({ dataPercentage: recordsPercentage, barsNames: finalBarsNames });
      return;
    }

    telemetryData.forEach(data => {
      const record = data[1];
      energyResults.push(record);
    });

    const finalBarsRanges = getRangesArray(Math.max(...energyResults));
    const finalBarsNames = createBarsNames(finalBarsRanges);

    energyResults.forEach(result => {
      const rangeIndex = checkRecordRange(result, finalBarsRanges);
      sortedRecords[rangeIndex].push(result);
    });

    sortedRecords.forEach(rangeArray => {
      const totalResultsNumber = energyResults.length;
      const totalResultsInRanges = rangeArray.length;
      const percent = Number(((totalResultsInRanges / totalResultsNumber) * 100).toFixed(2));
      recordsPercentage.push(percent);
    });

    setCustomChartData({ dataPercentage: recordsPercentage, barsNames: finalBarsNames });
  };

  const getPowerDemandTelemetryData = useCallback(
    async (id, timeButtonChecked, phaseButtonChecked) => {
      setIsLoading(true);

      const { resolution } = buttonResolutionFilter(timeButtonChecked);

      const requestData = {
        devices: id,
        begin: parseDateToApiFormat(buttonDateFilter(timeButtonChecked)),
        end: parseDateToApiFormat(),
        resolution
      };

      requestData[`avm_active_energy_fundamental${energyPhaseApiParameterNameAddition[phaseButtonChecked]}`] = 1;

      const { data } = await getTelemetryData(requestData);

      if (!data[0]?.data || data[0]?.data?.length === 0) {
        showToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości", "Informacja", "info");
        setCustomChartData([]);
        setIsLoading(false);
        return;
      }

      setTelemetryData(data[0]);
      setCurrentContractedPower(selectedDevices.contractualCapacity);
      setIsLoading(false);
    },
    [showToast, selectedDevices]
  );

  const getPowerDemandsRangesChartData = useCallback(() => {
    const dataObject = {
      title: "Zapotrzebowanie na poszczególne zakresy mocy",
      yAxisLabel: "Udział procentowy",
      yAxisUnit: "%",
      pointLabelFormat: "Udział z zużyciu: <b>{point.y} %</b><br/>",
      categories: customChartData.barsNames,
      data: customChartData.dataPercentage
    };

    return getVerticalBarChartOptions(dataObject);
  }, [customChartData]);

  const chartData = useMemo(() => getPowerDemandsRangesChartData(), [getPowerDemandsRangesChartData]);

  return (
    <Grid>
      <div className="power-demand-ranges-top-bar-container">
        <Toast ref={toastRef} />

        <RadioButtonsSection
          flexGrow={4}
          setButtonChecked={setTimeButtonChecked}
          radioButtons={standardTimeRadioButtons}
          value={timeButtonChecked}
          className="radio-button-section-container"
        />
        <RadioButtonsSection
          title={"Faza"}
          flexGrow={4}
          setButtonChecked={setPhaseButtonChecked}
          radioButtons={phasesRadioButtons}
          value={phaseButtonChecked}
          className="radio-button-section-container"
        />

        <CurrentContractedPowerSection contractedPower={currentContractedPower} />
      </div>

      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
