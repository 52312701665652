import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useAuthStore } from "../common/hooks/storeHooks";

import "primeflex/primeflex.css";
import "./Login.scss";

import background from "../assets/images/fotolia_56759072_m.jpg";
import logo from "../assets/images/bg-logo.svg";

export const Login = () => {
  const [loginData, setLoginData] = React.useState({
    username: "",
    password: ""
  });

  const [wrongPasswordMessage, setWrongPasswordMessage] = React.useState(true);

  const { logIn } = useAuthStore();

  const handleLogin = async () => {
    const success = await logIn(loginData.username, loginData.password);
    if (!success) {
      setWrongPasswordMessage("Podany login lub hasło są nieprawidłowe");
    }
  };

  return (
    <div className="login-body">
      <div className="p-d-flex p-flex-column p-jc-center login-wrapper">
        <div className="login-images-container">
          <div className="p-d-flex p-flex-column p-align-center p-justify-center login-background-img">
            <img className="image" src={background} alt="background" />
          </div>
          <div className="login-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="p-d-flex p-flex-column p-jc-center p-xl-3 login-container">
          <h2 className="p-mb-4">{"Zaloguj się"}</h2>
          <div className="p-fluid">
            <InputText
              placeholder="Użytkownik"
              onChange={e => setLoginData({ ...loginData, username: e.target.value })}
              className="p-mb-3"
            />
            <Password
              placeholder="Hasło"
              onChange={e => setLoginData({ ...loginData, password: e.target.value })}
              feedback={false}
              className="p-mb-3"
            />
            <Button onClick={handleLogin} label="Zaloguj"></Button>
            <div className="wrong-password-message-container">
              <span>{wrongPasswordMessage}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
