import { Card } from "../UI/Card/Card";
import { CustomCalendar } from "../CustomCalendar/CustomCalendar";

import "./TwoDatesSelection.scss";

export const TwoDatesSelection = ({ firstDate, secondDate, setFirstDate, setSecondDate }) => (
  <>
    <Card>
      <div className="dates-container">
        <span className="header-label">Porównaj dni:</span>
        <div>
          <CustomCalendar
            label="Dzień 1"
            value={firstDate}
            onDateChange={e => setFirstDate(e.target.value)}
            calendarIcon="pi pi-calendar-minus"
            disabledDates={[secondDate]}
            maxDate={new Date()}
          />
        </div>
        <div>
          <CustomCalendar
            label="Dzień 2"
            value={secondDate}
            onDateChange={e => setSecondDate(e.target.value)}
            calendarIcon="pi pi-calendar-minus"
            disabledDates={[firstDate]}
            maxDate={new Date()}
          />
        </div>
      </div>
    </Card>
  </>
);
