import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import { Card } from "../../components/UI/Card/Card";
import { Grid } from "../../components/UI/Grid";
import { Chart } from "../../components/UI/Chart/Chart";
import { DateFilterSection } from "../../components/DateFilterSection/DateFilterSection";
import { RadioButtonsSection } from "../../components/RadioButtonsSection/RadioButtonsSection";
import { OverviewBox } from "../../components/OverviewBox/OverviewBox";
import { colors } from "../../styles/colors";

import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../constants/timeValues";
import { TOAST_MESSAGE_TYPE } from "../../constants/toastText";
import { CHART_TYPES, RESOLUTIONS_NUMBERS } from "../../constants/chartOptions";
import { getSplineChartOptions, getColumnStackedChartOptions } from "../../constants/defaultChartOptions";
import { useClientStore } from "../../common/hooks/storeHooks";
import { getTelemetryData } from "../../api/clientApi";
import { getOsd } from "../../api/api";
import { calculateNumberOfPoints } from "../../utils/chartUtils";
import { showToast } from "../../utils/toast";
import { parseDateToApiFormat } from "../../utils/apiUtils";
import { parseDate, parseDateToChartFormat } from "../../utils/helpers";
import { calculateSum } from "../../utils/costs";
import { calculateActiveEnergyTotalCost, calculateDistributionCost, getSummaryCost } from "../../mocks/costs";

const radioOptions = [
  { label: "Liniowy", id: CHART_TYPES.SPLINE },
  { label: "Słupkowy", id: CHART_TYPES.COLUMN }
];

const dateFilterSectionResolutionOpions = [
  "1 godzina",
  "4 godziny",
  "1 dzień",
  "1 tydzień",
  "1 miesiąc",
  "3 miesiące",
  "1 rok"
];

const initialCardElements = [
  { number: "0 PLN", text: "Koszty energii czynnej całkowitej" },
  { number: "0 PLN", text: "Koszt dystrybucji" },
  { number: "0 PLN", text: "Suma" }
];

const initialActiveEnergyTotal = [{ data: [] }];

export const Costs = observer(() => {
  const toastRef = useRef(null);
  const { selectedDevices } = useClientStore();

  const { id: deviceId, branchId, osdId, contractualCapacity } = selectedDevices;

  const [sinceDate, setSinceDate] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]));
  const [toDate, setToDate] = useState(new Date());
  const [resolution, setResolution] = useState(TIME_CONSTANTS.HOUR_1);
  const [chartType, setChartType] = useState(CHART_TYPES.SPLINE);
  const [numberOfPoints, setNumberOfPoints] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activeEnergyTotal, setActiveEnergyTotal] = useState(initialActiveEnergyTotal);
  const [osdInfo, setOsdInfo] = useState();
  const [cardElements, setCardElements] = useState(initialCardElements);
  const [isCalcProgress, setIsCalcProgress] = useState(true);
  const [isForceUpdateCosts, setIsForceUpdateCosts] = useState(false);

  useEffect(() => {
    calculateNumberOfPoints(toDate, sinceDate, resolution, selectedDevices, displayToast, setNumberOfPoints);
    if (numberOfPoints) {
      if (branchId === undefined || osdId === null) {
        setOsdInfo(undefined);
        setIsForceUpdateCosts(true);
        forceUpdateEmptyCosts();
        displayToast(
          "Brak szczegółowych informacji o pliszce! Zakładka Koszty Energii została wyłączona",
          TOAST_MESSAGE_TYPE.WARN
        );
      } else {
        setIsForceUpdateCosts(false);
        fetchOsdInfo("2021", branchId, osdId);
      }
      fetchActiveEnergyTotal(deviceId);
    }
  }, [deviceId, numberOfPoints]);

  useEffect(() => {
    if (!isForceUpdateCosts) {
      const summaryDistributionCost = getSummaryCost(
        calculateDistributionCost(activeEnergyTotal, osdInfo?.data?.response?.[0], resolution, contractualCapacity)
      );
      const summaryActiveEnergyCost = getSummaryCost(calculateActiveEnergyTotalCost(activeEnergyTotal, resolution));
      const summaryCost = getSummaryCost(calculateSum(activeEnergyTotalCost, distributionCost));
      setCardElements([
        { number: `${summaryActiveEnergyCost} PLN`, text: "Koszty energii czynnej całkowitej" },
        { number: `${summaryDistributionCost} PLN`, text: "Koszt dystrybucji" },
        { number: `${summaryCost} PLN`, text: "Suma" }
      ]);
    }
  }, [activeEnergyTotal, osdInfo, resolution, contractualCapacity]);

  const fetchActiveEnergyTotal = useCallback(
    async id => {
      setIsLoading(true);

      const { data } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(sinceDate),
        end: parseDateToApiFormat(toDate),
        resolution,
        avm_active_energy_total_sum: 1
      });

      if (!data || data?.some(dataPart => !dataPart.data?.length) || data.length === 0) {
        displayToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości");
        setActiveEnergyTotal(initialActiveEnergyTotal);
        setIsLoading(false);
        return;
      }
      setActiveEnergyTotal(data);
      setIsLoading(false);
    },
    [resolution, sinceDate, toDate]
  );

  const fetchOsdInfo = useCallback(async (year, branchId, rateId) => {
    try {
      const osd = await getOsd({ year, branchId, rateId });
      setOsdInfo(osd);
    } catch (error) {
      displayToast("Brak informacji o osd!", TOAST_MESSAGE_TYPE.WARN);
    }
  }, []);

  const getCostsChartData = useCallback(
    (activeEnergyTotalCost, distributionCost, sum) => {
      if (chartType === CHART_TYPES.SPLINE) {
        const dataObject = {
          title: "Koszty",
          yAxisLabel: "Koszty PLN",
          yAxisUnit: "PLN",
          crosshairs: true,
          tooltipShared: true,
          pointInterval: RESOLUTIONS_NUMBERS[resolution],
          startTime: parseDate(sinceDate),
          plots: [
            { name: "Koszty Energi Czynnej Całkowitej", data: activeEnergyTotalCost },
            { name: "Koszty Dystrybucji", data: distributionCost },
            { name: "Suma", data: sum }
          ],
          height: 600,
          yAxisOpposite: true,
          navigatorHeight: 40
        };
        return getSplineChartOptions(dataObject);
      }

      if (chartType === CHART_TYPES.COLUMN) {
        const dataObject = {
          title: "Koszty",
          caption: "",
          yAxisUnit: "PLN",
          categories: [...activeEnergyTotalCost.map(elem => parseDateToChartFormat(elem[0]))],
          plots: [
            { name: "Koszty Energi Aktywnej Całkowitej", data: activeEnergyTotalCost.map(elem => elem[1]) },
            { name: "Koszty Dystrybucji", data: distributionCost.map(elem => elem[1]) }
          ]
        };

        return getColumnStackedChartOptions(dataObject);
      }
    },
    [sinceDate, resolution, chartType]
  );

  const clearDateFilters = () => {
    setSinceDate();
    setToDate();
  };

  const forceUpdateEmptyCosts = () => {
    setCardElements([
      { number: `-- PLN`, text: "Koszty energii czynnej całkowitej" },
      { number: `-- PLN`, text: "Koszt dystrybucji" },
      { number: `-- PLN`, text: "Suma" }
    ]);
  };
  const onConfirm = useCallback(() => {
    calculateNumberOfPoints(toDate, sinceDate, resolution, selectedDevices, displayToast, setNumberOfPoints);
  }, [toDate, sinceDate, resolution, showToast, setNumberOfPoints, selectedDevices]);

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  const distributionCost = useMemo(
    () => calculateDistributionCost(activeEnergyTotal, osdInfo?.data?.response?.[0], resolution, contractualCapacity),
    [activeEnergyTotal, osdInfo, contractualCapacity]
  );

  const activeEnergyTotalCost = useMemo(
    () => calculateActiveEnergyTotalCost(activeEnergyTotal, resolution),
    [activeEnergyTotal]
  );

  const sum = useMemo(
    () => calculateSum(activeEnergyTotalCost, distributionCost),
    [activeEnergyTotalCost, distributionCost]
  );

  const chartData = useMemo(
    () => getCostsChartData(activeEnergyTotalCost, distributionCost, sum),
    [activeEnergyTotalCost, distributionCost, sum, chartType]
  );

  return (
    <Grid>
      <Toast ref={toastRef} />
      <DateFilterSection
        sinceDate={sinceDate}
        setSinceDate={setSinceDate}
        toDate={toDate}
        setToDate={setToDate}
        clearDateFilters={clearDateFilters}
        resolution={resolution}
        setResolution={setResolution}
        resolutionOptions={dateFilterSectionResolutionOpions}
        onConfirm={onConfirm}
        flexGrow={12}
      />
      <OverviewBox
        title="Koszty energii"
        elements={cardElements}
        icon="pi-dollar"
        color={colors.lightBlue}
        iconColor={colors.darkBlue}
        flexGrow={8}
      />
      <RadioButtonsSection
        setButtonChecked={setChartType}
        radioButtons={radioOptions}
        value={chartType}
        title="Typ wykresu"
        flexGrow={4}
        addStyles={"section-wrapper-column"}
      />

      <Card>
        <Chart
          options={chartData}
          chartName={"Koszty"}
          constructorType={chartType === "spline" ? "stockChart" : null}
          isLoading={isLoading}
        />
      </Card>
    </Grid>
  );
});
