import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsVariwide from "highcharts/modules/variwide";
import highchartsAnnotations from "highcharts/modules/annotations";
import Boost from "highcharts/modules/boost";
import highchartsMore from "highcharts/highcharts-more";
import { observer } from "mobx-react";

highchartsVariwide(Highcharts);
Boost(Highcharts);
highchartsMore(Highcharts);
highchartsAnnotations(Highcharts);

export const StockChart = observer(({ options, constructorType } = {}) => {
  return (
    <>
      <HighchartsReact highcharts={Highcharts} constructorType={constructorType} options={options} />
    </>
  );
});
