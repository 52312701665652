import { NoteWrapper } from "../../NoteDialog/NoteWrapper";
import NoteDialog from "../../NoteDialog/NoteDialog";
import { ChartWithNotes } from "./ChartWithNotes";
import { observer } from "mobx-react";

export const Chart = observer(
  ({
    options,
    constructorType,
    masterDetailChartData,
    displayToast,
    isLoading,
    chartName,
    deviceId,
    chartResolution,
    displayNotesList
  } = {}) => {
    return (
      <>
        <NoteWrapper
          chartName={chartName}
          deviceId={deviceId}
          chartResolution={chartResolution}
          displayNotesList={displayNotesList}
        >
          {displayNotesList ? <NoteDialog /> : null}
          <ChartWithNotes
            options={options}
            constructorType={constructorType}
            masterDetailChartData={masterDetailChartData}
            displayToast={displayToast}
            isLoading={isLoading}
            displayNotesList={displayNotesList}
          />
        </NoteWrapper>
      </>
    );
  }
);
