import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { getTelemetryData } from "../../../../api/clientApi";
import { TIME_CONSTANTS } from "../../../../constants/timeValues";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { getPieChartOptions } from "../../../../constants/defaultChartOptions";
import { buttonResolutionFilter, buttonDateFilter } from "../../../../utils/dateAndResolutionFilter";
import "../../../../styles/globalStyles.scss";

export const LowQualityCounter = observer(() => {
  const [buttonChecked, setButtonChecked] = useState(standardTimeRadioButtons[0].id);

  const toastRef = React.useRef(null);
  const { selectedDevices } = useClientStore();
  const [isLoading, setIsLoading] = useState(false);
  const [sortedData, setSortedData] = useState([[], [], [], [], [], [], [], [], []]);
  const [telemetryData, setTelemetryData] = useState({
    device_id: 0,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  const ranges = [-Infinity, 200, 210, 220, 230, 240, 250, 260, 270, Infinity];
  const rangesTitle = [
    "<200V ",
    "200V-210V",
    "210V-220V",
    "220V-230V",
    "230V-240V",
    "240V-250V",
    "250V-260V",
    "260V-270V",
    ">270V"
  ];

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      getLowQualityCounterTelemetryData(selectedDevices.id, buttonChecked);
    } else {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    }
  }, [selectedDevices.id, buttonChecked]);

  useEffect(() => {
    sortRecords(telemetryData.data);
  }, [telemetryData]);

  const checkRecordRange = record => {
    return ranges.findIndex((range, index, ranges) => {
      const currentRangeValue = range;
      const nextRangeValue = ranges[index + 1];
      if (record > currentRangeValue && record <= nextRangeValue) {
        return true;
      }
    });
  };

  const sortRecords = data => {
    const sortedRecords = [[], [], [], [], [], [], [], [], []];
    data.forEach(data => {
      const record = data[1];
      const rangeIndex = checkRecordRange(record);
      sortedRecords[rangeIndex].push(record);
    });
    setSortedData(sortedRecords);
  };

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const getLowQualityCounterTelemetryData = useCallback(
    async (id, buttonChecked) => {
      setIsLoading(true);

      const { resolution } = buttonResolutionFilter(buttonChecked);

      const { data } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(buttonDateFilter(buttonChecked)),
        end: parseDateToApiFormat(),
        resolution,
        // voltage_agr: "|avg"
        avm_voltage_avg_1: 1
      });

      if (!data[0]?.data || data[0]?.data?.length === 0) {
        showToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości", "Informacja", "info");
        setSortedData([]);
        setIsLoading(false);
        return;
      }

      setTelemetryData(data[0]);
      setIsLoading(false);
    },
    [showToast]
  );

  const getLowQualityCounterChartData = useCallback(() => {
    const processedData = [];
    sortedData.map((sortedArray, idx) => {
      const value = sortedArray.length;
      const rTitle = rangesTitle[idx];
      if (value !== 0) {
        processedData.push({
          name: rTitle,
          y: value
        });
      }
    });

    const dataObject = {
      title: "Procentowy udział poszczególnych zakresów napięcia",
      yAxisUnit: "%",
      customPercentageChart: true,
      data: [
        {
          name: "Udział",
          colorByPoint: true,
          data: processedData
        }
      ]
    };
    return getPieChartOptions(dataObject);
  }, [sortedData]);

  const chartData = useMemo(() => getLowQualityCounterChartData(), [getLowQualityCounterChartData]);

  return (
    <Grid>
      <div className="header-toolbar-container">
        <Toast ref={toastRef} />
        <RadioButtonsSection
          flexGrow={12}
          setButtonChecked={setButtonChecked}
          radioButtons={standardTimeRadioButtons}
          value={buttonChecked}
          className="radio-button-section-container"
        />
      </div>
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
