import React from "react";
import { Button } from "primereact/button";

import { CustomCalendar } from "../CustomCalendar/CustomCalendar";

import "./DateFilter.scss";

export const DateFilter = ({
  sinceDate,
  setSinceDate,
  toDate,
  setToDate,
  clearDateFilters,
  dateOnly,
  buttonClear = true
}) => {
  return (
    <div className="datefilter-wrapper">
      <div className="datefilter-container">
        <CustomCalendar
          label="Od"
          value={sinceDate}
          onDateChange={e => setSinceDate(e.target.value)}
          calendarIcon="pi pi-calendar-minus"
          showTime={!dateOnly}
          maxDate={toDate}
        />
      </div>
      <div className="datefilter-container ">
        <CustomCalendar
          label="Do"
          value={toDate}
          onDateChange={e => setToDate(e.target.value)}
          calendarIcon="pi pi-calendar-plus"
          showTime={!dateOnly}
          minDate={sinceDate}
          maxDate={new Date()}
        />
      </div>
      {buttonClear && <Button onClick={clearDateFilters} label="Wyczyść" className="p-button-primary p-ml-2" />}
    </div>
  );
};
