import React, { useCallback } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import { Card } from "../UI/Card/Card";
import { CustomCalendar } from "../CustomCalendar/CustomCalendar";
import { RESOLUTIONS_NAMES } from "../../constants/chartOptions";

import "./ComparisonFilterSection.scss";

export const ComparisonFilterSection = ({
  dates,
  setDates,
  sinceHour,
  setSinceHour,
  toHour,
  setToHour,
  resolution,
  setResolution,
  resolutionOptions,
  onConfirm
}) => {
  const MAX_COMPARED_DATES = 4;

  const onDateChange = useCallback(
    e => {
      if (e.target.value) {
        const newDates = dates.map(date => {
          if (e.target.id === date.id) {
            return {
              id: date.id,
              value: e.target.value
            };
          }
          return date;
        });
        setDates(newDates);
      }
    },
    [dates, setDates]
  );

  const onDropdownChange = useCallback(
    e => {
      setResolution(Object.keys(RESOLUTIONS_NAMES).find(key => RESOLUTIONS_NAMES[key] === e.value));
    },
    [setResolution]
  );

  const onAddButtonClick = useCallback(() => {
    const dateToAdd = new Date();
    dateToAdd.setDate(dateToAdd.getDate() - dates.length);
    const disabledDates = dates.map(date => date.value.getDate());

    while (disabledDates.includes(dateToAdd.getDate())) {
      dateToAdd.setDate(dateToAdd.getDate() - 1);
    }

    const expandedDates = [...dates, { id: `Dzień ${dates.length + 1}`, value: dateToAdd }];
    setDates(expandedDates);
  }, [setDates, dates]);

  const onDeleteButtonClick = useCallback(
    index => {
      dates.splice(index, 1);
      const reducedDates = dates.map((date, index) => {
        return { value: date.value, id: `Dzień ${index + 1}` };
      });

      setDates(reducedDates);
    },
    [dates, setDates]
  );

  return (
    <>
      <Card>
        <div className="date-select-wrapper">
          {dates.map((date, index) => (
            <div key={date.id} className="date-select-element">
              <CustomCalendar
                label={date.id}
                value={date.value}
                calendarId={date.id}
                onDateChange={onDateChange}
                maxDate={new Date()}
                disabledDates={dates.map(date => date.value)}
                className="date-select"
              />
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                disabled={dates.length === 1}
                onClick={() => onDeleteButtonClick(index)}
              />
            </div>
          ))}
          {(!dates || dates.length < MAX_COMPARED_DATES) && (
            <Button label="Dodaj dzień" onClick={onAddButtonClick} className="comparison-filter-item" />
          )}
        </div>
      </Card>
      <Card>
        <div className="time-filter-section-container">
          <div className="time-filter-item-wrapper">
            <span className="comparison-filter-item">Od:</span>
            <Calendar
              className="time-select"
              timeOnly
              value={sinceHour}
              onChange={e => setSinceHour(e.target.value)}
              maxDate={toHour}
              icon="pi pi-clock"
              showIcon
            />
          </div>

          <div className="time-filter-item-wrapper">
            <span className="comparison-filter-item">Do:</span>
            <Calendar
              className="time-select"
              timeOnly
              value={toHour}
              onChange={e => setToHour(e.target.value)}
              minDate={sinceHour}
              disabled={!sinceHour}
              icon="pi pi-clock"
              showIcon
            />
          </div>
          <div className="time-filter-item-wrapper">
            <span className="resolution-text">Rozdzielczość:</span>
            <Dropdown value={RESOLUTIONS_NAMES[resolution]} options={resolutionOptions} onChange={onDropdownChange} />
            <Button label="Zatwierdź" onClick={onConfirm} className="p-button-success p-ml-2" />
          </div>
        </div>
      </Card>
    </>
  );
};
