import React from "react";
import { observer } from "mobx-react";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "../UI/Card/Card";

import { useUserOptionsStore } from "../../common/hooks/storeHooks";

import "./VoltageNumberInput.scss";

export const VoltageNumberInput = observer(() => {
  const { voltageDeviationThreshold, setVoltageDeviationThreshold } = useUserOptionsStore();

  return (
    <Card className="voltage-card-container">
      <div className="voltage-number-input-container">
        <span className="voltage-span">Graniczna wartość odchylenia napięcia od 230V, które nadal będzie w normie</span>
        <InputNumber
          value={voltageDeviationThreshold}
          onValueChange={e => setVoltageDeviationThreshold(e.value)}
          mode="decimal"
          showButtons
          inputStyle={{ width: "40px" }}
          min={1}
          max={50}
        />
      </div>
    </Card>
  );
});
