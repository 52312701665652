import { useMemo } from "react";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { getPvProductionData } from "../../../../mocks/pvProduction";

export const PvProduction = ({ deviceId, chartName, renderOptions, getData, withWeather }) => {
  const { isPhotovoltaicConnected } = useClientStore();

  const chartData = useMemo(() => {
    if (!isPhotovoltaicConnected) {
      const defaultChartOptions = getPvProductionData(getData, withWeather);
      const { series: defaulSeries } = defaultChartOptions;
      const chartOptions = {
        ...defaultChartOptions,
        series: defaulSeries.map(({ type, name }) => ({
          type,
          name,
          data: []
        }))
      };

      return chartOptions;
    }

    return getPvProductionData(getData, withWeather);
  }, [getData, withWeather, isPhotovoltaicConnected]);

  return (
    <Grid>
      {renderOptions && renderOptions()}
      <Card>
        <Chart options={chartData} deviceId={deviceId} chartName={chartName} displayNotesList />
      </Card>
    </Grid>
  );
};
