export const TOAST_MESSAGE_TYPE = Object.freeze({
  ERR: "error",
  WARN: "warn",
  INFO: "info"
});

export const TOAST_SUMMARY_TEXT = Object.freeze({
  ERR: "Wystąpił błąd",
  WARN: "Ostrzeżenie",
  INFO: "Informacja"
});

export const noteAddSuccessText = "Notatka została uworzona pomyśnie";
export const noteAddErrorText = "Notatka nie została wysłana. Spróbuj ponownie później.";
export const noteAddSendErrorText = "Notatka nie została wysłana. Spróbuj ponownie później.";
export const noteEditSuccessText = "Notatka została zedytowana pomyśnie";
export const noteEditErrorText = "Notatka nie została zedytowania. Spróbuj ponownie później.";
export const noteEditSendErrorText = "Notatka nie została zedytowania. Spróbuj ponownie później.";
