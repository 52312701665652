import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import axiosClient from "../api/axiosClient";

export const Error = () => {
  const history = useHistory();

  const checkConnection = React.useCallback(async () => {
    try {
      await axiosClient.get("/");
      history.goBack();
    } catch (error) {
      history.push("/error");
    }
  }, [history]);

  React.useEffect(() => {
    checkConnection();
  }, [checkConnection]);

  return (
    <div className="exception-body error">
      <div className="exception-content">
        <div className="exception-title" style={{ color: "black" }}>
          Błąd
        </div>
        <div className="exception-detail" style={{ color: "black" }}>
          Sprawdź połączenie z internetem lub skonsultuj się z administratorem systemu.
        </div>
        <Button label="Odśwież" onClick={checkConnection} className="p-button-secondary  p-mt-6" />
      </div>
    </div>
  );
};
