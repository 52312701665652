import { TOAST_MESSAGE_TYPE, TOAST_SUMMARY_TEXT } from "../constants/toastText";

export const showToast = (toastRef, message, type = TOAST_MESSAGE_TYPE.INFO) => {
  toastRef.current.show({
    severity: type,
    summary:
      type === TOAST_MESSAGE_TYPE.ERR
        ? TOAST_SUMMARY_TEXT.ERR
        : type === TOAST_MESSAGE_TYPE.WARN
        ? TOAST_SUMMARY_TEXT.WARN
        : TOAST_SUMMARY_TEXT.INFO,
    detail: message,
    life: 4000
  });
};
