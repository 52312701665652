import { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useClientStore } from "../../common/hooks/storeHooks";
import { CustomTreeSelect } from "./CustomTreeSelect";
import { mapDevices } from "../../utils/devices";

export const DeviceTreeSelect = observer(() => {
  const { selectedDevices, selectDevice, devicesNodes, fetchDevicesNodes } = useClientStore();

  const devices = useMemo(() => mapDevices(devicesNodes), [devicesNodes]);

  useEffect(() => {
    fetchDevicesNodes();
  }, [fetchDevicesNodes]);

  return (
    <CustomTreeSelect
      nodes={devices}
      selectedKeys={selectedDevices.id}
      setSelectedKeys={selectDevice}
      placeholder="Wybierz pliszkę"
    />
  );
});
