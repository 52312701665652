import moment from "moment";

export const priceForWatt = 0.0035;
//Every day: B12, C22b, Monday-Saturday: C12n
const dayNightPeakTime = {
  B12: { from: "7:00", to: "22:00", everyDay: true },
  C22b: { from: "6:00", to: "21:00", everyDay: true },
  C12n: { from: "5:00", to: "1:00", everyDay: false }
};

// Summer period - interval
const summertime = { from: "01-04", to: "30-09" };

// Monday-Friday: B23, C23, C13 Every day: C12a, C12ap
const morningAfternoonPeakTime = {
  monFri: {
    summer: { morning: { from: "7:00", to: "13:00" }, afternoon: { from: "19:00", to: "22:00" } },
    winter: { morning: { from: "7:00", to: "13:00" }, afternoon: { from: "16:00", to: "21:00" } },
    everyDay: false
  },
  everyDay: {
    summer: { morning: { from: "8:00", to: "11:00" }, afternoon: { from: "20:00", to: "21:00" } },
    winter: { morning: { from: "8:00", to: "11:00" }, afternoon: { from: "17:00", to: "21:00" } },
    everyDay: true
  }
};

//Monday-Friday: C12w, C22w (?)
const dayZone = {
  C12w: { morning: { from: "6:00", to: "13:00" }, afternoon: { from: "15:00", to: "22:00" }, everyDay: false },
  C22w: { morning: { from: "6:00", to: "21:00" }, afternoon: { from: "21:00", to: "6:00" }, everyDay: false }
};

// Every day: B22, C22a
const monthsTimeZones = {
  JanFebNovDec: { morning: { from: "8:00", to: "11:00" }, afternoon: { from: "16:00", to: "21:00" } },
  MarOct: { morning: { from: "8:00", to: "11:00" }, afternoon: { from: "18:00", to: "21:00" } },
  AprSep: { morning: { from: "8:00", to: "11:00" }, afternoon: { from: "19:00", to: "21:00" } },
  MayJunJulAug: { morning: { from: "8:00", to: "11:00" }, afternoon: { from: "20:00", to: "21:00" } },
  everyDay: true
};

const priceMock = 0.35;

export const getTotalCost = totalConsumption => Math.round(totalConsumption * priceMock * 100) / 100;

export const calculateActiveEnergyTotalCost = (telemetryData, resolution) => {
  const time = getTime(resolution);
  const kilowattsPerHour = time * 30 * 24;
  return telemetryData[0]?.data.map(dataPart => [
    dataPart[0],
    Math.round((dataPart[1] / 1000) * kilowattsPerHour * priceMock * 100) / 100
  ]);
};

export const calculateDistributionCost = (telemetryData, osdData, resolution, contractualCapacity) => {
  const time = getTime(resolution);
  return telemetryData?.[0]?.data?.map(dataPart => [
    dataPart[0],
    Math.round(getCalculateDistributionCost(dataPart[0], dataPart[1], osdData, time, contractualCapacity) * 100) / 100
  ]);
};

function getCalculateDistributionCost(dateTime, powerConsumption, osdData, time, contractualCapacity) {
  if (osdData !== undefined) {
    const monthlyContactedPowerCost = osdData.rate.includes("C") ? contractualCapacity * 10 : 0; // 10 PLN / kW / miesic za moc umowną dla taryf C
    const singlePointContractedPowerCosts = monthlyContactedPowerCost * time || 0;
    const powerEnergyKw = powerConsumption / 1000;
    const powerEnergyKwh = time * 30 * 24 * powerEnergyKw;
    const fixedComponentOfTheNetworkRate = osdData.constants_network_rate * powerEnergyKw * time;
    const transitionalFeeRate = osdData.transitional_fee * powerEnergyKw * time;
    const variableComponentOfTheNetworkRate = getVariableComponentOfTheNetworkRate(
      dateTime,
      osdData.network_rate,
      powerEnergyKwh,
      osdData.rate
    );
    const qualitativeRate = osdData.qualitative_rate * powerEnergyKwh;
    const rateOfTheSubscriptionFeeInTheCycle = osdData.subscription_fee.month_1 * time;
    return (
      fixedComponentOfTheNetworkRate +
      transitionalFeeRate +
      variableComponentOfTheNetworkRate +
      qualitativeRate +
      rateOfTheSubscriptionFeeInTheCycle +
      singlePointContractedPowerCosts
    );
  } else {
    return 0;
  }
}

export const getSummaryCost = costs => {
  const summaryCost = costs?.reduce((summ, element) => summ + element[1], 0);
  return Math.round(summaryCost * 100) / 100;
};

function getVariableComponentOfTheNetworkRate(dateTime, networkRate, convertingKwToKwh, osdRate) {
  if (networkRate.twenty_four_hour !== null) {
    return networkRate.twenty_four_hour * convertingKwToKwh;
  } else if (networkRate.daily_peak !== null && networkRate.night_off_peek !== null) {
    return getDayOrNightPeak(networkRate, dateTime, osdRate) * convertingKwToKwh;
  } else {
    //TODO: B24, C24
    return getMorningOrAfternoonPeak(networkRate, dateTime, osdRate) * convertingKwToKwh;
  }
}

function getDayOrNightPeak(networkRate, dateTime, osdRate) {
  //TODO: C12b, C12bp
  if (osdRate === "B12") {
    return getDayOrNightPeakForOsdRate(networkRate, dateTime, dayNightPeakTime.B12);
  } else if (osdRate === "C22b") {
    return getDayOrNightPeakForOsdRate(networkRate, dateTime, dayNightPeakTime.C22b);
  } else {
    return getDayOrNightPeakForOsdRate(networkRate, dateTime, dayNightPeakTime.C12n);
  }
}

function getDayOrNightPeakForOsdRate(networkRate, dateTime, osdTime) {
  let checkDay = true;
  if (osdTime.everyDay) {
    const day = new Date(dateTime).getDay();
    if (day === 0) {
      checkDay = false;
    }
  }
  if (moment(dateTime).format("HH:ss") >= osdTime.from && moment(dateTime).format("HH:ss") <= osdTime.to && checkDay) {
    return networkRate.daily_peak;
  } else {
    return networkRate.night_off_peek;
  }
}

function getMorningOrAfternoonPeak(networkRate, dateTime, osdRate) {
  if (osdRate === "B23" || osdRate === "C23" || osdRate === "C13") {
    return getMorningOrAfternoonPeakForOsdRate(networkRate, dateTime, morningAfternoonPeakTime.monFri);
  } else if (osdRate === "C12a" || osdRate === "C12ap") {
    return getMorningOrAfternoonPeakForOsdRate(networkRate, dateTime, morningAfternoonPeakTime.everyDay);
  } else if (osdRate === "C12w") {
    //TODO: C22w
    return getMorningOrAfternoonPeakForSeason(networkRate, dateTime, dayZone.C12w, dayZone.C12w.everyDay);
  } else {
    return getMorningOrAfternoonPeakMonthsZone(networkRate, dateTime);
  }
}

function getMorningOrAfternoonPeakMonthsZone(networkRate, dateTime) {
  const month = moment(dateTime).format("MM");
  switch (month) {
    case ("01", "02", "11", "12"):
      return getMorningOrAfternoonPeakForSeason(
        networkRate,
        dateTime,
        monthsTimeZones.JanFebNovDec,
        monthsTimeZones.everyDay
      );
    case ("03", "10"):
      return getMorningOrAfternoonPeakForSeason(
        networkRate,
        dateTime,
        monthsTimeZones.MarOct,
        monthsTimeZones.everyDay
      );
    case ("04", "09"):
      return getMorningOrAfternoonPeakForSeason(
        networkRate,
        dateTime,
        monthsTimeZones.AprSep,
        monthsTimeZones.everyDay
      );
    default:
      return getMorningOrAfternoonPeakForSeason(
        networkRate,
        dateTime,
        monthsTimeZones.MayJunJulAug,
        monthsTimeZones.everyDay
      );
  }
}

function getMorningOrAfternoonPeakForOsdRate(networkRate, dateTime, osdTime) {
  if (moment(dateTime).format("DD-MM") >= summertime.from && moment(dateTime).format("DD-MM") <= summertime.to) {
    return getMorningOrAfternoonPeakForSeason(networkRate, dateTime, osdTime.summer, osdTime.everyDay);
  } else {
    return getMorningOrAfternoonPeakForSeason(networkRate, dateTime, osdTime.winter, osdTime.everyDay);
  }
}

function getMorningOrAfternoonPeakForSeason(networkRate, dateTime, osdTime, checkEveryDay) {
  let checkDay = true;
  if (!checkEveryDay) {
    const day = new Date(dateTime).getDay();
    if (day === 0 || day === 6) {
      checkDay = false;
    }
  }
  if (
    moment(dateTime).format("HH:ss") >= osdTime.morning.from &&
    moment(dateTime).format("HH:ss") <= osdTime.morning.to &&
    checkDay
  ) {
    return networkRate.morning_peak;
  } else if (
    moment(dateTime).format("HH:ss") >= osdTime.afternoon.from &&
    moment(dateTime).format("HH:ss") <= osdTime.afternoon.from.to &&
    checkDay
  ) {
    return networkRate.afternoon_peak;
  } else if (networkRate.load_valley !== null) {
    return networkRate.load_valley;
  } else {
    return networkRate.remaining_hours;
  }
}

function getTime(time) {
  switch (time) {
    case "10_seconds":
      return 1 / (30 * 24 * 60 * 6);
    case "minute":
      return 1 / (30 * 24 * 60);
    case "hour_quarter":
      return 1 / (30 * 24 * 4);
    case "hour":
      return 1 / (30 * 24);
    case "4_hours":
      return 1 / (30 * 6);
    case "day":
      return 1 / 30;
    case "week":
      return 7 / 30;
    case "month":
      return 1;
    case "year_quarter":
      return 3;
    case "year":
      return 12;
    default:
      return 0;
  }
}
