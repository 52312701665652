import { RadioButton } from "primereact/radiobutton";
import React from "react";
import { Card } from "../UI/Card/Card";

import "./RadioButtonsSection.scss";

export const RadioButtonsSection = ({
  setButtonChecked,
  flexGrow,
  radioButtons,
  title,
  value,
  addStyles = null,
  disabledButtonId,
  ...rest
}) => {
  return (
    <Card flexGrow={flexGrow} {...rest}>
      <div className={`section-wrapper ${addStyles}`}>
        <h3 className="title-section">{`${title || "Okres"}:`}</h3>
        <div className="buttons-section-container">
          {radioButtons.map(category => {
            return (
              <div key={category.id} className="field-radiobutton radio-button-section__radiobutton ">
                <RadioButton
                  inputId={category.id}
                  name={category.label}
                  value={category.id}
                  checked={value === category.id}
                  onChange={e => setButtonChecked(e.value)}
                  disabled={disabledButtonId === category.id}
                />
                <label htmlFor={category.id} className="cursor-pointer radio-button-section__radiobutton-label">
                  {category.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};
