import Highcharts from "highcharts";
import { colors } from "../styles/colors";
import { generateWeatherData } from "../utils/chartUtils";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "./timeValues";
import { RESOLUTIONS_NUMBERS } from "./chartOptions";
import NoteIcon from "../assets/images/note.svg";
import { TOAST_MESSAGE_TYPE } from "./toastText";
import { NO_DATA_MESSAGE } from "./chartOptions";
const { lightGrey, lightRed, blue, lightYellow, transparentBlack } = colors;

const getTooltipOptions = (unit, withoutHeader) => ({
  headerFormat: withoutHeader ? "" : "<b>{point.key}</b><br>",
  pointFormat: `{series.name}: <b>{point.y} ${unit}</b><br/>`
});

const handleEventLegendItemClickOption = event => {
  const target = event.target;
  return target.chart.series.some(singleSeries =>
    target === singleSeries ? !singleSeries.visible : singleSeries.visible
  );
};
/*calculation of max y value for data set*/
const getMaxYAxisValue = data => {
  if (data && data.length > 0) {
    const max = data.map(value => {
      if (!Array.isArray(value)) return value;
      return value[1];
    });
    return Math.max.apply(Math, max);
  }
  return 0;
};
/*calculation of new point x and y for notes for different resolution than note was created*/
const calculateNewPointXYForNewChartResolution = (note, options) => {
  const point = { pointX: note.pointX, pointY: note.pointY };
  const interval = options.plotOptions?.pointInterval
    ? options.plotOptions?.pointInterval
    : options.plotOptions?.spline.pointInterval;
  if (note.chartResolution && interval !== RESOLUTIONS_NUMBERS[note.chartResolution]) {
    const modulo = note.pointX % interval;
    const yMax = options.series ? options.series[0].yAxisMaxValue : 0;
    if (modulo !== 0) {
      point.pointX = note.pointX - modulo;
      point.pointY = yMax + yMax / 25;
    } else {
      point.pointY = yMax + yMax / 25;
    }
  }
  return point;
};

const tooltipWeather = {
  temperature: { name: "Temperatura", unit: "°C" },
  windDirection: { name: "Kierunek wiatru", unit: "°" },
  windStrength: { name: "Siła wiatru", unit: "m/s" },
  humidity: { name: "Wilgotność", unit: "%" },
  cloudiness: { name: "Stopień zachmurzenia", unit: "%" },
  weather: { name: "Pogoda", unit: "" }
};

const getWeatherSeries = (plot, dataObject) => {
  return {
    name: plot.name,
    lineWidth: 0,
    color: "transparent",
    marker: {
      enabledThreshold: 1
    },

    custom: {
      ...tooltipWeather.value
    },
    tooltip: {
      crosshairs: dataObject.crosshairs,
      shared: dataObject.tooltipShared,
      xDateFormat: dataObject.tooltipTimeOnly && "Godzina: %H:%M",
      ...getTooltipOptions(dataObject.yAxisUnit, dataObject.withoutHeader),
      pointFormatter: function () {
        return this.custom.tempValue
          ? `<br>${this.series.name} <br> ${tooltipWeather.temperature.name}: <b>${this.custom.tempValue}${tooltipWeather.temperature.unit}</b>
          <br>${tooltipWeather.humidity.name}: <b>${this.custom.humidityValue}${tooltipWeather.humidity.unit}</b>
          <br>${tooltipWeather.windStrength.name}: <b>${this.custom.windValue} ${tooltipWeather.windStrength.unit}</b>
          <br>${tooltipWeather.windDirection.name}: <b>${this.custom.windDirectionValue}${tooltipWeather.windDirection.unit}</b>
          <br>${tooltipWeather.weather.name}: <b>${this.custom.weatherDescriptionValue}</b>
          <br>${tooltipWeather.cloudiness.name}: <b>${this.custom.cloudinessValue}${tooltipWeather.cloudiness.unit}</b>`
          : "";
      }
    },
    data: generateWeatherData(plot.data, plot.weather)
  };
};

export const getMixedSingleAxisChartOptions = dataObject => {
  return {
    chart: {
      zoomType: "xy",
      height: 600
    },
    title: {
      text: dataObject.title
    },
    lang: {
      noData: NO_DATA_MESSAGE
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "32px",
        color: "#303030"
      }
    },
    xAxis: {
      type: dataObject.xAxisType ?? "datetime",
      categories: dataObject.categories,
      labels: {
        overflow: "justify"
      },
      minRange: dataObject.xAxisType ? 5 : 5 * TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]
    },
    yAxis: dataObject.multipleAxes || [
      {
        labels: { format: `{value} ${dataObject.yAxisUnit ?? ""}` },
        title: {
          text: dataObject.yAxisTitle
        },
        minRange: 2
      }
    ],
    tooltip: {
      ...dataObject.tooltip,
      crosshairs: true,
      shared: true
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      ...dataObject.plotOptions,
      pointInterval: dataObject.pointInterval,
      series: {
        states: {
          hover: {
            lineWidthPlus: 0
          }
        },
        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    colors: dataObject.colors ?? [lightGrey, lightRed, blue],
    series: dataObject.series.map(({ unit, markerEnabled, type, ...rest }) => {
      const { data } = rest;
      const yAxisMaxValue = getMaxYAxisValue(data);
      return {
        ...rest,
        yAxisMaxValue: yAxisMaxValue,
        type: type ?? "spline",
        tooltip: { valueSuffix: unit ?? ` ${dataObject.yAxisUnit ?? ""}` },
        marker: { enabled: markerEnabled ?? false }
      };
    }),
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getColumnWithNegativeValues = dataObject => {
  return {
    chart: { type: "column", height: 600 },
    title: { text: dataObject.title },
    xAxis: {
      type: "datetime",
      labels: { overflow: "justify" },
      minRange: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]
    },
    yAxis: {
      title: { text: "Koszty" },
      stackLabels: {
        enabled: true,
        style: { fontWeight: "bold", color: "gray" },
        formatter: function (a) {
          return Highcharts.numberFormat(this.total, 2);
        }
      }
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    plotOptions: {
      column: { stacking: "normal", dataLabels: { enabled: false } },
      columnrange: { dataLabels: { enabled: false } }
    },
    series: dataObject.plots,
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getSplineChartOptions = dataObject => {
  const seriesData = dataObject.plots.map(plot => {
    const yAxisMaxValue = getMaxYAxisValue(plot.data);
    return {
      name: plot.name,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      data: plot.data,
      yAxisMaxValue: yAxisMaxValue
    };
  });

  return {
    navigator: {
      height: dataObject?.navigatorHeight ? dataObject?.navigatorHeight : 80
    },
    chart: {
      type: "spline",
      zoomType: dataObject.zoomType,
      animation: false,
      height: dataObject?.height ? dataObject?.height : 800,
      events: dataObject?.events
    },
    title: {
      text: dataObject.title
    },
    lang: {
      noData: NO_DATA_MESSAGE
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "32px",
        color: "#303030"
      }
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify",
        formatter: dataObject.xAxisFormatter
      },
      minRange: dataObject.xAxisFormatter ? undefined : TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]
    },
    yAxis: {
      labels: {
        format: `{value} ${dataObject.yAxisUnit ?? ""}`
      },
      opposite: dataObject?.yAxisOpposite ? dataObject?.yAxisOpposite : false,
      title: {
        text: dataObject.yAxisLabel
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      crosshairs: dataObject.crosshairs,
      shared: dataObject.tooltipShared,
      xDateFormat: dataObject.tooltipTimeOnly && "Godzina: %H:%M",
      ...getTooltipOptions(dataObject.yAxisUnit, dataObject.withoutHeader)
    },
    plotOptions: {
      spline: {
        pointStart: dataObject.startTime,
        pointInterval: dataObject.pointInterval
      },

      series: {
        states: {
          hover: {
            lineWidthPlus: 0
          }
        },

        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    series: !dataObject.withWeather
      ? [...seriesData]
      : [
          ...seriesData,
          ...dataObject?.weatherData?.map(plot => {
            return getWeatherSeries(plot, dataObject);
          })
        ],
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getVariwideChartOptions = (dataObject, chartHeight) => {
  return {
    chart: {
      type: "variwide",
      height: chartHeight ? chartHeight : 600
    },

    title: {
      text: dataObject.title,
      useHTML: true
    },
    lang: {
      noData: NO_DATA_MESSAGE
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "32px",
        color: "#303030"
      }
    },
    subtitle: {
      text: dataObject.subtitle
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -55
      }
    },
    yAxis: {
      labels: {
        useHTML: true,
        format: `{value} ${dataObject.yAxisUnit ?? ""}`
      },
      title: {
        text: dataObject.yAxisLabel,
        useHTML: true
      }
    },

    caption: {
      text: dataObject.caption
    },

    legend: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      outside: true
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    series: [
      {
        name: dataObject.title,
        data: dataObject.data,
        dataLabels: {
          enabled: true,
          format: `{point.y} ${dataObject.format}`,
          useHTML: true,
          x: 10,
          y: -40,
          padding: 0,
          allowOverlap: true,
          rotation: -45,
          crop: false,
          style: {
            textOverflow: "ellipsis"
          },
          ...dataObject.dataLabels
        },
        tooltip: {
          headerFormat: getTooltipOptions().headerFormat,
          pointFormatter: function () {
            const totalValueData = dataObject.tooltip.value.totalValueName
              ? `${dataObject.tooltip.value.totalValueName}: <b>${(this.z * this.y).toFixed()} ${
                  dataObject.tooltip.unit.totalValueName || ""
                }</b><br>`
              : "";
            return (
              `
            ${dataObject.tooltip.value.yValueName}: <b>${this.y} ${dataObject.tooltip.unit.yValueName || ""}</b><br>
            ${dataObject.tooltip.value.xValueName}: <b>${this.z} ${dataObject.tooltip.unit.xValueName || ""}</b><br>` +
              totalValueData
            );
          }
        },
        colorByPoint: true
      }
    ],
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getHorizontalBarChartOptions = dataObject => {
  return {
    chart: {
      type: "bar",
      height: 800
    },
    title: {
      text: dataObject.title
    },
    xAxis: {
      categories: dataObject.categories,
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: dataObject.yAxisLabel
      },
      labels: {
        overflow: "justify"
      }
    },
    tooltip: getTooltipOptions(dataObject.yAxisUnit, dataObject.withoutHeader),
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: dataObject.dataLabelsEnabled,
          format: `{point.y} ${dataObject.yAxisUnit}`
        }
      },
      series: {
        stacking: dataObject.stacking,
        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 160,
      floating: true,
      borderWidth: 1,
      backgroundColor: "#FFFFFF",
      shadow: true,
      itemMarginBottom: 10,
      itemMarginTop: 10,
      padding: 20
    },
    series: dataObject.data,
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getVerticalBarChartOptions = dataObject => {
  return {
    chart: {
      height: 600
    },

    title: {
      text: dataObject.title
    },

    xAxis: {
      categories: dataObject.categories
    },
    lang: {
      noData: NO_DATA_MESSAGE
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "32px",
        color: "#303030"
      }
    },
    yAxis: {
      labels: {
        format: `{value} ${dataObject.yAxisUnit ?? ""}`
      },
      title: {
        text: dataObject.yAxisLabel
      }
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    series: [
      {
        type: "column",
        tooltip: {
          headerFormat: getTooltipOptions().headerFormat,
          pointFormat: dataObject.pointLabelFormat
        },
        colorByPoint: true,
        data: dataObject.data,
        showInLegend: false
      }
    ],
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getCircleBarChartOptions = dataObject => {
  return {
    colors: dataObject.colors,
    chart: {
      type: "column",
      inverted: true,
      polar: true,
      height: 700
    },
    title: {
      text: dataObject.title
    },
    caption: {
      text: dataObject.caption
    },
    tooltip: {
      outside: true,
      ...getTooltipOptions(dataObject.yAxisUnit)
    },
    pane: {
      size: "85%",
      innerSize: "20%",
      endAngle: 270
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: "right",
        useHTML: true,
        allowOverlap: true,
        step: 1,
        y: 3,
        style: {
          fontSize: "13px"
        }
      },
      lineWidth: 0,
      categories: dataObject.categories
    },
    yAxis: {
      max: dataObject.yAxisMax,
      crosshair: {
        enabled: true,
        color: "#333"
      },
      labels: {
        format: "{text} %"
      },
      lineWidth: 0,
      tickInterval: 10,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15
      },
      series: {
        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    series: dataObject.data,
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getPieChartOptions = dataObject => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 600
    },
    title: {
      text: dataObject.title
    },
    caption: {
      text: dataObject.caption
    },
    tooltip: dataObject.customPercentageChart
      ? { pointFormat: `<b>{point.percentage:.2f} ${dataObject.yAxisUnit}</b>` }
      : getTooltipOptions(dataObject.yAxisUnit),
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: dataObject.customPercentageChart
            ? `<b>{point.name}</b>:<br>{point.percentage:.2f} ${dataObject.yAxisUnit}`
            : `<b>{point.name}</b>: {point.y} ${dataObject.yAxisUnit}`
        }
      },
      series: {
        events: {
          legendItemClick: handleEventLegendItemClickOption
        }
      }
    },
    series: dataObject.data,
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getColumnStackedChartOptions = dataObject => {
  return {
    chart: {
      type: "column",
      height: 600
    },
    title: {
      text: dataObject.title
    },
    lang: {
      noData: NO_DATA_MESSAGE
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "32px",
        color: "#303030"
      }
    },
    xAxis: {
      categories: dataObject.categories
    },
    yAxis: {
      min: 0,
      title: {
        text: "Koszty"
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color: "gray"
        }
      }
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: dataObject.plots,
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getChartOptionsWithNotes = (options, notes, handleEventPointClick) => {
  const series = options.series?.map(set => set?.name);
  return {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      series: {
        cursor: "pointer",
        events: {
          ...options.plotOptions?.series?.events
        },
        showInNavigator: true,
        point: {
          events: {
            click: handleEventPointClick,
            mouseOut: function () {
              if (this.series.chart.lbl) {
                this.series.chart.lbl.hide();
              }
            },
            mouseOver: function () {
              const note = this.series.chart.annotations.find(
                annotation =>
                  this.series.name === annotation.options.seriesName &&
                  annotation.options.pointX === this.x &&
                  annotation.options.pointY === this.y
              );
              const chart = this.series.chart;
              if (!chart.lbl) {
                chart.lbl = chart.renderer
                  .label("")
                  .attr({
                    padding: 10,
                    r: 10,
                    fill: transparentBlack
                  })
                  .css({
                    color: "#FFFFFF",
                    overflowWrap: "break-word",
                    wordBreak: "break-all",
                    width: "300px"
                  })
                  .add();
              }
              if (note) {
                chart.lbl.show().attr({
                  text: "Notatka: " + note.options.note
                });
              }
            }
          }
        }
      }
    },
    annotations: notes?.map(note => {
      const seriesName = series.find(name => name === undefined || name === note.seriesName);
      if (!!seriesName) {
        const { pointX, pointY } = calculateNewPointXYForNewChartResolution(note, options);
        return {
          draggable: "",
          allowOverlap: true,
          events: {
            click: function () {
              const event = {
                point: {
                  id: this.options.pointId,
                  x: this.options.pointX,
                  y: this.options.pointY,
                  series: { name: this.options.seriesName }
                }
              };
              handleEventPointClick(event);
            }
          },
          note: note.note,
          seriesName: note.seriesName,
          pointX: pointX,
          pointY: pointY,
          pointId: note.pointId,
          labels: [
            {
              className: "highcharts-no-tooltip cursor-pointer",
              point: { x: pointX, y: pointY, xAxis: 0, yAxis: 0 },
              text: `<img width="25" height="auto" src="${NoteIcon}"/>`,
              shape: "connector",
              backgroundColor: lightYellow,
              padding: 0,
              useHTML: true
            }
          ]
        };
      } else {
        return {};
      }
    })
  };
};

export const getMasterDetailChartOpions = (data, setLineChartOptions, displayToast) => {
  const [firstElem, secondElem] = data[0];
  const maxZoom = (firstElem[0] - secondElem[0]) * 10;
  const pointInterval = firstElem[0] - secondElem[0];

  return {
    chart: {
      height: 100,
      reflow: false,
      borderWidth: 0,
      backgroundColor: null,
      marginLeft: 50,
      marginRight: 20,
      zoomType: "x",
      events: {
        selection: function (event) {
          const extremesObject = event.xAxis[0],
            min = extremesObject.min,
            max = extremesObject.max,
            detailData = [[], []],
            xAxis = this.xAxis[0];

          this.series.map(({ data }, i) =>
            data.forEach(dataPart => {
              if (dataPart.x > min && dataPart.x < max) {
                detailData[i].push([dataPart.x, dataPart.y]);
              }
            })
          );

          if (detailData?.some(data => data.length < 5)) {
            displayToast("Wybrany zakres jest zbyt mały", TOAST_MESSAGE_TYPE.WARN);

            return false;
          }

          xAxis.removePlotBand("mask-front");
          xAxis.addPlotBand({
            id: "mask-front",
            from: min,
            to: max,
            color: "rgba(0, 0, 0, 0.2)"
          });

          setLineChartOptions(detailData);
          return false;
        }
      }
    },
    title: {
      text: null
    },
    accessibility: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      showLastTickLabel: true,
      maxZoom,
      title: {
        text: null
      }
    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      min: 0.6,
      showFirstLabel: false
    },
    tooltip: {
      formatter: function () {
        return false;
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        fillColor: {
          linearGradient: [0, 0, 0, 70],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, "rgba(255,255,255,0)"]
          ]
        },
        lineWidth: 1,
        marker: {
          enabled: false
        },
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        enableMouseTracking: false
      }
    },
    series: data.map(dataPart => ({
      type: "area",
      pointStart: dataPart[0][0],
      pointInterval,
      data: dataPart
    })),
    exporting: {
      enabled: false
    },
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};

export const getLargeTreeMapChartOptions = dataObject => {
  return {
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        allowDrillToNode: true,
        animation: false,
        dataLabels: {
          enabled: true
        },
        levelIsConstant: false,
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true
            },
            borderWidth: 3
          }
        ],
        data: dataObject.data
      }
    ],
    title: {
      text: dataObject.title
    },
    credits: false,
    time: {
      timezoneOffset: new Date().getTimezoneOffset()
    }
  };
};
