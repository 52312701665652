import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { FaRegClock } from "react-icons/fa";

import AppBreadcrumb from "./AppBreadcrumb";
import { ClientTreeSelect } from "./components/TreeSelect/ClientTreeSelect";

import "./AppTopbar.scss";
import { CustomClock } from "./components/CustomClock/CustomClock";
import { CLIENTS_PATHS, DEVICES_PATHS, CLIENT_DEVICES_PATHS } from "./constants/treeSelectPaths";
import { DeviceTreeSelect } from "./components/TreeSelect/DeviceTreeSelect";
import { ClientDevicesTreeSelect } from "./components/TreeSelect/ClientDevicesSelect";

const AppTopbar = observer(props => {
  const { pathname } = useLocation();

  const getTreeSelect = useCallback(() => {
    if (CLIENTS_PATHS.includes(pathname)) {
      return <ClientTreeSelect />;
    }
    if (DEVICES_PATHS.includes(pathname)) {
      return <DeviceTreeSelect />;
    }
    if (CLIENT_DEVICES_PATHS.includes(pathname)) {
      return <ClientDevicesTreeSelect />;
    }
    return <DeviceTreeSelect />;
  }, [pathname]);

  return (
    <div>
      <div className="layout-topbar">
        <div className="topbar-left topbar-title">
          <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
            <i className="pi pi-chevron-left"></i>
          </button>
          <span className="topbar-separator"></span>

          <div className="layout-breadcrumb viewname" style={{ textTransform: "uppercase", minWidth: "fit-content" }}>
            <AppBreadcrumb routers={props.routers} />
          </div>
        </div>

        <div className="tree-select-wrapper">{getTreeSelect()}</div>

        <div className="topbar-clock">
          <div className="topbar-info">
            <div className="topbar-info-title">Data/godzina</div>
            <div className="topbar-details">
              <FaRegClock color="black" size={18} />
              <CustomClock />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AppTopbar;
