import { getCircleBarChartOptions } from "../constants/defaultChartOptions";
import { colors } from "../styles/colors";
import { BUILDING_TYPES_CATEGORIES } from "../utils/buildingTypesUtils";

export const getHigherHarmonicsChartData = percentagesData => {
  const dataObject = {
    colors: [colors.green, colors.red],
    title: "Udział wyższych harmonicznych w energii dostarczanej",
    caption: "Udział wyższych harmonicznych energii",
    yAxisUnit: "% energii",
    yAxisMax: 100,
    categories: BUILDING_TYPES_CATEGORIES,
    data: [
      {
        name: "Pierwsza harmoniczna",
        data: percentagesData.firstHarmonicPercentage
      },
      {
        name: "Wyższe harmoniczne",
        data: percentagesData.higherHarmonicsPercentage
      }
    ]
  };
  return getCircleBarChartOptions(dataObject);
};
