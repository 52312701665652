import { observer } from "mobx-react";
import moment from "moment";
import { Toast } from "primereact/toast";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { energyTypeRadioButtons, standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { TIME_CONSTANTS } from "../../../../constants/timeValues";
import { getHigherHarmonicsChartData } from "../../../../mocks/higherHarmonics";
import "../../../../styles/globalStyles.scss";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { mergeSameBuildingTypeData } from "../../../../utils/buildingTypesUtils";
import { getRangeStartDate } from "../../../../utils/helpers";
import { getSelectedDevices } from "../../../../utils/multipleDevicesUtils";

export const HigherHarmonics = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedClientsDevices, clientsDevicesNodes } = useClientStore();
  const [buttonChecked, setButtonChecked] = useState(standardTimeRadioButtons[0].id);
  const [energyTypeButtonChecked, setEnergyTypeButtonChecked] = useState(energyTypeRadioButtons[0].id);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState(new Date(moment(new Date()).subtract(1, "day").startOf("day")));
  const [endTime, setEndTime] = useState(new Date(moment(new Date()).subtract(1, "day").endOf("day")));
  const [resolution, setResolution] = useState(TIME_CONSTANTS.MIN_15);
  const [percentagesData, setPercentagesData] = useState({});

  useEffect(() => {
    getHarmonicsData();
  }, [selectedClientsDevices.ids, buttonChecked, energyTypeButtonChecked]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const getHarmonicsData = useCallback(async () => {
    if (!selectedDevices?.length) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
      return;
    }
    setIsLoading(true);

    const selectedDevicesIds = selectedDevices?.map(device => device.deviceId);

    const { data: devicesArray } = await getTelemetryData({
      devices: selectedDevicesIds.join(","),
      begin: parseDateToApiFormat(startTime),
      end: parseDateToApiFormat(endTime),
      resolution: resolution,
      [`${energyTypeButtonChecked}_energy_fundamental_sum`]: 1,
      [`${energyTypeButtonChecked}_energy_total_sum`]: 1
    });

    const firstHarmonicData = devicesArray.filter(
      device => device.dataType === `${energyTypeButtonChecked}_energy_fundamental_sum`
    );

    const higherHarmonicsData = firstHarmonicData.map(fundamentalEnergyData => {
      const totalEnergyDevice = devicesArray
        .filter(device => device.dataType === `${energyTypeButtonChecked}_energy_total_sum`)
        .find(device => device.device_id === fundamentalEnergyData.device_id);
      return {
        ...totalEnergyDevice,
        data: fundamentalEnergyData?.data.map((element, index) =>
          Math.abs(totalEnergyDevice?.data?.[index]?.[1] - element?.[1])
        )
      };
    });

    const firstHarmonicDataWithBuildingTypes = firstHarmonicData.map(element => {
      const buildingType = selectedDevices.find(device => Number(device.deviceId) === element.device_id).buildingType;

      return {
        id: element.device_id,
        buildingType,
        data: element.data.map(array => Math.abs(array[1]))
      };
    });

    const higherHarmonicsDataWithBuildingTypes = higherHarmonicsData.map(element => {
      const buildingType = selectedDevices.find(device => Number(device.deviceId) === element.device_id).buildingType;

      return {
        id: element.device_id,
        buildingType,
        data: element.data
      };
    });

    const aggregatedFirstHarmonicData = mergeSameBuildingTypeData(firstHarmonicDataWithBuildingTypes).map(type => ({
      ...type,
      data: type.data.flat()
    }));
    const aggregatedHigherHarmonicsData = mergeSameBuildingTypeData(higherHarmonicsDataWithBuildingTypes).map(type => ({
      ...type,
      data: type.data.flat()
    }));

    const summedFirstHarmonicData = aggregatedFirstHarmonicData.map(type => ({
      ...type,
      data: type.data.reduce((summ, element) => summ + element, 0)
    }));
    const summedHigherHarmonicsData = aggregatedHigherHarmonicsData.map(type => ({
      ...type,
      data: type.data.reduce((summ, element) => summ + element, 0)
    }));

    const energySumm = summedFirstHarmonicData.map(
      (element, index) => element.data + summedHigherHarmonicsData[index].data
    );

    const getPercentageValue = (value, index) => Math.round((value / energySumm[index]) * 10000) / 100 || 0;

    const finalData = {
      firstHarmonicPercentage: summedFirstHarmonicData.map((type, index) => getPercentageValue(type.data, index)),
      higherHarmonicsPercentage: summedHigherHarmonicsData.map((type, index) => getPercentageValue(type.data, index))
    };
    setPercentagesData(finalData);
    setIsLoading(false);
  }, [startTime, endTime, resolution, selectedDevices, showToast, energyTypeButtonChecked]);

  const chartData = useMemo(() => getHigherHarmonicsChartData(percentagesData), [percentagesData]);

  const onRadioButtonPress = useCallback(value => {
    setButtonChecked(value);
    setStartTime(getRangeStartDate(value));
    if (value === TIME_CONSTANTS.DAY_1) {
      setResolution(TIME_CONSTANTS.MIN_15);
    } else if (value === TIME_CONSTANTS.YEAR_1) {
      setResolution(TIME_CONSTANTS.HOUR_4);
    } else {
      setResolution(TIME_CONSTANTS.HOUR_1);
    }
  }, []);
  return (
    <Grid>
      <Toast ref={toastRef} />
      <RadioButtonsSection
        setButtonChecked={onRadioButtonPress}
        radioButtons={standardTimeRadioButtons}
        value={buttonChecked}
        className="radio-button-section-container"
      />
      <RadioButtonsSection
        setButtonChecked={setEnergyTypeButtonChecked}
        radioButtons={energyTypeRadioButtons}
        value={energyTypeButtonChecked}
        className="radio-button-section-container"
        title="Energia"
      />
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
