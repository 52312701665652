import React, { useCallback } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { DateFilter } from "../DateFilter/DateFilter";
import { Card } from "../UI/Card/Card";

import "./DateFilterSection.scss";
import { RESOLUTIONS_NAMES } from "../../constants/chartOptions";

export const DateFilterSection = ({
  sinceDate,
  setSinceDate,
  toDate,
  setToDate,
  clearDateFilters,
  resolution,
  setResolution,
  resolutionOptions,
  onConfirm,
  flexGrow
}) => {
  const onDropdownChange = useCallback(
    e => {
      setResolution(Object.keys(RESOLUTIONS_NAMES).find(key => RESOLUTIONS_NAMES[key] === e.value));
    },
    [setResolution]
  );

  return (
    <Card flexGrow={flexGrow}>
      <div className="date-filter-section-container">
        <DateFilter
          sinceDate={sinceDate}
          setSinceDate={setSinceDate}
          toDate={toDate}
          setToDate={setToDate}
          clearDateFilters={clearDateFilters}
        />
        <div>
          <span className="resolution-text">Rozdzielczość:</span>
          <Dropdown value={RESOLUTIONS_NAMES[resolution]} options={resolutionOptions} onChange={onDropdownChange} />
          <Button label="Zatwierdź" onClick={onConfirm} className="p-button-success p-ml-2" />
        </div>
      </div>
    </Card>
  );
};
