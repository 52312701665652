import { getVerticalBarChartOptions } from "../constants/defaultChartOptions";
import { colors } from "../styles/colors";
import { handleNaN } from "../utils/helpers";

export const getDistributionCostsOverviewBoxes = (
  currentTariff = "-",
  daytimeUsage = 0,
  nightTimeUsage = 0,
  dayTimeCost = 0,
  nightTimeCost = 0
) => [
  {
    title: "Taryfa OSD",
    elements: [{ number: currentTariff, text: "Kod taryfy" }],
    icon: "pi-dollar",
    color: colors.lightBlue,
    iconColor: colors.darkBlue
  },
  {
    title: "Miesięczne zużycie energii 8 - 16",
    elements: [
      { number: handleNaN(daytimeUsage), text: "Ilość kWh" },
      { number: handleNaN(dayTimeCost), text: "Koszty OSD PLN" }
    ],
    icon: "pi-dollar",
    color: colors.purple,
    iconColor: colors.darkPurple
  },
  {
    title: "Miesięczne zużycie energii 16 - 8",
    elements: [
      { number: handleNaN(nightTimeUsage), text: "Ilość kWh" },
      { number: handleNaN(nightTimeCost), text: "Koszty OSD PLN" }
    ],
    icon: "pi-dollar",
    color: colors.jade,
    iconColor: colors.darkJade
  }
];

export const getDistributionCostsChartData = (categories, data) => {
  const dataObject = {
    title: "Miesięczne koszty dystrybucji dla poszczególnych taryf OSD",
    yAxisLabel: "Miesięczny koszt",
    yAxisUnit: "PLN",
    pointLabelFormat: "Koszt miesięczny: <b>{point.y} PLN</b><br/>",
    categories,
    data
  };

  return getVerticalBarChartOptions(dataObject);
};
