import React from "react";

import "./Card.scss";

export const Card = ({ children, flexGrow, className, ...rest }) => {
  const growClass = flexGrow ? `p-col-${flexGrow}` : "p-col-12";
  return (
    <div className={`${growClass} ${className} card-wrapper`} {...rest}>
      <div className="card card-container">{children}</div>
    </div>
  );
};
