import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNoteStore } from "../../common/hooks/storeHooks";

const NoteContext = createContext();

export const NoteWrapper = observer(({ children, chartName, deviceId, chartResolution, displayNotesList = true }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [note, setNote] = useState("");
  const noteStore = useNoteStore();
  const [chartPointData, setChartPointData] = useState({});
  const [noteId, setNoteId] = useState(null);
  const [userName, setUserName] = useState("TEST:USER");
  const [authorName, setAuthorName] = useState(null);
  const [modificationAuthor, setModificationAuthor] = useState(null);
  const [date, setDate] = useState(Date.now());
  const [noteDate, setNoteDate] = useState(null);
  const [editDate, setEditDate] = useState(null);

  const { fetchNotes, notes } = noteStore;

  const fetchData = useCallback(async () => {
    await fetchNotes(deviceId);
  }, [fetchNotes, deviceId]);

  const toastRef = React.useRef(null);

  useEffect(() => {
    fetchData(deviceId);
  }, [fetchData, deviceId]);

  const handleEventPointClick = useCallback(
    event => {
      const {
        id: pointId,
        x: pointX,
        y: pointY,
        name: pointName,
        series: { name: seriesName }
      } = event.point;
      if (pointName) {
        setChartPointData({ seriesName, pointId: pointName, pointX, pointY });
      } else {
        setChartPointData({ seriesName, pointId, pointX, pointY });
      }

      if (notes.length > 0) {
        const note = notes.find(
          note =>
            note.pointId === pointId ||
            (note.pointX === pointX && note.pointY === pointY && note.seriesName === seriesName)
        );
        if (note) {
          setNoteId(note.id);
          setNote(note.note);
          setAuthorName(note.userName);
          setNoteDate(note.noteDate);
          setModificationAuthor(note.userName2);
          setEditDate(note.editDate);
        }
      }
      setIsDialogVisible(displayNotesList);
    },
    [notes, displayNotesList]
  );

  const showToast = useCallback(
    (message, error) =>
      toastRef.current.show({
        severity: error ? "error" : "success",
        summary: error ? "Wystąpił błąd" : "Sukces",
        detail: message
      }),
    []
  );

  return (
    <NoteContext.Provider
      value={{
        isDialogVisible,
        note,
        noteStore,
        chartPointData,
        noteId,
        userName,
        authorName,
        modificationAuthor,
        chartName,
        date,
        noteDate,
        editDate,
        deviceId,
        chartResolution,
        setIsDialogVisible,
        setNote,
        setChartPointData,
        setNoteId,
        setUserName,
        setAuthorName,
        setModificationAuthor,
        setDate,
        setNoteDate,
        setEditDate,
        handleEventPointClick,
        showToast
      }}
    >
      <Toast ref={toastRef} />
      {children}
    </NoteContext.Provider>
  );
});

export const useNoteContext = () => React.useContext(NoteContext);
