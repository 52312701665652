import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DateFilter } from "../../../../components/DateFilter/DateFilter";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { getHorizontalBarChartOptions } from "../../../../constants/defaultChartOptions";
import { getTelemetryData } from "../../../../api/clientApi";
import { Toast } from "primereact/toast";
import { getSelectedDevices } from "../../../../utils/multipleDevicesUtils";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";

export const PowerDemand = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedClientsDevices, clientsDevicesNodes } = useClientStore();
  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const [sinceDate, setSinceDate] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]));
  const [toDate, setToDate] = useState(new Date());
  const [devicesDataArray, setDevicesDataArray] = useState([]);
  const [devicesNameArray, setDevicesNameArray] = useState([]);
  const [devicesContractPowerArray, setDevicesContractPowerArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const resolution = TIME_CONSTANTS.HOUR_1;

  const clearDateFilters = useCallback(() => {
    setSinceDate("");
    setToDate("");
  }, []);

  useEffect(() => {
    getPowerDemandData();
  }, [selectedClientsDevices.ids, sinceDate, toDate]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const getPowerDemandData = useCallback(async id => {
    if (!selectedDevices?.length) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
      return;
    }
    setIsLoading(true);
    const promisesDataArray = selectedDevices.map(async devices => {
      const { data } = await getTelemetryData({
        devices: devices.deviceId,
        begin: parseDateToApiFormat(sinceDate),
        end: parseDateToApiFormat(toDate),
        resolution: resolution,
        avm_active_energy_total_sum: 1
      });
      return { value: data };
    });
    const chartNamesArray = selectedDevices.map(devices => devices.nameElements.join(" - "));
    const devicesContratPowerArray = selectedDevices.map(devices => devices.contractPower * 1000);
    const devicesArray = await Promise.all(promisesDataArray);

    const dataArray = devicesArray.map(devicesData => {
      if (devicesData?.value[0]?.data !== undefined) {
        const summDevicesDataArray = devicesData.value[0].data.map(element => {
          return element[1];
        });
        if (summDevicesDataArray.length > 0) {
          const averageDevicesDataArray = Math.round(
            summDevicesDataArray.reduce((prev, curr) => prev + curr) / summDevicesDataArray.length
          );
          return averageDevicesDataArray;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    });
    setDevicesContractPowerArray(devicesContratPowerArray);
    setDevicesNameArray(chartNamesArray);
    setDevicesDataArray(dataArray);
    setIsLoading(false);
  });

  const getPowerDemandChartData = useCallback(() => {
    const dataObject = {
      title: "Zapotrzebowanie na moc i moc umowna ",
      yAxisLabel: "Moc [ W ]",
      yAxisUnit: "W",
      categories: devicesNameArray,
      dataLabelsEnabled: true,
      data: [
        {
          name: "Średnia moc",
          data: devicesDataArray
        },
        {
          name: "Moc umowna",
          data: devicesContractPowerArray
        }
      ]
    };

    return getHorizontalBarChartOptions(dataObject);
  });

  const chartData = useMemo(() => getPowerDemandChartData(), [devicesDataArray, sinceDate, toDate]);

  return (
    <Grid>
      <Card>
        <Toast ref={toastRef} />
        <DateFilter
          sinceDate={sinceDate}
          setSinceDate={setSinceDate}
          toDate={toDate}
          setToDate={setToDate}
          clearDateFilters={clearDateFilters}
          dateOnly
        />
      </Card>
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
