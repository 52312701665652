import { observable, makeObservable } from "mobx";
import { checkCredentians } from "../api/api";
import { setCredentialsToAxiosClient } from "../api/apiClient";

export class AuthStore {
  auth = {
    isUserAuthenticated: false
  };

  user = {
    email: undefined,
    username: undefined,
    password: undefined
  };

  constructor() {
    makeObservable(this, {
      auth: observable,
      user: observable
    });
  }

  logIn = async (username, password) => {
    const areCredentialsCorrect = await checkCredentians(username, password);

    if (!areCredentialsCorrect) {
      return false;
    }

    setCredentialsToAxiosClient(username, password);
    this.auth.isUserAuthenticated = true;
    this.user = { username, password };
    return true;
  };

  logOut = () => {
    this.auth.isUserAuthenticated = false;
  };
}
