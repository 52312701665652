export const BUILDINGS_TYPES_NAMES = Object.freeze({
  EDUCATION: "Edukacja",
  SERVICES: "Usługi",
  SPORT: "Sport",
  APARTMENT_BUILDINGS: "Bloki",
  HOUSES: "Domy",
  TRADE: "Handel",
  BUSINESS: "Biznes",
  OTHER: "Inne"
});

export const BUILDINGS_TYPES = Object.freeze({
  EDUCATION: ["b01", "b02"],
  SERVICES: ["b03", "b05"],
  SPORT: ["b04"],
  APARTMENT_BUILDINGS: ["b07"],
  HOUSES: ["b06"],
  TRADE: ["b08", "b09"],
  BUSINESS: ["b10", "b11", "b12"],
  OTHER: ["b13", "u01", "u02", "c01", "c02", "c03"]
});

export const mergeSameBuildingTypeData = data => {
  const newData = Object.values(BUILDINGS_TYPES_NAMES).map(type => ({
    name: type,
    data: []
  }));

  data.forEach(device => {
    const buildingType = getBuildingTypeForCode(device.buildingType);
    newData.find(type => type.name === buildingType).data.push(device.data);
  });
  return newData;
};

const getBuildingTypeForCode = code => {
  if (code) {
    return BUILDINGS_TYPES_NAMES[Object.keys(BUILDINGS_TYPES).find(key => BUILDINGS_TYPES[key].includes(code))];
  }
  return BUILDINGS_TYPES_NAMES.OTHER;
};

export const BUILDING_TYPES_CATEGORIES = Object.values(BUILDINGS_TYPES_NAMES);
