import { observable, makeObservable, runInAction, action } from "mobx";
import { getDevicesNodes } from "../api/clientApi";

export class ClientStore {
  selectedClients = {
    ids: {}
  };
  selectedDevices = {
    id: {},
    osdId: "",
    branchId: "",
    contractualCapacity: ""
  };
  selectedClientsDevices = {
    ids: {}
  };
  devicesNodes = [];
  clientsNodes = [];
  clientsDevicesNodes = [];
  data = {};
  isPhotovoltaicConnected = false;

  constructor() {
    makeObservable(this, {
      selectedClients: observable,
      selectedDevices: observable,
      clientsNodes: observable,
      devicesNodes: observable,
      selectedClientsDevices: observable,
      clientsDevicesNodes: observable,
      data: observable,
      selectDevice: action.bound,
      selectClientsDevices: action.bound,
      selectAllDevices: action.bound
    });
    this.selectAllLocations();
  }

  selectClients = clients => {
    this.selectedClients.ids = clients;
  };

  selectClientsDevices = clients => {
    this.selectedClientsDevices.ids = clients;
  };

  selectDevice = id => {
    let selectedLocation = [];

    this.devicesNodes?.customers.forEach(customer =>
      customer?.areas?.forEach(area =>
        area?.locations?.forEach(location =>
          location?.installations?.forEach(installation =>
            installation?.devices?.forEach(device => {
              device?.extensions?.forEach(extension => {
                if (extension.id === id) {
                  selectedLocation = location;
                }
              });
            })
          )
        )
      )
    );

    const { osd, osd_branch, contractual_capacity } = selectedLocation;

    const device = {
      id,
      osdId: osd,
      branchId: osd_branch,
      contractualCapacity: contractual_capacity ? contractual_capacity : undefined
    };

    this.selectedDevices = device;
  };

  selectAllLocations = async () => {
    const { data } = await getDevicesNodes();
    const selectAll = {};
    data?.customers?.forEach(customer => {
      selectAll[customer.id] = { checked: true, partialChecked: false };
      customer.areas.forEach(area => {
        selectAll[area.id] = { checked: true, partialChecked: false };
        area.locations.forEach(location => {
          selectAll[location.id] = { checked: true, partialChecked: false };
        });
      });
    });
    this.selectClients(selectAll);
  };

  selectAllDevices = async () => {
    const { data } = await getDevicesNodes();

    const selectAll = {};
    data?.customers?.forEach(customer => {
      selectAll[customer.id] = { checked: true, partialChecked: false };
      customer.areas?.forEach(area => {
        selectAll[`${customer.id}-${area.id}`] = { checked: true, partialChecked: false };
        area.locations?.forEach(location => {
          selectAll[`${customer.id}-${area.id}-${location.id}`] = { checked: true, partialChecked: false };
          location.installations?.forEach(installation => {
            selectAll[`${customer.id}-${area.id}-${location.id}-${installation.id}`] = {
              checked: true,
              partialChecked: false
            };
            installation.devices?.forEach(device => {
              selectAll[`${customer.id}-${area.id}-${location.id}-${installation.id}-${device.id}`] = {
                checked: true,
                partialChecked: false
              };
              device.extensions?.forEach(extension => {
                selectAll[`${customer.id}-${area.id}-${location.id}-${installation.id}-${device.id}-${extension.id}`] =
                  {
                    checked: true,
                    partialChecked: false
                  };
              });
            });
          });
        });
      });
    });

    this.selectClientsDevices(selectAll);
  };

  fetchDevicesNodes = async () => {
    const { data } = await getDevicesNodes();
    this.devicesNodes = data;
    runInAction(() => {
      const clientsDevices = data.customers.map(({ id: customerId, customer, areas }) => ({
        key: `${customerId}`,
        label: customer,
        selectable: true,
        children: areas.map(({ id: areaId, area, locations }) => ({
          key: `${customerId}-${areaId}`,
          label: area,
          selectable: true,
          children:
            locations &&
            locations.map(
              ({
                id: locationId,
                location,
                installations,
                contractual_capacity,
                osd,
                osd_branch,
                building_area,
                type
              }) => ({
                contractPower: contractual_capacity,
                osdId: osd,
                branchId: osd_branch,
                buildingArea: building_area,
                buildingType: type,
                key: `${customerId}-${areaId}-${locationId}`,
                label: location,
                selectable: true,
                children:
                  installations &&
                  installations.map(({ id: installationId, installation, devices }) => ({
                    key: `${customerId}-${areaId}-${locationId}-${installationId}`,
                    label: installation,
                    selectable: true,
                    children:
                      devices &&
                      devices.map(({ id: deviceId, device, extensions }) => ({
                        key: `${customerId}-${areaId}-${locationId}-${installationId}-${deviceId}`,
                        label: device,
                        selectable: true,
                        children:
                          extensions &&
                          extensions.map(({ id: extensionId, device: extension }) => ({
                            key: `${customerId}-${areaId}-${locationId}-${installationId}-${deviceId}-${extensionId}`,
                            label: extension,
                            selectable: true
                          }))
                      }))
                  }))
              })
            )
        }))
      }));
      this.clientsDevicesNodes = clientsDevices;

      // const devices = data.customers.map(({ id, customer, areas }) => ({
      //   key: id,
      //   label: customer,
      //   selectable: false,
      //   children: areas.map(({ id, area, locations }) => ({
      //     key: id,
      //     label: area,
      //     selectable: false,
      //     children:
      //       locations &&
      //       locations.map(({ id, location, installations }) => ({
      //         key: id,
      //         label: location,
      //         selectable: false,
      //         children:
      //           installations &&
      //           installations.map(({ id, installation, devices }) => ({
      //             key: id,
      //             label: installation,
      //             selectable: false,
      //             children:
      //               devices &&
      //               devices.map(({ id, device }) => ({
      //                 key: id,
      //                 label: device,
      //                 selectable: true
      //               }))
      //           }))
      //       }))
      //   }))
      // }));
      // this.devicesNodes = devices;

      const clients = data.customers.map(({ id, customer, areas }) => ({
        key: id,
        label: customer,
        selectable: true,
        children: areas.map(({ id, area, locations }) => ({
          key: id,
          label: area,
          selectable: true,
          children:
            locations &&
            locations.map(({ id, location }) => ({
              key: id,
              label: location,
              selectable: true,
              checked: true
            }))
        }))
      }));
      this.clientsNodes = clients;
    });
    this.data = data;
  };
}
