import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { observer } from "mobx-react";
import Highcharts from "highcharts";
import { Toast } from "primereact/toast";
import { useLocation } from "react-router-dom";
import { Card } from "../../components/UI/Card/Card";
import { Chart } from "../../components/UI/Chart/Chart";
import { Grid } from "../../components/UI/Grid";
import { DateFilterSection } from "../../components/DateFilterSection/DateFilterSection";
import { phasesRadioButtons } from "../../constants/radioButtons";
import { RadioButtonsSection } from "../../components/RadioButtonsSection/RadioButtonsSection";

import { getTelemetryData } from "../../api/clientApi";
import { useClientStore } from "../../common/hooks/storeHooks";
import {
  getSplineChartOptions,
  getColumnWithNegativeValues,
  getMixedSingleAxisChartOptions
} from "../../constants/defaultChartOptions";
import { CHART_TYPES, resolutionOptions, RESOLUTIONS_NUMBERS } from "../../constants/chartOptions";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../constants/timeValues";
import { parseDateToApiFormat } from "../../utils/apiUtils";
import { calculateNumberOfPoints } from "../../utils/chartUtils";
import { parseDate } from "../../utils/helpers";
import { OverviewBox } from "../../components/OverviewBox/OverviewBox";
import { colors } from "../../styles/colors";
import { showToast } from "../../utils/toast";
import { TOAST_MESSAGE_TYPE } from "../../constants/toastText";
import { isNotNullish } from "../../utils/helpers";

import "./Profile.scss";
import "../../../src/styles/globalStyles.scss";

import { calculateDistributionCost, getSummaryCost, getTotalCost } from "../../mocks/costs";
import { getOsd } from "../../api/api";

const DATA_SERIES = [
  { label: "Energia czynna całkowita", dataName: "active_energy_total", unit: "W" },
  { label: "Energia bierna całkowita", dataName: "reactive_energy_total", unit: "var · h" }
];

const initialCardElements = [
  { number: 0, text: "Ilość kWh" },
  { number: 0, text: "Koszt PLN netto" }
];

const energyPhaseApiParameterNameAddition = Object.freeze({
  sum: "_sum",
  phase1: "_1",
  phase2: "_2",
  phase3: "_3"
});

const initialChartData = {
  title: "Profile",
  yAxisLabel: "Energia",
  yAxisUnit: "Wh",
  crosshairs: true,
  tooltipShared: true,
  plots: []
};

export const Profile = observer(() => {
  const toastRef = useRef(null);
  const { selectedDevices, selectDevice } = useClientStore();
  const { branchId, osdId, contractualCapacity } = selectedDevices;

  const location = useLocation();

  const [resolution, setResolution] = useState(TIME_CONSTANTS.MIN_15);
  const [sinceDate, setSinceDate] = useState(
    location.state
      ? location.state.useLocationSinceDate
      : new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1])
  );
  const [toDate, setToDate] = useState(location.state ? location.state.useLocationToDate : new Date());
  const [numberOfPoints, setNumberOfPoints] = useState();
  const [chartData, setChartData] = useState(getSplineChartOptions(initialChartData));
  const [chartType, setChartType] = useState("spline");
  const [telemetryData, setTelemetryData] = useState();
  const [cardElements, setCardElements] = useState(initialCardElements);
  const [isLoading, setIsLoading] = useState(false);
  const [phaseButtonCheckedId, setPhaseButtonCheckedId] = useState(phasesRadioButtons[0].id);
  const [osdInfo, setOsdInfo] = useState();

  useEffect(() => {
    if (!isNaN(selectedDevices.id)) {
      calculateNumberOfPoints(toDate, sinceDate, resolution, selectedDevices, displayToast, setNumberOfPoints);
    }
    if (numberOfPoints) {
      if (branchId === undefined || osdId === null) {
        setOsdInfo(undefined);
        displayToast(
          "Brak szczegółowych informacji o pliszce! Zakładka Koszty Energii została wyłączona",
          TOAST_MESSAGE_TYPE.WARN
        );
      } else {
        fetchOsdInfo("2021", branchId, osdId);
      }
      getEnergyProfilesData(selectedDevices.id);
    }
  }, [selectedDevices, numberOfPoints, phaseButtonCheckedId]);

  useEffect(() => {
    if (!isNaN(selectedDevices.id) && isNotNullish(location.state)) {
      if (isNotNullish(location.state.useLocationPoint.installations[0].devices)) {
        selectDevice(location.state.useLocationPoint.installations[0].devices[0].id);
      } else if (isNotNullish(location.state.useLocationPoint.installations.length)) {
        displayToast("Brak urządzeń dla danej lokalizacji.");
        selectDevice({});
      } else {
        displayToast("Brak informacji o urządzeniach dla danej lokalizacji.", TOAST_MESSAGE_TYPE.WARN);
      }
    }
  }, []);

  useEffect(() => {
    getEnergyProfilesChartData();
    calculateActiveEnergyOverviewElements();
  }, [telemetryData, chartType]);

  const fetchOsdInfo = useCallback(async (year, branchId, rateId) => {
    try {
      const osd = await getOsd({ year, branchId, rateId });
      setOsdInfo(osd);
    } catch (error) {
      displayToast("Brak informacji o osd!", TOAST_MESSAGE_TYPE.WARN);
    }
  }, []);

  const getEnergyProfilesData = useCallback(
    async id => {
      setIsLoading(true);
      const timeDifference = Math.abs(toDate - sinceDate);
      const ifWrongResolution = timeDifference < RESOLUTIONS_NUMBERS[resolution];

      const requestData = {
        devices: id,
        begin: ifWrongResolution
          ? new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1])
          : parseDateToApiFormat(sinceDate),
        end: ifWrongResolution ? new Date() : parseDateToApiFormat(toDate),
        resolution: ifWrongResolution ? TIME_CONSTANTS.MIN_15 : resolution
      };

      requestData[`avm_active_energy_total${energyPhaseApiParameterNameAddition[phaseButtonCheckedId]}`] = 1;
      requestData[`avm_reactive_energy_total${energyPhaseApiParameterNameAddition[phaseButtonCheckedId]}`] = 1;

      const { data } = await getTelemetryData(requestData);

      if (ifWrongResolution) {
        displayToast("Wybierz mniejszą rozdzielczość lub zwiększ zakres czasowy");
        setSinceDate(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]));
        setToDate(new Date());
        setResolution(TIME_CONSTANTS.MIN_15);
      }

      if (!data || data.some(dataPart => !dataPart.data?.length)) {
        displayToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości");
      }

      setTelemetryData(data);
      setIsLoading(false);
    },
    [sinceDate, toDate, resolution, showToast, phaseButtonCheckedId]
  );

  const getEnergyProfilesChartData = useCallback(() => {
    if (chartType === CHART_TYPES.COLUMN) {
      const dataObject = {
        title: "Profile",
        yAxisLabel: "Energia",
        yAxisUnit: "Wh",
        crosshairs: true,
        tooltipShared: true,
        pointInterval: RESOLUTIONS_NUMBERS[resolution],
        startTime: parseDate(sinceDate),
        events: {
          redraw: calculateActiveEnergyOverviewElements
        },
        plots: DATA_SERIES.map(serie => ({
          name: serie.label,
          data: telemetryData?.find(item => item.dataType.includes(serie.dataName))?.data
        }))
      };
      setChartData(getColumnWithNegativeValues(dataObject));
    } else {
      const dataObject = {
        title: "Profile",
        pointInterval: RESOLUTIONS_NUMBERS[resolution],
        multipleAxes: [
          {
            labels: {
              format: `{value} W`,
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            title: {
              text: "Energia czynna całkowita",
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            }
          },
          {
            labels: {
              format: `{value} var · h`,
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: "Energia bierna całkowita",
              color: Highcharts.getOptions().colors[1]
            },
            opposite: true
          }
        ],
        colors: Highcharts.getOptions().colors,
        series: DATA_SERIES.map((serie, i) => ({
          name: serie.label,
          yAxis: i,
          data: telemetryData?.find(item => item.dataType.includes(serie.dataName))?.data,
          unit: " " + serie.unit
        }))
      };
      setChartData(getMixedSingleAxisChartOptions(dataObject));
    }
  }, [telemetryData, sinceDate, resolution, chartType]);

  const calculateActiveEnergyOverviewElements = useCallback(() => {
    if (telemetryData !== undefined && telemetryData?.some(({ data }) => data.length)) {
      const activeEnergyTotal = telemetryData[0].data;
      const totalConsumption = activeEnergyTotal.reduce((prev, current) => prev + current[1], 0);
      const totalTime = RESOLUTIONS_NUMBERS[resolution] / RESOLUTIONS_NUMBERS[TIME_CONSTANTS.HOUR_1];
      const totalConsumptionInKh = Math.round(((totalConsumption * totalTime) / 1000) * 100) / 100;
      const distributionCosts = getSummaryCost(
        calculateDistributionCost(telemetryData, osdInfo?.data?.response?.[0], resolution, contractualCapacity)
      );
      const energyCosts = getTotalCost(totalConsumptionInKh);
      const totalCost = Math.round((distributionCosts + energyCosts) * 100) / 100;

      setCardElements([
        { number: totalConsumptionInKh, text: "Ilość kWh" },
        { number: totalCost, text: "Koszt PLN netto" }
      ]);
    }
  }, [telemetryData, sinceDate, resolution, contractualCapacity]);

  const masterDetailChartData = useMemo(() => telemetryData?.map(dataPart => dataPart.data), [telemetryData]);

  const clearDateFilters = () => {
    setSinceDate();
    setToDate();
  };

  const displayToast = useCallback((message, type) => showToast(toastRef, message, type), [toastRef, showToast]);

  const onConfirm = useCallback(() => {
    calculateNumberOfPoints(toDate, sinceDate, resolution, selectedDevices, displayToast, setNumberOfPoints);
  }, [toDate, sinceDate, resolution, displayToast, setNumberOfPoints, selectedDevices]);

  return (
    <Grid>
      <Toast ref={toastRef} />
      <DateFilterSection
        sinceDate={sinceDate}
        setSinceDate={setSinceDate}
        toDate={toDate}
        setToDate={setToDate}
        clearDateFilters={clearDateFilters}
        resolution={resolution}
        setResolution={setResolution}
        resolutionOptions={resolutionOptions}
        onConfirm={onConfirm}
        flexGrow={8}
      />
      <OverviewBox
        title="Energia Czynna"
        elements={cardElements}
        icon="pi-dollar"
        color={colors.lightBlue}
        iconColor={colors.darkBlue}
        flexGrow={4}
      />
      <div className="header-toolbar-container">
        <RadioButtonsSection
          title={"Faza"}
          flexGrow={4}
          setButtonChecked={setPhaseButtonCheckedId}
          radioButtons={phasesRadioButtons}
          value={phaseButtonCheckedId}
          className="radio-button-section-container"
        />

        <RadioButtonsSection
          className="radio-button-section-container"
          setButtonChecked={setChartType}
          radioButtons={[
            { id: "spline", label: "Liniowy" },
            { id: "column", label: "Słupkowy" }
          ]}
          onRadioButtonPress={() => {}}
          value={chartType}
          title="Typ wykresu"
          flexGrow="wrap"
        />
      </div>

      <Card>
        <Chart
          options={chartData}
          constructorType={chartType === "spline" ? "stockChart" : null}
          displayToast={displayToast}
          masterDetailChartData={null}
          isLoading={isLoading}
          chartName={"Profile"}
          deviceId={typeof selectedDevices.id === "number" ? selectedDevices.id : null}
          chartResolution={resolution}
          displayNotesList
        />
      </Card>
    </Grid>
  );
});
