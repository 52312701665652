import { observer } from "mobx-react";
import moment from "moment";
import { Toast } from "primereact/toast";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { getPowerDipsChartData } from "../../../../mocks/powerDips";
import "../../../../styles/globalStyles.scss";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { mergeSameBuildingTypeData } from "../../../../utils/buildingTypesUtils";
import {
  splitDataIntoAfternoonsAndNights,
  splitDataIntoMidDays,
  splitDataIntoWeekends
} from "../../../../utils/dataAggreagationUtils";
import { getRangeStartDate } from "../../../../utils/helpers";
import { getSelectedDevices } from "../../../../utils/multipleDevicesUtils";

const MINIMUM_VOLTAGE_VALUE = 225;
const MAXIMUM_VOLTAGE_VALUE = 235;

export const PowerDips = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedClientsDevices, clientsDevicesNodes } = useClientStore();
  const [buttonChecked, setButtonChecked] = useState(standardTimeRadioButtons[0].id);
  const [startTime, setStartTime] = useState(
    new Date(moment(new Date()).subtract(1, "day").startOf("day").subtract(8, "hours"))
  );
  const [endTime, setEndTime] = useState(new Date(moment(new Date()).subtract(1, "day").endOf("day")));
  const [resolution, setResolution] = useState(TIME_CONSTANTS.MIN_15);
  const [powerDipsData, setPowerDipsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPowerDipsData();
  }, [selectedClientsDevices.ids, buttonChecked]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const getPowerDipsData = useCallback(async () => {
    if (!selectedDevices?.length) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
      return;
    }
    setIsLoading(true);

    const selectedDevicesIds = selectedDevices?.map(device => device.deviceId);

    const { data: devicesArray } = await getTelemetryData({
      devices: selectedDevicesIds.join(","),
      begin: parseDateToApiFormat(startTime),
      end: parseDateToApiFormat(endTime),
      resolution: resolution,
      avm_voltage_avg_1: 1
    });

    const dataWithBuildingTypes = devicesArray.map(element => {
      const buildingType = selectedDevices.find(device => Number(device.deviceId) === element.device_id).buildingType;

      return {
        id: element.device_id,
        buildingType,
        data: element.data
      };
    });

    const buildingTypeMergedData = mergeSameBuildingTypeData(dataWithBuildingTypes);

    const buildingTypesSplitData = buildingTypeMergedData.map(type => ({
      ...type,
      midDaysData: type?.data?.map(splitDataIntoMidDays).flat().flat(),
      afternoonsAndNightsData: type?.data?.map(splitDataIntoAfternoonsAndNights).flat().flat(),
      weekendsData: type?.data?.map(splitDataIntoWeekends).flat().flat()
    }));

    const getPowerDipTime = (data = []) => {
      const filteredData = data?.filter(
        element => element[1] < MINIMUM_VOLTAGE_VALUE || element[1] > MAXIMUM_VOLTAGE_VALUE
      );
      return moment.duration(filteredData?.length * TIME_CONSTANTS_VALUES[resolution]).asMinutes();
    };

    const finalValues = {
      midDays: buildingTypesSplitData.map(type => getPowerDipTime(type.midDaysData)),
      afternoonfAndNights: buildingTypesSplitData.map(type => getPowerDipTime(type.afternoonsAndNightsData)),
      weekends: buildingTypesSplitData.map(type => getPowerDipTime(type.weekendsData))
    };

    setPowerDipsData(finalValues);
    setIsLoading(false);
  }, [startTime, endTime, resolution, selectedDevices, showToast]);

  const chartData = useMemo(() => getPowerDipsChartData(powerDipsData), [powerDipsData]);

  const onRadioButtonPress = useCallback(value => {
    setButtonChecked(value);
    setStartTime(new Date(moment(getRangeStartDate(value)).subtract(8, "hours")));
    if (value === TIME_CONSTANTS.DAY_1) {
      setResolution(TIME_CONSTANTS.MIN_15);
    } else if (value === TIME_CONSTANTS.YEAR_1) {
      setResolution(TIME_CONSTANTS.HOUR_4);
    } else {
      setResolution(TIME_CONSTANTS.HOUR_1);
    }
  }, []);
  return (
    <Grid>
      <Toast ref={toastRef} />
      <div className="header-toolbar-container">
        <RadioButtonsSection
          setButtonChecked={onRadioButtonPress}
          radioButtons={standardTimeRadioButtons}
          value={buttonChecked}
          className="radio-button-section-container"
        />
      </div>
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
