export const getSelectedDevices = (ids, nodes) => {
  const idsArray = Object.keys(ids);
  const devicesKeys = idsArray.filter(id => countDashes(id) === 5);
  const splitedDevicesKeys = devicesKeys.map(key => key.split("-"));
  return getDevicesWithNames(splitedDevicesKeys, nodes);
};

const countDashes = string => {
  return string.match(/-/g)?.length;
};

export const getDevicesWithNames = (keys, nodes) => {
  return keys.map(ids => {
    const nameElements = [];
    const [customerKey, areaKey, locationKey, instalationKey, deviceKey, extensionKey] = ids;
    const selectedLocation = nodes
      ?.find(customer => customer.key === customerKey)
      ?.children?.find(area => area.key === `${customerKey}-${areaKey}`)
      ?.children?.find(location => location.key === `${customerKey}-${areaKey}-${locationKey}`);
    nameElements.push(selectedLocation?.label);
    const selectedInstalation = selectedLocation?.children?.find(
      instalation => instalation.key === `${customerKey}-${areaKey}-${locationKey}-${instalationKey}`
    );
    nameElements.push(selectedInstalation?.label);
    const selectedDevice = selectedInstalation?.children?.find(
      device => device.key === `${customerKey}-${areaKey}-${locationKey}-${instalationKey}-${deviceKey}`
    );
    nameElements.push(selectedDevice?.label);
    const selectedExtension = selectedDevice?.children?.find(
      extension =>
        extension.key === `${customerKey}-${areaKey}-${locationKey}-${instalationKey}-${deviceKey}-${extensionKey}`
    );
    nameElements.push(selectedExtension?.label);
    return {
      deviceId: extensionKey,
      locationId: locationKey,
      locationName: selectedLocation?.label,
      buildingType: selectedLocation?.buildingType,
      nameElements,
      contractPower: selectedLocation?.contractPower,
      osdId: selectedLocation?.osdId,
      branchId: selectedLocation?.branchId,
      buildingArea: selectedLocation?.buildingArea
    };
  });
};

export const mergeSameLocationData = data => {
  const mergedData = [];
  data.forEach(device => {
    if (device) {
      const filteredDevices = data.filter(element => element?.[0] === device[0] && device[1]);
      if (filteredDevices.length === 1) {
        mergedData.push(device);
      } else if (!mergedData.find(element => element?.[0] === device[0]) && device[1]) {
        const locationValue = Math.round(filteredDevices.reduce((summ, device) => summ + device[1], 0) * 100) / 100;
        mergedData.push([device[0], locationValue, device[2]]);
      }
    }
  });
  return mergedData;
};
