import { makeAutoObservable, runInAction } from "mobx";
import { createNote, getNotes, removeNote, updateNote } from "../api/api";

export class NoteStore {
  notes = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async addNote(
    { pointId, pointX, pointY, seriesName },
    note,
    userName,
    chartName,
    noteDate,
    deviceId,
    chartResolution
  ) {
    const { message, status } = await createNote({
      pointId,
      pointX,
      pointY,
      seriesName,
      note,
      userName,
      chartName,
      noteDate,
      deviceId,
      chartResolution
    });
    await this.fetchNotes(deviceId);
    return { status, message };
  }

  async editNote(id, notes, modificationAuthor, editDate, deviceId) {
    const note = this.notes.find(note => id === note.id);
    if (note) {
      const { message, status } = await updateNote({ ...note, note: notes, modificationAuthor, editDate });
      await this.fetchNotes(deviceId);
      return { status, message };
    }
    return { message: "error edit" };
  }

  async removeNote(id, deviceId) {
    const { message, status } = await removeNote({ id });

    await this.fetchNotes(deviceId);
    return { status, message };
  }

  async fetchNotes(deviceId) {
    this.isLoading = true;
    const { status, data, message } = await getNotes({ deviceId });
    runInAction(() => {
      if (status === 200) {
        this.isLoading = false;
        this.notes = data.data.response;
      } else {
        this.isLoading = false;
        return { message };
      }
    });
  }
}
