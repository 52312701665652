import { observer } from "mobx-react";
import moment from "moment";
import { Toast } from "primereact/toast";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import {
  nightsAndWeekendsRadioButtons,
  NIGHTS_AND_WEEKENDS,
  standardTimeRadioButtons
} from "../../../../constants/radioButtons";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { getHighestUsagesChartData } from "../../../../mocks/highestUsages";
import "../../../../styles/globalStyles.scss";
import {
  MIN_DATA_ELEMENTS_HOURLY_IN_DAY,
  MIN_DATA_ELEMENTS_HOURLY_IN_NIGHT,
  MIN_DATA_ELEMENTS_HOURLY_IN_WEEKEND,
  MIN_DATA_ELEMENTS_PER_4_HOURS_IN_DAY,
  MIN_DATA_ELEMENTS_PER_4_HOURS_IN_NIGHT,
  MIN_DATA_ELEMENTS_PER_4_HOURS_IN_WEEKEND,
  splitDataIntoDays,
  splitDataIntoNights,
  splitDataIntoWeekends
} from "../../../../utils/dataAggreagationUtils";
import { getRangeStartDate } from "../../../../utils/helpers";
import { getSelectedDevices, mergeSameLocationData } from "../../../../utils/multipleDevicesUtils";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { TOAST_MESSAGE_TYPE } from "../../../../constants/toastText";

export const HighestUsages = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedClientsDevices, clientsDevicesNodes } = useClientStore();

  const [timeButtonChecked, setTimeButtonChecked] = useState(standardTimeRadioButtons[0].id);
  const [nightsAndWeekendsButtonChecked, setNightsAndWeekendsButtonChecked] = useState(
    nightsAndWeekendsRadioButtons[0].id
  );
  const [startTime, setStartTime] = useState(new Date(moment(new Date()).subtract(1, "day").startOf("day")));
  const [endTime, setEndTime] = useState(new Date(moment(new Date()).subtract(1, "day").endOf("day")));
  const [resolution, setResolution] = useState(TIME_CONSTANTS.HOUR_1);
  const [usagesData, setUsagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const minDataElementsInDay = useMemo(
    () =>
      resolution === TIME_CONSTANTS.HOUR_1 ? MIN_DATA_ELEMENTS_HOURLY_IN_DAY : MIN_DATA_ELEMENTS_PER_4_HOURS_IN_DAY,
    [resolution]
  );
  const minDataElementsInNight = useMemo(
    () =>
      resolution === TIME_CONSTANTS.HOUR_1 ? MIN_DATA_ELEMENTS_HOURLY_IN_NIGHT : MIN_DATA_ELEMENTS_PER_4_HOURS_IN_NIGHT,
    [resolution]
  );
  const minDataElementsInWeekend = useMemo(
    () =>
      resolution === TIME_CONSTANTS.HOUR_1
        ? MIN_DATA_ELEMENTS_HOURLY_IN_WEEKEND
        : MIN_DATA_ELEMENTS_PER_4_HOURS_IN_WEEKEND,
    [resolution]
  );

  useEffect(() => {
    getHighestUsagesData();
  }, [selectedClientsDevices.ids, timeButtonChecked, nightsAndWeekendsButtonChecked]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const splitData = useCallback(
    data => {
      switch (nightsAndWeekendsButtonChecked) {
        case NIGHTS_AND_WEEKENDS.ALL:
          return splitDataIntoDays(data);
        case NIGHTS_AND_WEEKENDS.NIGHTS:
          return splitDataIntoNights(data);
        case NIGHTS_AND_WEEKENDS.WEEKENDS:
          return splitDataIntoWeekends(data);
        default:
          return splitDataIntoDays(data);
      }
    },
    [nightsAndWeekendsButtonChecked]
  );

  const minDataElements = useMemo(() => {
    switch (nightsAndWeekendsButtonChecked) {
      case NIGHTS_AND_WEEKENDS.ALL:
        return minDataElementsInDay;
      case NIGHTS_AND_WEEKENDS.NIGHTS:
        return minDataElementsInNight;
      case NIGHTS_AND_WEEKENDS.WEEKENDS:
        return minDataElementsInWeekend;
      default:
        return minDataElementsInDay;
    }
  }, [nightsAndWeekendsButtonChecked, minDataElementsInDay, minDataElementsInNight, minDataElementsInWeekend]);

  const getHighestUsagesData = useCallback(async () => {
    if (!selectedDevices?.length) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
      return;
    }
    setIsLoading(true);

    const selectedDevicesIds = selectedDevices?.map(device => device.deviceId);

    const { data: devicesArray } = await getTelemetryData({
      devices: selectedDevicesIds.join(","),
      begin: parseDateToApiFormat(startTime),
      end: parseDateToApiFormat(endTime),
      resolution: resolution,
      avm_active_energy_total_sum: 1
    });

    const splitPowerUsageData = devicesArray?.map(element => {
      const buildingArea = selectedDevices.find(device => Number(device.deviceId) === element.device_id).buildingArea;
      if (!buildingArea) {
        showToast("Brak szczegółowych informacji o pliszce!", "Ostrzeżenie", "warn");
        return {};
      }
      const name = selectedDevices.find(device => Number(device.deviceId) === element.device_id).locationName;
      const locationId = selectedDevices.find(device => Number(device.deviceId) === element.device_id).locationId;

      return {
        id: element.device_id,
        locationId,
        name,
        area: buildingArea,
        data: splitData(element.data)
      };
    });

    const powerUsageDataWithoutDaysWithFewData = splitPowerUsageData?.map(element => ({
      ...element,
      data: element?.data?.filter(array => array?.length >= minDataElements)
    }));

    const energyUsageData = powerUsageDataWithoutDaysWithFewData?.map(device => ({
      ...device,
      data: device?.data?.map(element =>
        element?.map(
          value => (value[1] * TIME_CONSTANTS_VALUES[resolution]) / TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]
        )
      )
    }));

    const summedEnergyUsageData = energyUsageData?.map(device => ({
      ...device,
      data: device?.data?.map(element =>
        element?.reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0)
      )
    }));

    const averageEnergyUsageData = summedEnergyUsageData?.map(element => ({
      ...element,
      value:
        element?.data?.reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0) / (element?.length || 1)
    }));

    const averageEnergyUsagePerMeterSquare = averageEnergyUsageData?.map(element => ({
      ...element,
      value: element.value / element.area
    }));

    const formattedAverageData = averageEnergyUsagePerMeterSquare?.map(element => ({
      ...element,
      value: Math.round((element.value / 1000) * 100) / 100
    }));

    const sortedAverageEnergyData = formattedAverageData
      ?.filter(element => {
        if (!element.value) {
          showToast("Brak danych dla wybranej pliszki i zakresu!", "Ostrzeżenie", "warn");
        }
        return element.value;
      })
      ?.sort((a, b) => b.value - a.value);

    const finalData = mergeSameLocationData(
      sortedAverageEnergyData?.map((element, index) => [element.name, element.value, element.area])
    );

    setUsagesData(finalData);
    setIsLoading(false);
  }, [startTime, endTime, resolution, selectedDevices, showToast, splitData, minDataElements]);

  const onTimeRadioButtonPress = useCallback(
    value => {
      setTimeButtonChecked(value);
      if (value === TIME_CONSTANTS.YEAR_1) {
        setResolution(TIME_CONSTANTS.HOUR_4);
      } else {
        setResolution(TIME_CONSTANTS.HOUR_1);
      }
      if (value === TIME_CONSTANTS.WEEK_1 && nightsAndWeekendsButtonChecked === NIGHTS_AND_WEEKENDS.WEEKENDS) {
        setStartTime(new Date(moment(getRangeStartDate(value)).subtract(4, "days")));
      } else if (value === TIME_CONSTANTS.DAY_1 && nightsAndWeekendsButtonChecked === NIGHTS_AND_WEEKENDS.NIGHTS) {
        setStartTime(new Date(moment(getRangeStartDate(value)).subtract(1, "day")));
      } else {
        setStartTime(getRangeStartDate(value));
      }
    },
    [nightsAndWeekendsButtonChecked]
  );

  const onNightAndWeekendsRadioButtonPress = useCallback(
    value => {
      setNightsAndWeekendsButtonChecked(value);
      if (value === NIGHTS_AND_WEEKENDS.WEEKENDS && timeButtonChecked === TIME_CONSTANTS.DAY_1) {
        setTimeButtonChecked(TIME_CONSTANTS.WEEK_1);
        setStartTime(new Date(moment(getRangeStartDate(TIME_CONSTANTS.WEEK_1)).subtract(4, "days")));
      }
      if (value === NIGHTS_AND_WEEKENDS.NIGHTS && timeButtonChecked === TIME_CONSTANTS.DAY_1) {
        setStartTime(new Date(moment(getRangeStartDate(TIME_CONSTANTS.DAY_1)).subtract(1, "day")));
      }
    },
    [timeButtonChecked]
  );

  const chartData = useMemo(() => getHighestUsagesChartData(usagesData), [usagesData]);

  return (
    <Grid>
      <Toast ref={toastRef} />
      <div className="header-toolbar-container">
        <RadioButtonsSection
          setButtonChecked={onTimeRadioButtonPress}
          radioButtons={standardTimeRadioButtons}
          value={timeButtonChecked}
          className="radio-button-section-container"
          disabledButtonId={
            nightsAndWeekendsButtonChecked === NIGHTS_AND_WEEKENDS.WEEKENDS ? TIME_CONSTANTS.DAY_1 : null
          }
        />
        <RadioButtonsSection
          title="Zakres"
          setButtonChecked={onNightAndWeekendsRadioButtonPress}
          radioButtons={nightsAndWeekendsRadioButtons}
          value={nightsAndWeekendsButtonChecked}
          className="radio-button-section-container"
        />
      </div>

      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
