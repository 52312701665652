import { TIME_CONSTANTS } from "../constants/timeValues";

export const buttonResolutionFilter = buttonChecked => {
  switch (buttonChecked) {
    case "day":
      return {
        resolution: TIME_CONSTANTS.MIN_15
      };
    case "week":
      return {
        resolution: TIME_CONSTANTS.MIN_15
      };
    case "month":
      return {
        resolution: TIME_CONSTANTS.HOUR_1
      };
    case "year":
      return {
        resolution: TIME_CONSTANTS.HOUR_4
      };
  }
};

export const buttonDateFilter = buttonChecked => {
  const date = new Date();
  const [dayOfWeek, month, day, year] = [date.getDay(), date.getMonth(), date.getDate(), date.getFullYear()];

  switch (buttonChecked) {
    case "day":
      return new Date(year, month, day);
    case "week":
      const diff = day - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      const d = new Date(date.setDate(diff));
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      return d;
    case "month":
      return new Date(year, month, "01");
    case "year":
      return new Date(year, "00", "01");
    default:
      return new Date(year, month, day);
  }
};
