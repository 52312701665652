import InfoWindow from "../InfoWindow/InfoWindow";
import { Link } from "react-router-dom";
import "./MapMarker.scss";
import classNames from "classnames";

const MapMarker = ({ point, infoWindowDown, infoWindowRight, infoWindowLeft, sinceDate, toDate }) => {
  const { active, type, showLabel } = point;

  const classMapMarkerContainer = classNames("map-marker-container", {
    active: active
  });

  const classMapMarketIcon = classNames("map-marker-icon", {
    "street-lamp": type === "lamp"
  });

  return (
    <>
      <Link
        to={{
          pathname: "/profile",
          state: {
            useLocationSinceDate: sinceDate,
            useLocationToDate: toDate,
            useLocationPoint: point
          }
        }}
      >
        <div className={classMapMarkerContainer}>
          <div className={classMapMarketIcon} />
        </div>

        {showLabel && (
          <InfoWindow
            point={point}
            infoWindowDown={infoWindowDown}
            infoWindowRight={infoWindowRight}
            infoWindowLeft={infoWindowLeft}
          />
        )}
      </Link>
    </>
  );
};

export default MapMarker;
