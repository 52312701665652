import { PrimeIcons } from "primereact/api";

const disableDevice = argArray => {
  for (let i = 0; i < argArray.length; i++) {
    const element = argArray[i];
    if (element === null) {
      // return { icon: PrimeIcons.LOCK, style: { color: "red", pointerEvents: "none" } };
      return { icon: PrimeIcons.EXCLAMATION_TRIANGLE, style: { color: "DarkOrange" } };
    }
  }
};

export const mapDevices = data =>
  data?.customers?.map(({ id, customer, areas }) => ({
    key: id + customer,
    label: customer,
    selectable: false,
    children: areas.map(({ id, area, locations }) => ({
      key: id + area,
      label: area,
      selectable: false,
      children:
        locations &&
        locations.map(
          ({
            id,
            location,
            installations,
            contractual_capacity,
            description,
            latitude,
            longitude,
            osd,
            osd_branch,
            electricity_supplier
          }) => ({
            key: id + location,
            label: location,
            contractual_capacity: contractual_capacity,
            description: description,
            latitude: latitude,
            longitude: longitude,
            osd: osd,
            osd_branch: osd_branch,
            electricity_supplier: electricity_supplier,
            selectable: false,
            children:
              installations &&
              installations.map(({ id, installation, devices }) => ({
                key: id + installation,
                label: installation,
                selectable: false,
                children:
                  devices &&
                  devices.map(({ id, device, extensions }) => ({
                    key: id + device,
                    label: device,
                    selectable: false,
                    children:
                      extensions &&
                      extensions.map(({ id, device: extension }) => ({
                        key: id,
                        label: extension,
                        selectable: true,
                        ...disableDevice([osd_branch, osd, contractual_capacity, id, extension])
                      }))
                  }))
              }))
          })
        )
    }))
  }));
