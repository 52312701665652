import Highcharts from "highcharts";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { ComparisonFilterSection } from "../../../../components/ComparisonFilterSection/ComparisonFilterSection";
import { Grid } from "../../../../components/UI/Grid";
import { filterResolutionsOptions, parseDate } from "../../../../utils/helpers";
import { resolutionOptions, RESOLUTIONS_NUMBERS } from "../../../../constants/chartOptions";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { getSplineChartOptions } from "../../../../constants/defaultChartOptions";
import { getTelemetryData } from "../../../../api/clientApi";
import { calculateNumberOfPoints } from "../../../../utils/chartUtils";
import { useClientStore } from "../../../../common/hooks/storeHooks";

export const CompareActiveEnergy = observer(() => {
  const toastRef = React.useRef(null);

  const [numberOfDataPoints, setNumberOfDataPoints] = useState(0);
  const [resolution, setResolution] = useState(TIME_CONSTANTS.SEC_10);
  const [sinceHour, setSinceHour] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1]));
  const [toHour, setToHour] = useState(new Date());
  const [dates, setDates] = useState([{ id: "Dzień 1", value: new Date() }]);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedDevices } = useClientStore();

  const [telemetryData, setTelemetryData] = useState([
    {
      device_id: 0,
      dateType: "",
      name: "",
      dashStyle: "",
      yAxis: "",
      data: [],
      tooltip: ""
    }
  ]);

  useEffect(() => {
    if (!isNaN(selectedDevices.id) && numberOfDataPoints !== 0) {
      getActiveEnergyDetails(selectedDevices.id, sinceHour, toHour, resolution, dates);
    }
  }, [selectedDevices.id, numberOfDataPoints]);

  useEffect(() => {
    calculateNumberOfPoints(toHour, sinceHour, resolution, showToast, setNumberOfDataPoints);
    if (isNaN(selectedDevices.id)) showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    //eslint-disable-next-line
  }, []);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const stitchDates = (wantedDate, wantedTime) => {
    const date = moment(wantedDate).format("YYYY-MM-DD");
    const time = moment(wantedTime).format("HH:mm:ss");
    return `${date}` + ` ` + `${time}`;
  };

  const getActiveEnergyDetails = useCallback(
    async (id, sinceHour, toHour, resolution, dates) => {
      setIsLoading(true);

      const newTelemetryDataPromises = dates.map(async date => {
        const { data } = await getTelemetryData({
          devices: id,
          begin: stitchDates(date.value, sinceHour),
          end: stitchDates(date.value, toHour),
          resolution,
          avm_active_energy_total_sum: 1
        });

        if (!data[0]?.data || data[0]?.data?.length === 0) {
          showToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości", "Informacja", "info");
          return null;
        }
        return data[0];
      });

      const newTelemetryData = await Promise.all(newTelemetryDataPromises);
      if (newTelemetryData?.some(dataPart => dataPart === null)) {
        setTelemetryData([
          {
            device_id: 0,
            dateType: "",
            name: "",
            dashStyle: "",
            yAxis: "",
            data: [],
            tooltip: ""
          }
        ]);
      } else {
        setTelemetryData(newTelemetryData);
      }

      setIsLoading(false);
    },
    [showToast]
  );

  const getActiveEnergyCompareChartData = useCallback(
    (resolution, startingPoint, dates) => {
      const dataObject = {
        title: "Porównanie profili energii czynnej dla wybranych dni",
        yAxisLabel: "Energia czynna",
        yAxisUnit: "Wh",
        pointInterval: resolution,
        startTime: startingPoint,
        zoomType: "x",
        tooltipShared: true,
        tooltipTimeOnly: true,
        xAxisFormatter: function () {
          return Highcharts.dateFormat("%H:%M", this.value);
        },
        plots: telemetryData.map((tData, idx) => {
          const element = tData.data;
          const tempArr = [];
          element.map(insideData => {
            tempArr.push(insideData[1]);
          });
          return {
            name: moment(dates[idx].value).format("DD/MM/YYYY"),
            data: tempArr
          };
        })
      };
      return getSplineChartOptions(dataObject);
    },
    [telemetryData, getActiveEnergyDetails]
  );

  const onConfirm = useCallback(() => {
    calculateNumberOfPoints(
      toHour,
      sinceHour,
      resolution,
      selectedDevices,
      showToast,
      setNumberOfDataPoints,
      true,
      () => getActiveEnergyDetails(selectedDevices.id, sinceHour, toHour, resolution, dates)
    );
  });

  const chartData = useMemo(
    () => getActiveEnergyCompareChartData(RESOLUTIONS_NUMBERS[resolution], parseDate(sinceHour), dates),
    [telemetryData, getActiveEnergyCompareChartData]
  );
  return (
    <Grid>
      <Toast ref={toastRef} />
      <ComparisonFilterSection
        dates={dates}
        setDates={setDates}
        sinceHour={sinceHour}
        setSinceHour={setSinceHour}
        toHour={toHour}
        setToHour={setToHour}
        resolution={resolution}
        setResolution={setResolution}
        resolutionOptions={filterResolutionsOptions(toHour, sinceHour, resolutionOptions, resolution, setResolution)}
        onConfirm={onConfirm}
      />
      <Card>
        <Chart
          options={chartData}
          deviceId={typeof selectedDevices.id === "number" ? selectedDevices.id : null}
          chartResolution={resolution}
          isLoading={isLoading}
          chartName={"CompareActiveEnergy"}
          displayNotesList
        />
      </Card>
    </Grid>
  );
});
