export const CLIENTS_PATHS = Object.freeze([
  "/usagePerFixture",
  "/pvProductionPerLocalization",
  "/pvProductionEfficiency",
  "/map"
]);

export const DEVICES_PATHS = Object.freeze([
  "/lastNight",
  "/activeAndPassiveEnergy",
  "/activeEnergyDetails",
  "/pvProductionPerDate",
  "/osdBalance",
  "/pvProductionDetails",
  "/distributionCosts",
  "/contractedPowerReduction",
  "/powerDemanRanges",
  "/demandIncrease",
  "/lowQualityCounter"
]);

export const CLIENT_DEVICES_PATHS = Object.freeze([
  "/powerDemand",
  "/highestUsages",
  "/phaseVoltages",
  "/higherHarmonics",
  "/powerDips",
  "/relativeCosts",
  "/passivePowerCosts"
]);
