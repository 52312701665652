import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";

import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { resolutionOptions, RESOLUTIONS_NUMBERS } from "../../../../constants/chartOptions";
import { DateFilterSection } from "../../../../components/DateFilterSection/DateFilterSection";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";
import { parseDate } from "../../../../utils/helpers";
import { calculateNumberOfPoints } from "../../../../utils/chartUtils";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { getTelemetryData } from "../../../../api/clientApi";
import { getSplineChartOptions } from "../../../../constants/defaultChartOptions";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";

export const ActiveEnergyDetails = observer(() => {
  const toastRef = React.useRef(null);

  const { selectedDevices } = useClientStore();
  const [isLoading, setIsLoading] = useState(false);
  const [sinceDate, setSinceDate] = useState(new Date(Date.now() - TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1]));
  const [toDate, setToDate] = useState(new Date());
  const [resolution, setResolution] = useState(TIME_CONSTANTS.MIN_15);
  const [numberOfDataPoints, setNumberOfDataPoints] = useState(0);
  const [telemetryData, setTelemetryData] = useState({
    device_id: 0,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  useEffect(() => {
    calculateNumberOfPoints(toDate, sinceDate, resolution, showToast, setNumberOfDataPoints);
    if (isNaN(selectedDevices.id)) showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    //eslint-disable-next-line
  }, [toDate, sinceDate, resolution, showToast, setNumberOfDataPoints]);

  useEffect(() => {
    if (!isNaN(selectedDevices.id) && numberOfDataPoints !== 0) {
      getActiveEnergyDetails(selectedDevices.id, sinceDate, toDate, resolution);
    }
  }, [selectedDevices.id, numberOfDataPoints]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const getActiveEnergyDetails = useCallback(
    async (id, sinceDate, toDate, resolution) => {
      setIsLoading(true);

      const { data } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(sinceDate),
        end: parseDateToApiFormat(toDate),
        resolution,
        avm_active_energy_fundamental_sum: 1
      });

      if (!data[0]?.data || data[0]?.data?.length === 0)
        showToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości", "Informacja", "info");

      setTelemetryData(data[0]);

      setIsLoading(false);
    },
    [showToast]
  );

  const getActiveEnergyDetailsChartData = useCallback(
    (resolution, startingPoint) => {
      const dataObject = {
        title: "Szczegółowy profil energii czynnej",
        yAxisLabel: "Energia czynna",
        yAxisUnit: "Wh",
        pointInterval: resolution,
        startTime: startingPoint,
        zoomType: "x",
        plots: [
          {
            name: "Energia czynna",
            data: telemetryData?.data || []
          }
        ]
      };

      return getSplineChartOptions(dataObject);
    },
    [telemetryData]
  );

  const clearDateFilters = useCallback(() => {
    setSinceDate("");
    setToDate("");
  }, []);

  const onConfirm = useCallback(() => {
    calculateNumberOfPoints(
      toDate,
      sinceDate,
      resolution,
      selectedDevices,
      showToast,
      setNumberOfDataPoints,
      false,
      () => getActiveEnergyDetails(selectedDevices.id, sinceDate, toDate, resolution)
    );
  }, [toDate, sinceDate, resolution, showToast, setNumberOfDataPoints, selectedDevices]);

  const chartData = useMemo(
    () => getActiveEnergyDetailsChartData(RESOLUTIONS_NUMBERS[resolution], parseDate(sinceDate), telemetryData),
    [telemetryData, getActiveEnergyDetailsChartData]
  );
  return (
    <Grid>
      <Toast ref={toastRef} />
      <DateFilterSection
        sinceDate={sinceDate}
        setSinceDate={setSinceDate}
        toDate={toDate}
        setToDate={setToDate}
        clearDateFilters={clearDateFilters}
        resolution={resolution}
        setResolution={setResolution}
        resolutionOptions={resolutionOptions}
        onConfirm={onConfirm}
      />
      <Card>
        <Chart
          options={chartData}
          deviceId={typeof selectedDevices.id === "number" ? selectedDevices.id : null}
          chartResolution={resolution}
          isLoading={isLoading}
          chartName={"ActiveEnergyDetails"}
          displayNotesList
        />
      </Card>
    </Grid>
  );
});
