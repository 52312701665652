/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Route } from "react-router-dom";

import AppTopBar from "./AppTopbar";
// import AppConfig from "./AppConfig";
import AppMenu from "./AppMenu";

import { Login } from "./pages/Login";
import { useAuthStore } from "./common/hooks/storeHooks";
import { routers } from "./routers";
import { menu } from "./menuModel";

import "./App.scss";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./styles/primeFacesOverrides.scss";
import "primereact/resources/themes/saga-blue/theme.css";

const App = observer(() => {
  const {
    auth: { isUserAuthenticated }
  } = useAuthStore();

  const [menuActive, setMenuActive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);

  const [menuMode, setMenuMode] = useState("static");
  const [colorScheme, setColorScheme] = useState("light");
  const [menuTheme, setMenuTheme] = useState("layout-sidebar-blue");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(false);

  let menuClick = false;
  let userMenuClick = false;
  let configClick = false;

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  // const onInputStyleChange = inputStyle => {
  //   setInputStyle(inputStyle);
  // };

  // const onRippleChange = e => {
  //   PrimeReact.ripple = e.value;
  //   setRipple(e.value);
  // };

  const onDocumentClick = () => {
    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!menuClick) {
      if (isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    userMenuClick = false;
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuButtonClick = event => {
    menuClick = true;
    setTopbarUserMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
    } else {
      setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
    }

    event.preventDefault();
  };

  const onMenuitemClick = event => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  };

  // const onMenuThemeChange = name => {
  //   setMenuTheme("layout-sidebar-" + name);
  // };

  // const onMenuModeChange = e => {
  //   setMenuMode(e.value);
  // };

  // const onColorSchemeChange = e => {
  //   setColorScheme(e.value);
  // };

  const onTopbarUserMenuButtonClick = event => {
    userMenuClick = true;
    setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  // const onConfigClick = () => {
  //   configClick = true;
  // };

  // const onConfigButtonClick = () => {
  //   setConfigActive(prevConfigActive => !prevConfigActive);
  //   configClick = true;
  // };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
    }
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 1399;
  };

  const containerClassName = classNames(
    "layout-wrapper",
    {
      "layout-overlay": menuMode === "overlay",
      "layout-static": menuMode === "static",
      "layout-slim": menuMode === "slim",
      "layout-sidebar-dim": colorScheme === "dim",
      "layout-sidebar-dark": colorScheme === "dark",
      "layout-overlay-active": overlayMenuActive,
      "layout-mobile-active": staticMenuMobileActive,
      "layout-static-inactive": staticMenuDesktopInactive && menuMode === "static",
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple
    },
    colorScheme === "light" ? menuTheme : ""
  );

  return isUserAuthenticated ? (
    <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
      <div className="layout-content-wrapper">
        <AppTopBar
          routers={routers}
          topbarUserMenuActive={topbarUserMenuActive}
          onMenuButtonClick={onMenuButtonClick}
          onTopbarUserMenu={onTopbarUserMenuButtonClick}
        ></AppTopBar>

        <div className="layout-content">
          {routers.map((router, index) => {
            if (router.exact) {
              return <Route key={`router${index}`} path={router.path} exact component={router.component} />;
            }

            return <Route key={`router${index}`} path={router.path} component={router.component} />;
          })}
        </div>
      </div>

      <AppMenu
        model={menu}
        menuMode={menuMode}
        active={menuActive}
        mobileMenuActive={staticMenuMobileActive}
        onMenuClick={onMenuClick}
        onMenuitemClick={onMenuitemClick}
        onRootMenuitemClick={onRootMenuitemClick}
      ></AppMenu>

      {/* <AppConfig
        configActive={configActive}
        menuMode={menuMode}
        onMenuModeChange={onMenuModeChange}
        menuTheme={menuTheme}
        onMenuThemeChange={onMenuThemeChange}
        colorScheme={colorScheme}
        onColorSchemeChange={onColorSchemeChange}
        onConfigClick={onConfigClick}
        onConfigButtonClick={onConfigButtonClick}
        rippleActive={ripple}
        onRippleChange={onRippleChange}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
      ></AppConfig> */}

      <div className="layout-mask modal-in"></div>
    </div>
  ) : (
    <Login />
  );
});

export default App;
