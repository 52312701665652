import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import Highcharts from "highcharts";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { useCityStore, useWeatherStore, useClientStore } from "../../../../common/hooks/storeHooks";
import { getDayBeginningDateTime, getDayEndDateTime, isNotNullish } from "../../../../utils/helpers";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { TIME_CONSTANTS_VALUES, TIME_CONSTANTS } from "../../../../constants/timeValues";
import { RESOLUTIONS_NUMBERS } from "../../../../constants/chartOptions";
import { buttonDateFilter } from "../../../../utils/dateAndResolutionFilter";
import { getTelemetryData } from "../../../../api/clientApi";
import { getPieChartOptions, getMixedSingleAxisChartOptions } from "../../../../constants/defaultChartOptions";

import "../../../../styles/globalStyles.scss";

export const ActiveAndPassiveEnergy = observer(() => {
  const toastRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonChecked, setButtonChecked] = useState(standardTimeRadioButtons[0].id);
  const [chartType, setChartType] = useState("spline");
  const [telemetryData, setTelemetryData] = useState([{ data: [[]] }, { data: [[]] }, { data: [[]] }]);
  const { fetchWeatherData, weatherData } = useWeatherStore();
  const { fetchCities } = useCityStore();
  const { selectedDevices } = useClientStore();

  useEffect(() => {
    const date = new Date();
    fetchCities();
    fetchWeatherData("Krakow", getDayBeginningDateTime(date).toISOString(), getDayEndDateTime(date));
  }, [fetchWeatherData, fetchCities]);

  useEffect(() => {
    if (isNaN(selectedDevices.id)) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    } else {
      getActiveAndPassiveEnergyData(selectedDevices.id, buttonChecked);
    }
  }, [selectedDevices.id, buttonChecked]);

  useEffect(() => {
    if (isNaN(selectedDevices.id)) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
    }
  }, [chartType]);

  const getActiveAndPassiveEnergyData = useCallback(
    async (id, buttonChecked) => {
      setIsLoading(true);

      const { resolution } = resolutionFilter(buttonChecked);

      const { data } = await getTelemetryData({
        devices: id,
        begin: parseDateToApiFormat(buttonDateFilter(buttonChecked)),
        end: parseDateToApiFormat(),
        resolution,
        avm_active_energy_fundamental_sum: 1,
        avm_active_energy_total_sum: 1,
        avm_reactive_energy_total_sum: 1
      });

      if (!data || data?.some(dataPart => !dataPart.data?.length) || data.length === 0) {
        showToast("Brak danych dla takiej pliszki, zakresu i rozdzielczości", "Informacja", "info");
        setTelemetryData([{ data: [[]] }, { data: [[]] }, { data: [[]] }]);
        setIsLoading(false);
        return;
      }

      setTelemetryData(data);
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const resolutionFilter = useCallback(buttonChecked => {
    switch (buttonChecked) {
      case "day":
        return {
          resolution: TIME_CONSTANTS.MIN_15,
          timeValue: TIME_CONSTANTS_VALUES.day,
          resolutionValue: TIME_CONSTANTS_VALUES.hour_quarter
        };
      case "week":
        return {
          resolution: TIME_CONSTANTS.HOUR_4,
          timeValue: TIME_CONSTANTS_VALUES.week,
          resolutionValue: TIME_CONSTANTS_VALUES["4_hours"]
        };
      case "month":
        return {
          resolution: TIME_CONSTANTS.HOUR_4,
          timeValue: TIME_CONSTANTS_VALUES.month,
          resolutionValue: TIME_CONSTANTS_VALUES["4_hours"]
        };
      case "year":
        return {
          resolution: TIME_CONSTANTS.DAY_1,
          timeValue: TIME_CONSTANTS_VALUES.year,
          resolutionValue: TIME_CONSTANTS_VALUES.day
        };
      default:
        return {
          resolution: TIME_CONSTANTS.MIN_15,
          timeValue: TIME_CONSTANTS_VALUES.day,
          resolutionValue: TIME_CONSTANTS_VALUES.hour_quarter
        };
    }
  }, []);

  const getActiveAndPassiveEnergyChartData = useCallback((weatherData, telemetryData, chartType, buttonChecked) => {
    const dataArrays = telemetryData.map(({ data }) => data);
    if (chartType === "spline") {
      if (dataArrays[0][0].length !== 0) {
        const { timeValue, resolutionValue } = resolutionFilter(buttonChecked);

        const aditionalPoints = timeValue / resolutionValue - dataArrays[0].length;
        const lastTimestamp = dataArrays[0][dataArrays[0].length - 1][0];

        for (let i = 0; i < aditionalPoints + 1; i++) {
          const timestamp = lastTimestamp + resolutionValue * (i + 1);

          dataArrays[0].push([timestamp, null]);
          dataArrays[1].push([timestamp, null]);
          dataArrays[2].push([timestamp, null]);
        }
      }

      const arrayMax = Math.max.apply(Math, [...dataArrays[0], ...dataArrays[1], ...dataArrays[2]]);

      const weatherPoints = [
        {
          name: "Kraków pogoda",
          weather: weatherData,
          data: dataArrays[0].map(() => arrayMax + 1)
        }
      ];

      const dataObject = {
        title: "Profile energii czynnej i biernej",
        // weatherData: weatherPoints,
        multipleAxes: [
          {
            labels: {
              format: `{value} W`,
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            title: {
              text: "Energia czynna fundamentalna",
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            }
          },
          {
            labels: {
              format: `{value} W`,
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: "Energia czynna całkowita",
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            }
          },
          {
            labels: {
              format: `{value} var · h`,
              style: {
                color: Highcharts.getOptions().colors[2]
              }
            },
            title: {
              text: "Energia bierna całkowita",
              style: {
                color: Highcharts.getOptions().colors[2]
              }
            },
            opposite: true
          }
        ],
        colors: Highcharts.getOptions().colors,
        pointInterval: RESOLUTIONS_NUMBERS[resolutionFilter(buttonChecked).resolution],
        series: [
          {
            name: "Energia czynna fundamentalna",
            yAxis: 0,
            data: dataArrays[0],
            unit: " W"
          },
          {
            name: "Energia czynna całkowita",
            yAxis: 1,
            data: dataArrays[1],
            unit: " W"
          },
          {
            name: "Energia bierna całkowita",
            yAxis: 2,
            data: dataArrays[2],
            unit: " var · h"
          }
        ]
      };
      return getMixedSingleAxisChartOptions(dataObject);
    }
    if (chartType === "pie") {
      let activeEnergyTotalData = 0,
        reactiveEnergyTotalData = 0;

      dataArrays[1].map(data => isNotNullish(data[1]) && (activeEnergyTotalData += data[1]));
      dataArrays[2].map(data => isNotNullish(data[1]) && (reactiveEnergyTotalData += Math.abs(data[1])));

      activeEnergyTotalData = Math.round(activeEnergyTotalData);
      reactiveEnergyTotalData = Math.round(reactiveEnergyTotalData);

      const data = [
        {
          name: "Wartość",
          colorByPoint: true,
          data: [
            {
              name: "Energia Czynna Całkowita",
              y: activeEnergyTotalData,
              sliced: true
            },
            {
              name: "Energia Bierna Całkowita",
              y: reactiveEnergyTotalData,
              sliced: true
            }
          ]
        }
      ];

      const dataObject = {
        title: "Profile energii czynnej i biernej",
        yAxisUnit: "Wh",
        data
      };
      return getPieChartOptions(dataObject);
    }
  }, []);
  const chartData = useMemo(
    () => getActiveAndPassiveEnergyChartData(weatherData, telemetryData, chartType, buttonChecked),
    [weatherData, telemetryData, chartType]
  );
  return (
    <Grid>
      <Toast ref={toastRef} />
      <div className="header-toolbar-container">
        <RadioButtonsSection
          setButtonChecked={setButtonChecked}
          radioButtons={standardTimeRadioButtons}
          onRadioButtonPress={() => {}}
          value={buttonChecked}
          flexGrow="wrap"
          className="radio-button-section-container"
        />
        <RadioButtonsSection
          setButtonChecked={setChartType}
          radioButtons={[
            { id: "spline", label: "Liniowy" },
            { id: "pie", label: "Kołowy" }
          ]}
          onRadioButtonPress={() => {}}
          value={chartType}
          title="Typ wykresu"
          className="radio-button-section-container"
        />
      </div>
      <Card>
        <Chart
          options={chartData}
          isLoading={isLoading}
          deviceId={typeof selectedDevices.id === "number" ? selectedDevices.id : null}
          chartResolution={resolutionFilter(buttonChecked).resolution}
          chartName={"ActiveAndPassiveEnergy"}
          displayNotesList={chartType === "spline"}
        />
      </Card>
    </Grid>
  );
});
