import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "./timeValues";

export const chartLangOptions = Object.freeze({
  lang: {
    months: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień"
    ],
    shortMonths: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paz", "Lis", "Gru"],
    weekdays: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    resetZoom: "Zresetuj powiększenie"
  }
});

export const chartTimeOptions = Object.freeze({
  time: {
    useUTC: false
  }
});

export const CHART_TYPES = Object.freeze({
  SPLINE: "spline",
  VARIWIDE: "variwide",
  BAR: "bar",
  COLUMN: "column",
  PIE: "pie"
});

export const MAX_NUMBER_OF_DATA_POINTS = 1000;

export const RESOLUTIONS_NAMES = Object.freeze({
  [TIME_CONSTANTS.SEC_10]: "10 sekund",
  [TIME_CONSTANTS.MIN_1]: "1 minuta",
  [TIME_CONSTANTS.MIN_15]: "15 minut",
  [TIME_CONSTANTS.HOUR_1]: "1 godzina",
  [TIME_CONSTANTS.HOUR_4]: "4 godziny",
  [TIME_CONSTANTS.DAY_1]: "1 dzień",
  [TIME_CONSTANTS.WEEK_1]: "1 tydzień",
  [TIME_CONSTANTS.MONTH_1]: "1 miesiąc",
  [TIME_CONSTANTS.MONTH_3]: "3 miesiące",
  [TIME_CONSTANTS.YEAR_1]: "1 rok"
});

export const RESOLUTIONS_NUMBERS = Object.freeze({
  [TIME_CONSTANTS.SEC_10]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.SEC_10],
  [TIME_CONSTANTS.MIN_1]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MIN_1],
  [TIME_CONSTANTS.MIN_15]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MIN_15],
  [TIME_CONSTANTS.HOUR_1]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_1],
  [TIME_CONSTANTS.HOUR_4]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.HOUR_4],
  [TIME_CONSTANTS.DAY_1]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.DAY_1],
  [TIME_CONSTANTS.WEEK_1]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.WEEK_1],
  [TIME_CONSTANTS.MONTH_1]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MONTH_1],
  [TIME_CONSTANTS.MONTH_3]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.MONTH_3],
  [TIME_CONSTANTS.YEAR_1]: TIME_CONSTANTS_VALUES[TIME_CONSTANTS.YEAR_1]
});

export const resolutionOptions = Object.freeze(Object.values(RESOLUTIONS_NAMES));

export const RANGES = Object.freeze({ DAY: "day", MONTH: "month" });

export const NO_DATA_MESSAGE = "Brak Danych";

export const week = [
  { dayname: "todayData", value: 0 },
  { dayname: "oneDayAgoData", value: 1 },
  { dayname: "twoDayAgoData", value: 2 },
  { dayname: "threeDayAgoData", value: 3 },
  { dayname: "fourDayAgoData", value: 4 },
  { dayname: "fiveDayAgoData", value: 5 },
  { dayname: "sixDayAgoData", value: 6 }
];
