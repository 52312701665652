import React from "react";
import { Card } from "../components/UI/Card/Card";
import { Chart } from "../components/UI/Chart/Chart";
import { Grid } from "../components/UI/Grid";
import { exampleChartsData } from "../mocks";

import "./Start.scss";

export const Start = () => {
  return (
    <Grid>
      <Card flexGrow={8} className="jdfhskhsdjkhkjsad">
        <div className="start-container">
          <h2 className="start-title">
            Nasza platforma składa się z następujących komponentów sprzętowych i oprogramowania:
          </h2>
          <ul>
            <li>
              <h5>Pliszka - analizator i sterownik mikrosieci niskiego napięcia,</h5>
            </li>
            <li>
              <h5>kolektor i agregator danych pomiarowych i statusów sieci,</h5>
            </li>
            <li>
              <h5>dispatcher danych sterujących,</h5>
            </li>
            <li>
              <h5>Dynamiczny terminarz grafikowania pracy magazynów energii,</h5>
            </li>
            <li>
              <h5>aplikacja mobilna,</h5>
            </li>
            <li>
              <h5>portal Klienta,</h5>
            </li>
            <li>
              <h5>Platforma do zarządzania ładowarkami EV – ICU Connect.</h5>
            </li>
          </ul>
          <h5>
            Energetyka rozproszona to ogromna szansa na tanią i zieloną energię. Zespół Naatu Operator od 2014 roku
            rozwija technologie które wspierają rozwój prosumenckiej fotowoltaiki, magazynów energii i handlu energią
            peer2peer.
          </h5>
          <div className="contact-section">
            <p>Zapraszamy do współpracy</p>
            <p>Bartek Steczkowicz</p>
            <p>
              <a href="mailto:b.steczowicz@naatu.pl">b.steczowicz@naatu.pl</a>
            </p>
            <p>
              <a href="tel:+48 511 186 550">+48 511 186 550</a>
            </p>
          </div>
        </div>
      </Card>
    </Grid>
  );
};
