import { observable, makeObservable, action, runInAction } from "mobx";
import { getCities } from "../api/api";

export class CityStore {
  cities = [];
  isLoading = false;

  constructor() {
    makeObservable(this, {
      cities: observable,
      isLoading: observable,
      fetchCities: action.bound
    });
  }

  fetchCities = async () => {
    this.isLoading = true;
    const { status, data, message } = await getCities();
    runInAction(() => {
      if (status === 200) {
        this.isLoading = false;
        this.cities = data.data.response;
      } else {
        this.isLoading = false;
        return { message };
      }

      this.isLoading = false;
    });
  };
}
