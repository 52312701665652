import { Dropdown } from "primereact/dropdown";
import { Card } from "../../../../../../components/UI/Card/Card";
import { CustomCalendar } from "../../../../../../components/CustomCalendar/CustomCalendar";
import "./LocalizationsSelection.scss";

export const LocalizationsSelection = ({
  setFirstLocalization,
  setSecondLocalization,
  currentFirstLocalization,
  firstLocalizations,
  currentSecondLocalization,
  secondsLocalizations,
  date,
  setDate
}) => (
  <>
    <Card>
      <div className="options-wrapper">
        <div className="localizations-container">
          <span className="header-label">Porównaj lokalizacje:</span>
          <div>
            <span className="localization-label">Lokalizacja 1</span>
            <Dropdown value={currentFirstLocalization} options={firstLocalizations} onChange={setFirstLocalization} />
          </div>
          <div>
            <span className="localization-label">Lokalizacja 2</span>
            <Dropdown
              value={currentSecondLocalization}
              options={secondsLocalizations}
              onChange={setSecondLocalization}
            />
          </div>
        </div>
        <div>
          <CustomCalendar
            value={date}
            onDateChange={e => setDate(e.target.value)}
            calendarIcon="pi pi-calendar-minus"
            maxDate={new Date()}
          />
        </div>
      </div>
    </Card>
  </>
);
