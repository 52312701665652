import moment from "moment";

export const splitDataIntoDays = (data = []) => {
  const copiedData = [...data];
  const splitData = [];
  while (copiedData.length) {
    const newDayDataIndex = copiedData.findIndex(
      (element, _, array) => moment(element?.[0]) > moment(array?.[0]?.[0]).endOf("day")
    );
    if (newDayDataIndex === -1) {
      splitData.push(copiedData);
      break;
    }
    const selectedDayElements = copiedData.splice(0, newDayDataIndex);
    if (newDayDataIndex === 1) {
      splitData.push([selectedDayElements]);
    } else {
      splitData.push(selectedDayElements);
    }
  }
  return splitData;
};

export const splitDataIntoNights = (data = []) => {
  const dataSplitIntoDays = splitDataIntoDays(data);
  const splitData = [];

  dataSplitIntoDays.forEach((dayData, index, array) => {
    if (index === array.length - 1) {
      return;
    }
    const startNightIndex = dayData.findIndex(element => moment(element?.[0]).hour() >= 22);
    if (startNightIndex === -1) {
      return;
    }
    const nextDayData = array[index + 1];
    if (!moment(dayData?.[startNightIndex]?.[0]).add(1, "day").isSame(moment(nextDayData?.[0]?.[0]), "day")) {
      return;
    }
    const endNightIndex = nextDayData?.findIndex(element => moment(element?.[0]).hour() >= 6);
    const nightData = dayData.slice(startNightIndex).concat(nextDayData.slice(0, endNightIndex));
    splitData.push(nightData);
  });

  return splitData;
};

export const splitDataIntoMidDays = (data = []) => {
  const dataSplitIntoDays = splitDataIntoDays(data);
  const splitData = [];

  dataSplitIntoDays.forEach(dayData => {
    const startIndex = dayData.findIndex(
      element => moment(element?.[0]).hour() >= 8 && moment(element?.[0]).isoWeekday() < 6
    );
    if (startIndex === -1) {
      return;
    }
    const endIndex = dayData?.findIndex(element => moment(element?.[0]).hour() >= 16);
    const midDayData = dayData.slice(startIndex, endIndex === -1 ? undefined : endIndex);
    splitData.push(midDayData);
  });

  return splitData;
};

export const splitDataIntoAfternoonsAndNights = (data = []) => {
  const dataSplitIntoDays = splitDataIntoDays(data);
  const splitData = [];

  dataSplitIntoDays.forEach((dayData, index, array) => {
    if (index === array.length - 1) {
      return;
    }
    const startNightIndex = dayData.findIndex(
      element => moment(element?.[0]).hour() >= 16 && moment(element?.[0]).isoWeekday() < 5
    );
    if (startNightIndex === -1) {
      return;
    }
    const nextDayData = array[index + 1];
    if (!moment(dayData?.[startNightIndex]?.[0]).add(1, "day").isSame(moment(nextDayData?.[0]?.[0]), "day")) {
      return;
    }
    const endNightIndex = nextDayData?.findIndex(element => moment(element?.[0]).hour() >= 8);
    const nightData = dayData.slice(startNightIndex).concat(nextDayData.slice(0, endNightIndex));
    splitData.push(nightData);
  });

  return splitData;
};

export const splitDataIntoWeekends = (data = []) => {
  const dataSplitIntoDays = splitDataIntoDays(data);
  const splitData = [];

  dataSplitIntoDays.forEach((dayData, index, array) => {
    if (index === array.length - 3) {
      return;
    }
    const startWeekendIndex = dayData.findIndex(
      element => moment(element?.[0]).isoWeekday() === 5 && moment(element?.[0]).hour() >= 16
    );
    if (startWeekendIndex === -1) {
      return;
    }
    const nextDayData = array[index + 1];
    const secondNextDayData = array[index + 2];
    const thirdNextDayData = array[index + 3];

    if (
      !moment(dayData?.[startWeekendIndex]?.[0]).add(1, "day").isSame(moment(nextDayData?.[0]?.[0]), "day") ||
      !moment(dayData?.[startWeekendIndex]?.[0]).add(2, "day").isSame(moment(secondNextDayData?.[0]?.[0]), "day") ||
      !moment(dayData?.[startWeekendIndex]?.[0]).add(3, "day").isSame(moment(thirdNextDayData?.[0]?.[0]), "day")
    ) {
      return;
    }
    const endWeekendIndex = thirdNextDayData?.findIndex(element => moment(element?.[0]).hour() >= 8);
    const weekendData = dayData
      .slice(startWeekendIndex)
      .concat(nextDayData)
      .concat(secondNextDayData)
      .concat(nextDayData.slice(0, endWeekendIndex));
    splitData.push(weekendData);
  });

  return splitData;
};

export const MIN_DATA_ELEMENTS_HOURLY_IN_DAY = 20;
export const MIN_DATA_ELEMENTS_PER_4_HOURS_IN_DAY = 5;
export const MIN_DATA_ELEMENTS_HOURLY_IN_NIGHT = 6;
export const MIN_DATA_ELEMENTS_PER_4_HOURS_IN_NIGHT = 2;
export const MIN_DATA_ELEMENTS_HOURLY_IN_WEEKEND = 52;
export const MIN_DATA_ELEMENTS_PER_4_HOURS_IN_WEEKEND = 13;

export const splitDataIntoThresholds = (data, lowerThreshold, higherThreshold) => {
  const lowerThresholdIndex = data.findIndex(element => element >= lowerThreshold);
  const higherThresholdIndex = data.findIndex(element => element >= higherThreshold);

  const belowLowerThresholdData = data.slice(0, lowerThresholdIndex === -1 ? undefined : lowerThresholdIndex);
  const betweenThresholdsData =
    lowerThresholdIndex === -1
      ? []
      : data.slice(lowerThresholdIndex, higherThresholdIndex === -1 ? undefined : higherThresholdIndex);
  const aboveHigherThresholdData = higherThresholdIndex === -1 ? [] : data.slice(higherThresholdIndex);

  return { belowLowerThresholdData, betweenThresholdsData, aboveHigherThresholdData };
};
