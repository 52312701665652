import { useMemo, useState } from "react";
import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { useClientStore } from "../../../../common/hooks/storeHooks";
import { getOsdBalanceData } from "../../../../mocks/osdBalance";
import { TIME_CONSTANTS, TIME_CONSTANTS_VALUES } from "../../../../constants/timeValues";

export const OsdBalance = () => {
  const [resolution, setResolution] = useState(TIME_CONSTANTS.SEC_10);
  //TODO telemetryData and resolution - dummy data used for notes(we dont have real data right now).
  //Delete or change when proper data will be introduced.
  //If selectedDevices will be used instead telemetryData.device_id use typeof selectedDevices.id === "number" ? selectedDevices.id : null.
  const [telemetryData, setTelemetryData] = useState({
    device_id: 145,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  const { isPhotovoltaicConnected } = useClientStore();

  const chartData = useMemo(() => {
    if (!isPhotovoltaicConnected) {
      const defaultChartOptions = getOsdBalanceData();
      const { series: defaulSeries } = defaultChartOptions;
      const chartOptions = {
        ...defaultChartOptions,
        series: defaulSeries.map(({ type, name }) => ({
          type,
          name,
          data: []
        }))
      };

      return chartOptions;
    }

    return getOsdBalanceData();
  }, [isPhotovoltaicConnected]);
  return (
    <Grid>
      <Card>
        <Chart
          options={chartData}
          deviceId={telemetryData.device_id}
          chartResolution={resolution}
          isLoading={false}
          chartName={"OsdBalance"}
          displayNotesList
        />
      </Card>
    </Grid>
  );
};
