import React from "react";
import { Calendar } from "primereact/calendar";
import "../../constants/localCalendar";

import "./CustomCalendar.scss";

export const CustomCalendar = ({
  label,
  value,
  calendarId,
  onDateChange,
  calendarIcon,
  showTime,
  minDate,
  maxDate,
  disabledDates,
  className
}) => {
  return (
    <div className="calendar-wrapper">
      {label && <span className="calendar-label">{label}:</span>}
      <Calendar
        value={value}
        id={calendarId}
        dateFormat="dd/mm/yy"
        onChange={onDateChange}
        icon={calendarIcon}
        showIcon
        showTime={showTime}
        minDate={minDate}
        maxDate={maxDate}
        disabledDates={disabledDates}
        locale="pl"
        className={className}
      />
    </div>
  );
};
