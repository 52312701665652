import authorizedAxiosClient from "./apiClient";
export const getDevicesNodes = async () => await fetchData("devices/data.json");
export const getTelemetryData = async params => await fetchData("telemetry/data.json", params);

async function fetchData(routeName, params = {}) {
  try {
    const { data, status } = await authorizedAxiosClient.get(`/${routeName}`, {
      params
    });
    return { data, status };
  } catch (error) {
    const errorObject = error.toJSON ? error.toJSON() : error;
    console.error("Fetch error: ", errorObject);
    return { message: errorObject?.message };
  }
}
