import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import { StoreProvider } from "./store/StoreProvider";
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StoreProvider>
    <HashRouter>
      <AppWrapper />
    </HashRouter>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
