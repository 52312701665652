import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import Highcharts from "highcharts";
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import highchartsVariwide from "highcharts/modules/variwide";
import highchartsAnnotations from "highcharts/modules/annotations";
import Boost from "highcharts/modules/boost";
import highchartsMore from "highcharts/highcharts-more";
import { ProgressSpinner } from "primereact/progressspinner";
import { chartLangOptions, chartTimeOptions } from "../../../constants/chartOptions";
import { useNoteContext } from "../../NoteDialog/NoteWrapper";
import { observer } from "mobx-react";
import { getChartOptionsWithNotes, getMasterDetailChartOpions } from "../../../constants/defaultChartOptions";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import { StockChart } from "./StockChart";
import "./ChartWithNotes.scss";
import "primeflex/primeflex.css";
import highchartsTreeMap from "highcharts/modules/treemap";

highchartsVariwide(Highcharts);
Boost(Highcharts);
highchartsMore(Highcharts);
highchartsAnnotations(Highcharts);
noDataToDisplay(Highcharts);
highchartsTreeMap(Highcharts);

export const ChartWithNotes = observer(
  ({
    options,
    constructorType,
    masterDetailChartData,
    immutable = true,
    displayToast,
    isLoading,
    displayNotesList = false
  } = {}) => {
    Highcharts.setOptions(chartLangOptions);
    Highcharts.setOptions(chartTimeOptions);

    const dropDownNotesList = notes => {
      return notes.map(note => ({
        label: `Notatka: ${note.id} | Utworzona: ${moment(note.noteDate).format("DD.MM.YYYY HH:mm")}`,
        value: note
      }));
    };

    const {
      noteStore,
      handleEventPointClick,
      setAuthorName,
      setNoteDate,
      setModificationAuthor,
      setEditDate,
      setNoteId,
      setIsDialogVisible,
      setNote
    } = useNoteContext();

    const notes = noteStore?.notes;
    const notesList = dropDownNotesList(notes);

    const [selectedNote, setSelectedNote] = useState(null);
    const [chartOptions, setChartOptions] = useState(getChartOptionsWithNotes(options, notes, handleEventPointClick));

    useEffect(() => {
      setChartOptions(getChartOptionsWithNotes(options, notes, handleEventPointClick));
    }, [options, notes, handleEventPointClick]);

    const setMainChartOptions = useCallback(
      detailData => {
        const newSeries = options.series.map((serie, i) => ({
          ...serie,
          data: detailData[i]
        }));

        const newChartOpions = {
          ...options,
          series: newSeries
        };

        setChartOptions(getChartOptionsWithNotes(newChartOpions, notes, handleEventPointClick));
      },
      [options]
    );

    const masterDetailChartOptions = useMemo(
      () =>
        masterDetailChartData?.some(data => data.length) &&
        getMasterDetailChartOpions(masterDetailChartData, setMainChartOptions, displayToast),
      [masterDetailChartData]
    );
    return (
      <>
        {displayNotesList && notes.length !== 0 ? (
          <div className="chart-notes-list">
            <Dropdown
              className="notes-list"
              value={selectedNote}
              options={notesList}
              onChange={e => {
                setSelectedNote(e.value);
                setAuthorName(e.value.userName);
                setNoteDate(e.value.noteDate);
                setModificationAuthor(e.value.userName2);
                setEditDate(e.value.editDate);
                setIsDialogVisible(true);
                setNoteId(e.value.id);
                setNote(e.value.note);
              }}
              optionLabel={"label"}
              placeholder="Wybierz notatkę"
            />
          </div>
        ) : null}
        {isLoading ? (
          <ProgressSpinner style={{ height: 600 }} />
        ) : (
          <>
            {constructorType === "stockChart" ? (
              <StockChart constructorType={constructorType} options={chartOptions} />
            ) : masterDetailChartOptions ? (
              <>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                <HighchartsReact highcharts={Highcharts} options={masterDetailChartOptions} />
              </>
            ) : (
              <>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} immutable={immutable} />
              </>
            )}
          </>
        )}
      </>
    );
  }
);
