import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Toast } from "primereact/toast";

import { Card } from "../../../../components/UI/Card/Card";
import { Grid } from "../../../../components/UI/Grid";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { resolutionOptions, RESOLUTIONS_NUMBERS } from "../../../../constants/chartOptions";
import { DateFilterSection } from "../../../../components/DateFilterSection/DateFilterSection";
import { TIME_CONSTANTS } from "../../../../constants/timeValues";
import { getPvProductionDetailsChartData } from "../../../../mocks/pvProductionDetails";
import { parseDate } from "../../../../utils/helpers";
import { calculateNumberOfPoints } from "../../../../utils/chartUtils";
import { getPreviousDate, getDayBeginningDateTime } from "../../../../utils/helpers";

export const PvProductionDetails = () => {
  const toastRef = React.useRef(null);

  const [sinceDate, setSinceDate] = useState(getDayBeginningDateTime(getPreviousDate()));
  const [toDate, setToDate] = useState(new Date());
  const [resolution, setResolution] = useState(TIME_CONSTANTS.SEC_10);
  const [numberOfDataPoints, setNumberOfDataPoints] = useState(0);
  //TODO telemetryData - dummy data used for notes(we dont have real data right now).
  //Delete or change when proper data will be introduced.
  //If selectedDevices will be used instead telemetryData.device_id use typeof selectedDevices.id === "number" ? selectedDevices.id : null.
  const [telemetryData, setTelemetryData] = useState({
    device_id: 145,
    dateType: "",
    name: "",
    dashStyle: "",
    yAxis: "",
    data: [],
    tooltip: ""
  });

  useEffect(() => {
    calculateNumberOfPoints(toDate, sinceDate, resolution, showErrorToast, setNumberOfDataPoints);
    //eslint-disable-next-line
  }, [toDate, sinceDate, resolution, showErrorToast, setNumberOfDataPoints]);

  const clearDateFilters = useCallback(() => {
    setSinceDate("");
    setToDate("");
  }, []);

  const showErrorToast = useCallback(
    message =>
      toastRef.current.show({
        severity: "error",
        summary: "Wystąpił błąd",
        detail: message,
        sticky: true
      }),
    []
  );

  const onConfirm = useCallback(
    () => calculateNumberOfPoints(toDate, sinceDate, resolution, showErrorToast, setNumberOfDataPoints),
    [toDate, sinceDate, resolution, showErrorToast]
  );

  const chartData = useMemo(
    () => getPvProductionDetailsChartData(numberOfDataPoints, RESOLUTIONS_NUMBERS[resolution], parseDate(sinceDate)),
    [numberOfDataPoints, resolution, sinceDate]
  );
  return (
    <Grid>
      <Toast ref={toastRef} />
      <DateFilterSection
        sinceDate={sinceDate}
        setSinceDate={setSinceDate}
        toDate={toDate}
        setToDate={setToDate}
        clearDateFilters={clearDateFilters}
        resolution={resolution}
        setResolution={setResolution}
        resolutionOptions={resolutionOptions}
        onConfirm={onConfirm}
      />
      <Card>
        <Chart
          options={chartData}
          deviceId={telemetryData.device_id}
          chartResolution={resolution}
          chartName={"PvProductionDetails"}
          displayNotesList
        />
      </Card>
    </Grid>
  );
};
