export const ENERGY_TARIFF = Object.freeze({
  1: "Innogy Stoen",
  2: "B21",
  3: "B22",
  4: "B23",
  5: "C21",
  6: "C22a",
  7: "C22b",
  8: "C23",
  9: "C11",
  10: "C12a",
  11: "C12b",
  12: "G11",
  13: "G12",
  14: "G12w",
  15: "R",
  16: "PGE Dystrybucja",
  17: "B21",
  18: "B22",
  19: "B23",
  20: "B24",
  21: "B11",
  22: "C21",
  23: "C22a",
  24: "C22b",
  25: "C23",
  26: "C24",
  27: "C11",
  28: "C11o",
  29: "C12a",
  30: "C12b",
  31: "C12n",
  32: "C12o",
  33: "G11",
  34: "G12",
  35: "G12n",
  36: "G12w",
  37: "R",
  38: "Enea",
  39: "B21",
  40: "B22",
  41: "B23",
  42: "B11",
  43: "B12",
  44: "C21",
  45: "C22a",
  46: "C22b",
  47: "C22w",
  48: "C11",
  49: "C11o",
  50: "C11p",
  51: "C12a",
  52: "C12ap",
  53: "C12b",
  54: "C12bp",
  55: "G11",
  56: "G11p",
  57: "G12",
  58: "G12p",
  59: "G12w",
  60: "R",
  61: "Tauron",
  62: "B21",
  63: "B22",
  64: "B23",
  65: "B11",
  66: "C21",
  67: "C22a",
  68: "C22b",
  69: "C23",
  70: "C11",
  71: "C12a",
  72: "C12b",
  73: "C13",
  74: "G11",
  75: "G11n",
  76: "G12",
  77: "G12e",
  78: "G12g",
  79: "G12n",
  80: "G12w",
  81: "G13",
  82: "O11",
  83: "O12",
  84: "R",
  85: "Energa",
  86: "B21",
  87: "B22",
  88: "B23",
  89: "B11",
  90: "C21",
  91: "C22a",
  92: "C22b",
  93: "C23",
  94: "C11",
  95: "C12a",
  96: "C12b",
  97: "C12w",
  98: "C11o",
  99: "C12o",
  100: "G11",
  101: "G12",
  102: "G12r",
  103: "G12w",
  104: "R",
  105: "PKP Energetyka",
  106: "B21",
  107: "B22",
  108: "B23",
  109: "B11",
  110: "C21",
  111: "C22a",
  112: "C22b",
  113: "C11",
  114: "C12a",
  115: "C12b",
  116: "G11",
  117: "G12",
  118: "G12w",
  119: "R"
});
