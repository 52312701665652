import React from "react";
import { Dialog as PrimeDialog } from "primereact/dialog";
import "./Dialog.scss";

const Dialog = ({ visible, onHide, header, children }) => {
  return (
    <PrimeDialog className="dialog-container" header={header} visible={visible} onHide={onHide}>
      {children}
    </PrimeDialog>
  );
};

export default Dialog;
