import React from "react";
import { Chip } from "primereact/chip";

import { Card } from "../../../../../components/UI/Card/Card";

import "./CurrentContractedPowerSection.scss";

export const CurrentContractedPowerSection = ({ contractedPower }) => {
  return (
    <Card className="current-contracted-power-section-card-container">
      <div className="contracted-power-section-container">
        <span>Moc umowna: </span>
        <Chip template={<span>{contractedPower} kW</span>} />
      </div>
    </Card>
  );
};
