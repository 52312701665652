import { observer } from "mobx-react";
import moment from "moment";
import { Toast } from "primereact/toast";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { getTelemetryData } from "../../../../api/clientApi";
import { useClientStore, useUserOptionsStore } from "../../../../common/hooks/storeHooks";
import { RadioButtonsSection } from "../../../../components/RadioButtonsSection/RadioButtonsSection";
import { Card } from "../../../../components/UI/Card/Card";
import { Chart } from "../../../../components/UI/Chart/Chart";
import { Grid } from "../../../../components/UI/Grid";
import { VoltageNumberInput } from "../../../../components/VoltageNumberInput/VoltageNumberInput";
import { standardTimeRadioButtons } from "../../../../constants/radioButtons";
import { TIME_CONSTANTS } from "../../../../constants/timeValues";
import { getPhaseVoltagesChartData } from "../../../../mocks/phaseVoltages";
import "../../../../styles/globalStyles.scss";
import { parseDateToApiFormat } from "../../../../utils/apiUtils";
import { mergeSameBuildingTypeData } from "../../../../utils/buildingTypesUtils";
import { splitDataIntoThresholds } from "../../../../utils/dataAggreagationUtils";
import { getRangeStartDate } from "../../../../utils/helpers";
import { getSelectedDevices } from "../../../../utils/multipleDevicesUtils";

const OPTIMAL_VOLTAGE = 230;

export const PhaseVoltages = observer(() => {
  const toastRef = React.useRef(null);
  const { selectedClientsDevices, clientsDevicesNodes } = useClientStore();
  const { voltageDeviationThreshold } = useUserOptionsStore();
  const [buttonChecked, setButtonChecked] = useState(standardTimeRadioButtons[0].id);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState(new Date(moment(new Date()).subtract(1, "day").startOf("day")));
  const [endTime, setEndTime] = useState(new Date(moment(new Date()).subtract(1, "day").endOf("day")));
  const [resolution, setResolution] = useState(TIME_CONSTANTS.MIN_15);
  const [percentagesData, setPercentagesData] = useState({});

  useEffect(() => {
    getVoltagesData();
  }, [selectedClientsDevices.ids, buttonChecked, voltageDeviationThreshold]);

  const showToast = useCallback(
    (message, summary = "Wystąpił bład", severity = "error") =>
      toastRef.current.show({
        severity,
        summary,
        detail: message,
        life: 4000
      }),
    []
  );

  const selectedDevices = useMemo(
    () => getSelectedDevices(selectedClientsDevices.ids, clientsDevicesNodes),
    [selectedClientsDevices.ids, clientsDevicesNodes]
  );

  const getVoltagesData = useCallback(async () => {
    if (!selectedDevices?.length) {
      showToast("Nie wybrano pliszki.", "Ostrzeżenie", "warn");
      return;
    }
    setIsLoading(true);

    const selectedDevicesIds = selectedDevices?.map(device => device.deviceId);

    const { data: devicesArray } = await getTelemetryData({
      devices: selectedDevicesIds.join(","),
      begin: parseDateToApiFormat(startTime),
      end: parseDateToApiFormat(endTime),
      resolution: resolution,
      avm_voltage_avg_1: 1
    });

    const dataWithBuildingTypes = devicesArray.map(element => {
      const buildingType = selectedDevices.find(device => Number(device.deviceId) === element.device_id).buildingType;

      return {
        id: element.device_id,
        buildingType,
        data: element.data
      };
    });

    const aggregatedDataArray = mergeSameBuildingTypeData(dataWithBuildingTypes).map(type => ({
      ...type,
      data: type.data.flat()
    }));

    const sortedDataArray = aggregatedDataArray.map(type => ({
      ...type,
      data: type.data.map(element => element[1]).sort((a, b) => a - b)
    }));

    const lowerThreshold = OPTIMAL_VOLTAGE - voltageDeviationThreshold;
    const higherThreshold = OPTIMAL_VOLTAGE + voltageDeviationThreshold;

    const dataSplitIntoThresholds = sortedDataArray.map(type =>
      splitDataIntoThresholds(type.data, lowerThreshold, higherThreshold)
    );

    const getPercentageValue = (array, index) =>
      Math.round((array.length / sortedDataArray[index].data.length) * 10000) / 100;

    const finalData = {
      belowLowerThresholdPercentage: dataSplitIntoThresholds.map(
        (type, index) => getPercentageValue(type.belowLowerThresholdData, index) || 0
      ),
      betweenThresholdsPercentage: dataSplitIntoThresholds.map(
        (type, index) => getPercentageValue(type.betweenThresholdsData, index) || 0
      ),
      aboveHigherThresholdPercentage: dataSplitIntoThresholds.map(
        (type, index) => getPercentageValue(type.aboveHigherThresholdData, index) || 0
      )
    };

    setPercentagesData(finalData);
    setIsLoading(false);
  }, [startTime, endTime, resolution, selectedDevices, showToast, voltageDeviationThreshold]);

  const chartData = useMemo(() => getPhaseVoltagesChartData(percentagesData), [percentagesData]);

  const onRadioButtonPress = useCallback(value => {
    setButtonChecked(value);
    setStartTime(getRangeStartDate(value));
    if (value === TIME_CONSTANTS.DAY_1) {
      setResolution(TIME_CONSTANTS.MIN_15);
    } else if (value === TIME_CONSTANTS.YEAR_1) {
      setResolution(TIME_CONSTANTS.HOUR_4);
    } else {
      setResolution(TIME_CONSTANTS.HOUR_1);
    }
  }, []);

  return (
    <Grid>
      <Toast ref={toastRef} />
      <RadioButtonsSection
        flexGrow={8}
        setButtonChecked={onRadioButtonPress}
        radioButtons={standardTimeRadioButtons}
        value={buttonChecked}
        className="radio-button-section-container"
      />
      <VoltageNumberInput />
      <Card>
        <Chart options={chartData} isLoading={isLoading} />
      </Card>
    </Grid>
  );
});
