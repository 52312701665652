import { getVariwideChartOptions } from "../constants/defaultChartOptions";

export const getPvProductionEfficiencyChartData = () => {
  const dataObject = {
    title: "Produkcja PV Wh/Wp w odniesieniu do wielkości instalacji",
    yAxisLabel: "Efektywność produkcji PV Wh/Wp",
    yAxisUnit: "%",
    caption: "Szerokość kolumn jest proporcjonalna do wielkości instalacji",
    data: buildingCostsData,
    format: "%",
    tooltip: {
      value: {
        yValueName: "Efektywność produkcji",
        xValueName: "Liczba paneli"
      },
      unit: {
        yValueName: "%"
      }
    }
  };
  return getVariwideChartOptions(dataObject);
};

const buildingCostsData = Object.freeze([
  ["Budynek 1", 84, 20],
  ["Budynek 2", 82, 25],
  ["Budynek 3", 81, 30],
  ["Budynek 4", 80, 50],
  ["Budynek 5", 76, 39],
  ["Budynek 6", 76, 15],
  ["Budynek 7", 74, 33],
  ["Budynek 8", 70, 28],
  ["Budynek 9", 69, 41],
  ["Budynek 10", 68, 23],
  ["Budynek 11", 67, 38],
  ["Budynek 12", 65, 55],
  ["Budynek 13", 64, 44],
  ["Budynek 14", 61, 28],
  ["Budynek 15", 60, 35],
  ["Budynek 16", 58, 37],
  ["Budynek 17", 57, 32],
  ["Budynek 18", 55, 45],
  ["Budynek 19", 54, 40],
  ["Budynek 20", 50, 21]
]);
