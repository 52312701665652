import { Card } from "primereact/card";
import "./InfoWindow.scss";
import classNames from "classnames";
import { ENERGY_TARIFF } from "../../../../../mocks/energyTariff";

const Text = ({ children }) => <div className="map-info-window-text">{children}</div>;

const InfoWindow = ({ point, infoWindowDown, infoWindowRight, infoWindowLeft }) => {
  const telephoneNumber = point.description?.includes("tel.")
    ? point.description.slice(point.description.indexOf("tel.") + 4)
    : null;

  const infoWindowPosition = classNames({
    "map-info-window-down": infoWindowDown,
    "map-info-window-right": infoWindowRight,
    "map-info-window-left": infoWindowLeft
  });

  return (
    <Card className={`map-info-window-container ${infoWindowPosition}`}>
      <Text>
        Adres: {point.street} {point.postal_code} {point.post_city}
      </Text>
      <Text>Właściciel: {point.location && point.location}</Text>
      <Text>Tel: {telephoneNumber && telephoneNumber}</Text>
      <Text>Taryfa OSD: {point.osd && ENERGY_TARIFF[point.osd.toString()]}</Text>
      <Text>Operator OSD: {point.electricity_supplier && point.electricity_supplier}</Text>
    </Card>
  );
};

export default InfoWindow;
