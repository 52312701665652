import React from "react";
import { observer } from "mobx-react";
import { useClientStore } from "../../common/hooks/storeHooks";
import { CustomTreeSelect } from "./CustomTreeSelect";

export const ClientTreeSelect = observer(({}) => {
  const { selectedClients, selectClients, clientsNodes, fetchDevicesNodes } = useClientStore();

  React.useEffect(() => {
    fetchDevicesNodes();
  }, [fetchDevicesNodes]);

  return (
    <CustomTreeSelect
      nodes={clientsNodes}
      selectedKeys={selectedClients.ids}
      setSelectedKeys={selectClients}
      placeholder="Wybierz klienta"
      display="chip"
      selectionMode="checkbox"
    />
  );
});
